import React from 'react';
import { faCalendar, faCalendarCheck } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { OrderControlContext } from 'modules/orders/index';
import { useApi, useConfig } from 'providers';
import dayjs from 'dayjs';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';
import { Order, OrderStatus } from 'interfaces/api';
import { useScheduleDateModal } from 'modules/orders/components/ScheduleDatePicker';

const labels = messages.orders.controls.schedule;

export const Schedule = (props: OrderControlContext) => {

  const { orders: { scheduleOrders } } = useApi();
  const { bindings: { reload } } = props;

  const { scheduled_at } = props.data;

  const showModal = useScheduleDateModal();

  const performPreBook = (orders: Order[]) => {
    return showModal({
      defaultValue: scheduled_at.length === 1 ? dayjs(scheduled_at[0]) : undefined,
      onDone: async (value) => {
        await scheduleOrders({
          scheduled_at: value?.format(),
          orderIds: orders.map(o => o.id),
        });
        reload();
      },
    });
  };

  const { preferences: { ordersScheduleTimeEnabled } } = useConfig();
  const format = 'L' + (ordersScheduleTimeEnabled ? ' - HH:mm' : '');

  return (
    <ChooseOrdersControl
      orders={props.data.orders}
      icon={scheduled_at.length > 0 ? faCalendarCheck : faCalendar}
      isActive={scheduled_at.length > 0}
      label={labels.label}
      tooltip={{ title: scheduled_at.length > 0 ? scheduled_at.map(s => dayjs(s).format(format)).join(', ') : undefined }}
      notifications={labels.notifications}
      onDone={performPreBook}
      disabledCheck={o => o.status !== OrderStatus.Saved}
    />
  );
};
