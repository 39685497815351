import { createContext, useContext } from 'react';
import { useStore } from 'zustand';
import { ListLayoutBindingsState, ListLayoutBindingsStore } from 'containers';
import { OrdersListContext } from 'modules/orders/index';

export const OrdersListLayoutBindingsContext = createContext<ListLayoutBindingsStore<OrdersListContext> | null>(null);

export function useOrdersListLayoutBindingsContext<T>(selector: (state: ListLayoutBindingsState<OrdersListContext>) => T): T {
  const store = useContext(OrdersListLayoutBindingsContext);
  if (!store) throw new Error('Missing OrdersListLayoutBindingsContext.Provider in the tree');
  return useStore(store, selector);
}
