import { Device } from '@capacitor/device';
import { Platform } from 'interfaces/api';

export const isAndroid = async () => (await Device.getInfo()).platform === 'android';
export const isIOS = async () => (await Device.getInfo()).platform === 'ios';
export const isWeb = async () => (await Device.getInfo()).platform === 'web';

export const getDeviceId = async () => (await Device.getId()).identifier;
export const getDeviceName = async () => (await Device.getInfo()).name;

export const getPlatform = async () => ({
  ios: Platform.IOS,
  android: Platform.ANDROID,
  web: Platform.WEB,
}[(await Device.getInfo()).platform] || Platform.WEB);
