import React from 'react';
import { useShowHints } from 'modules/orders/providers';
import { HintsModal } from 'modules/orders/components/HintsModal/HintsModal';

export const Hints = () => {

  const { showHints, dismissHints } = useShowHints();

  if (!showHints) {
    return null;
  }

  return (
    <HintsModal
      tnr={showHints.tnr}
      hints={showHints.hints}
      onClose={dismissHints}
    />
  );

};
