import React, { useMemo } from 'react';
import { Control, ControlProps } from 'components/Control';
import { faTag } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useOrdersConfig, useOrdersSelectors, useResetOrders, useSetOfficeDoctor } from 'modules/orders/providers';
import { WriteableOrderProperties } from 'interfaces/api';

export type NewOrderControlProps = Partial<ControlProps> & {
  initialOrders?: WriteableOrderProperties[];
  aid?: number;
};

export const NewOrder: React.FC<NewOrderControlProps> = ({ initialOrders, aid, ...controlProps }) => {

  const setWizardVisible = useOrdersSelectors.setWizardVisible();
  const setShowPatientSelectModal = useOrdersSelectors.setShowPatientSelectModal();

  const { preferences: { orderWizardNewOrderShowSelectPatient } } = useOrdersConfig();

  const setOfficeDoctor = useSetOfficeDoctor();
  const resetOrders = useResetOrders();

  const setImmutableRequirementIds = useOrdersSelectors.setImmutableRequirementIds();

  // @todo useCallback
  const onClick = () => {
    setOfficeDoctor(aid > 0 ? { aid } : undefined);
    resetOrders(initialOrders);
    setImmutableRequirementIds([]);
    setWizardVisible(true);
    if (orderWizardNewOrderShowSelectPatient) {
      setShowPatientSelectModal(true);
    }
  };

  return useMemo(() => (
    <Control
      icon={faTag}
      label={messages.orders.create}
      button={{
        type: 'primary',
      }}
      onClick={onClick}
      {...controlProps}
    />
  ), [onClick, controlProps]);
};
