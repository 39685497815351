import { useCallback } from 'react';
import { AnalysisProperties, OrderWizardRequirement, ReportHistoryValue, ReportValueProperties } from 'interfaces/api';
import { Color } from 'interfaces';
import { getReportValueStatus } from 'modules/reports/utils';
import { ReportValueStatus } from 'modules/reports/interfaces';
import { filter, find, map, uniq } from 'lodash';
import { useOfficeDoctorSelectors, useOrdersSelectors } from 'modules/orders/providers';

export type ExtendedAnalysis = AnalysisProperties & {
  status: ReportValueStatus;
  history: ReportHistoryValue;
  lastValue: ReportValueProperties;
};

export const useExtendedAnalyses = () => {

  const reportsHistory = useOrdersSelectors.history();

  return useCallback((requirement: OrderWizardRequirement): ExtendedAnalysis[] => {
    return (requirement.analyses || []).map((analysis) => {

      const history = reportsHistory && find(reportsHistory, { name: analysis.shortName });
      const status = history ? getReportValueStatus(history.values[history.values.length - 1]) : ReportValueStatus.Default;
      const lastValue = history?.values.length > 0 ? history.values[history.values.length - 1] : undefined;

      return { ...analysis, history, status, lastValue };
    });
  }, [reportsHistory]);

};

export const getRequirementReportFlag = () => {

  const getExtendedAnalyses = useExtendedAnalyses();
  const onlyAllAnalyses = useOfficeDoctorSelectors.wizardSettings()?.preferences?.orderWizardReportValueAllAnalyses;

  return useCallback((requirement: OrderWizardRequirement) => {

    const extended = getExtendedAnalyses(requirement);

    if (onlyAllAnalyses && filter(extended, e => !!e.history).length !== extended.length) {
      return undefined;
    }

    const uniqStatus = uniq(map(extended, a => a.status));

    if (uniqStatus.includes(ReportValueStatus.Pathological)) {
      return Color.Red;
    } else if (uniqStatus.includes(ReportValueStatus.Ok)) {
      return Color.Green;
    } else {
      return undefined;
    }
  }, [getExtendedAnalyses, onlyAllAnalyses]);

};

