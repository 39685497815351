import React from 'react';
import './Switch.less';
import { default as AntSwitch, SwitchProps as AntSwitchProps } from 'antd/es/switch';

type OverwriteProps = unknown;

export type SwitchProps = Omit<AntSwitchProps, keyof OverwriteProps> & OverwriteProps;

export const Switch: React.FC<SwitchProps> = (props) => {

  return (
    <AntSwitch
      {...props}
    />
  );
};
