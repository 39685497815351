import React from 'react';
import { isPlainObject, mapValues } from 'lodash';

export type I18nMessage = {
  id: string;
  description?: string;
  defaultMessage?: string;
};

export type Message = I18nMessage | React.ReactNode;

export type TransformedMessages<T extends string | object> = {
  readonly [K in keyof T]: T[K] extends object ? TransformedMessages<T[K]> : I18nMessage;
};

export const Messages = <T extends object>(m: T): TransformedMessages<T> => {
  const mapper = (obj: T, keys?: string[]): any => mapValues(obj, (v: any, k: string): any => {
    const currentKeys = keys.concat(k);
    return isPlainObject(v)
      ? mapper(v, currentKeys)
      : { id: currentKeys.join('.'), defaultMessage: v };
  });

  return mapper(m, []);
};

export const isI18nMessage = (obj: I18nMessage | any): obj is I18nMessage => {
  return obj !== undefined && (obj as I18nMessage)?.id !== undefined;
};
