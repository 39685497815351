import React from 'react';
import { useMissingFreetext, useOrderRulesContext } from 'modules/orders/providers';
import { FreetextEdit } from 'modules/orders/components/FreetextEdit';

export const Freetext = () => {

  const { cancel, loading } = useOrderRulesContext();
  const { missingFreetext, updateValues } = useMissingFreetext();

  if (!missingFreetext) {
    return null;
  }

  return (
    <FreetextEdit
      loading={loading}
      requirement={missingFreetext[0].requirement}
      cancel={cancel}
      next={(requirement) => {
        updateValues([{ ...missingFreetext[0], requirement }]);
      }}
    />
  );

};
