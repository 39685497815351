import React from 'react';
import { OrderControlContext } from 'modules/orders/index';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useStartRealReRequest } from 'modules/orders/providers';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';

const labels = messages.orders.controls.reRequest;

export const RealReRequest = (props: OrderControlContext) => {

  const { data: { orders, hasMissingAggregation }, context: { selectedOrder } } = props;

  const startRealRequest = useStartRealReRequest();

  return (
    <ChooseOrdersControl
      icon={faPlusCircle}
      button={{
        type: 'primary',
        block: true,
      }}
      single
      defaultSelectedOrder={selectedOrder}
      disabledCheck={o => !o.realReRequestConditions?.allowed}
      disabled={hasMissingAggregation}
      orders={orders}
      label={labels.label}
      onDone={orders => startRealRequest(props.data, orders.map(o => o.id))}
    />
  );
};
