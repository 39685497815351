import React from 'react';
import { CaseAutocomplete, ListLayoutArgs, ListLayoutSideBarSectionCallback } from 'containers';
import { isEqual } from 'lodash';
import { OrdersListContext } from 'modules/orders/index';

const Component = React.memo((props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;

  if (!context.caseId) {
    return null;
  }

  return (
    <CaseAutocomplete
      id={context.caseId}
      reset={() => setContext({ caseId: undefined })}
    />
  );

}, (prev, next) => isEqual(prev.context.caseId, next.context.caseId));

export const CaseSection: ListLayoutSideBarSectionCallback<OrdersListContext> = () => ({
  component: Component,
});
