import React from 'react';
import { FormItemRenderProps } from 'components/Form/Item';
import { Feature, OrderPatientMetaProperties } from 'interfaces/api';
import { Container } from 'components';
import { Guard } from 'containers';
import messages from 'messages';
import { useGetGenderOptions } from 'modules/orders/utils';

export const PatientDataHungaryForm: React.ComponentType<FormItemRenderProps<OrderPatientMetaProperties>> = (props) => {

  const { Input, DatePicker, Select } = props;

  const personLabels = messages.general.person;

  const genderOptions = useGetGenderOptions();

  return (
    <Container grid={'49%'}>

      <Input property={'insuranceNumber'} label={personLabels.insuranceNumber} maxLength={50} floating/>
      <DatePicker property={'birthday'} label={personLabels.birthday} floating/>

      <Select
        floating
        label={personLabels.sex}
        property={'gender'}
        options={genderOptions}
      />

      <Input property={'firstName'} label={personLabels.firstName} maxLength={50} floating/>
      <Input property={'lastName'} label={personLabels.lastName} maxLength={50} floating/>

      <Input property={'city'} label={personLabels.city} maxLength={50} floating/>
      <Input property={'zipCode'} label={personLabels.zip} maxLength={10} floating/>

      <Input property={'address'} label={personLabels.address} maxLength={150} floating/>
      <Input property={'houseNumber'} label={personLabels.houseNumber} maxLength={50} floating className={'oa-patient-house-number'}/>

      <Input property={'passportNumber'} label={personLabels.passportNumber} maxLength={20} floating/>
      <Input property={'idcardNumber'} label={personLabels.idcardNumber} maxLength={20} floating/>

      <Guard feature={Feature.OrderWizardShowPatientContact}>
        <Input property={'phone'} label={personLabels.phone} maxLength={100} floating/>
        <Input property={'email'} label={personLabels.email} maxLength={255} floating/>
      </Guard>

    </Container>
  );

};
