import React, { useCallback } from 'react';
import { App, ModalFuncProps } from 'antd';
import { Icon } from 'components/Icon';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';

export const useInfoModal = () => {
  const { modal } = App.useApp();
  return useCallback((props: ModalFuncProps) => modal.info({
    ...props,
    className: cx('ant-modal-method', props.className),
    icon: <span className={'info-icon'}><Icon icon={faInfoCircle}/></span>,
  }), [modal]);
};

export const useWarningModal = () => {
  const { modal } = App.useApp();
  return useCallback((props: ModalFuncProps) => modal.warning({
    ...props,
    className: cx('ant-modal-method', props.className),
    icon: <span className={'warning-icon'}><Icon icon={faExclamationCircle}/></span>,
  }), [modal]);
};

export const useErrorModal = () => {
  const { modal } = App.useApp();
  return useCallback((props: ModalFuncProps) => modal.error({
    ...props,
    className: cx('ant-modal-method', props.className),
    icon: <span className={'error-icon'}><Icon icon={faTimesCircle}/></span>,
  }), [modal]);
};

export const useConfirmModal = () => {
  const { modal } = App.useApp();
  return useCallback((props: ModalFuncProps) => modal.confirm({
    ...props,
    className: cx('ant-modal-method', props.className),
    icon: <span className={'confirm-icon'}><Icon icon={faQuestionCircle}/></span>,
  }), [modal]);
};

export const useSuccessModal = () => {
  const { modal } = App.useApp();
  return useCallback((props: ModalFuncProps) => modal.success({
    ...props,
    className: cx('ant-modal-method', props.className),
    icon: <span className={'success-icon'}><Icon icon={faCheckCircle}/></span>,
  }), [modal]);
};
