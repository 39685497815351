import React, { CSSProperties, useMemo } from 'react';
import './Header.less';
import { ControlProps } from 'components/Control';
import { filter } from 'lodash';
import cx from 'classnames';
import { Message } from 'interfaces';
import { Translate } from 'providers';

export interface ListHeaderProps {
  title: Message;
  controls?: React.ReactElement<ControlProps>[];
  styles?: CSSProperties;
  className?: any;
}

export const ListHeader: React.FC<ListHeaderProps> = (props) => {

  const { title, styles } = props;

  return useMemo(() => (
    <h3 key={'header-' + title} className={cx('list-header', { 'list-header-has-controls': filter(props.controls)?.length > 0 }, props.className)} style={styles}>
      <Translate message={title}/>
      {props.controls && (
        <ul className={'list-header-controls'}>
          {filter(props.controls).map((control, index) => (
            <li key={index}>{React.cloneElement(control)}</li>
          ))}
        </ul>
      )}
    </h3>

  ), [title, styles]);

};
