import React from 'react';
import { CheckboxProps as AntCheckboxProps, default as AntCheckbox } from 'antd/es/checkbox';
import './Checkbox.less';

type OverwriteProps = object;

export type CheckboxProps = Omit<AntCheckboxProps, keyof OverwriteProps> & OverwriteProps;

export const Checkbox: React.FC<CheckboxProps> = (props) => {

  return (
    <AntCheckbox
      {...props}
    />
  );
};
