import React, { useMemo } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import styles from './Loader.module.less';
import cx from 'classnames';
import { useTimeout } from 'react-use';
import { getCssColor, getCssVariableValue } from 'utils/dom';
import { Color } from 'interfaces';

export type Props = {
  large?: boolean;
  small?: boolean;
  static?: boolean;
  overlay?: boolean;
  delay?: number;
  color?: Color;
};

export const Loader = (props: Props) => {

  const { delay, large, small, overlay, color } = props;
  const loaderColor = useMemo(() => color ? getCssColor(color) : getCssVariableValue('--primary-color'), [color]);

  const [isReady] = useTimeout(delay ?? 100);

  return (
    <div className={cx('loader', styles.container, { [styles.isStatic]: props.static, [styles.pastDelay]: isReady(), [styles.isOverlay]: overlay })}>
      <PulseLoader size={large ? 15 : small ? 4 : 8} color={loaderColor}/>
    </div>
  );

};
