import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { Control } from 'components';
import React from 'react';
import messages from 'messages';
import { useApi } from 'providers';
import { useOfficeDoctorSelectors, useOrdersSelectors } from 'modules/orders/providers';
import { getWriteableOrder, useOnWizardDone } from 'modules/orders/utils';

type Props = {
  disabled?: boolean;
};

export const SendOffReRequestControl = (props: Props) => {

  const { disabled } = props;

  const { realReRequest: { sendOffRealReRequest } } = useApi();

  const orders = useOrdersSelectors.orders();
  const globalSettings = useOrdersSelectors.globalSettings();

  const onWizardDone = useOnWizardDone();
  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();

  const sendOffReRequest = () => new Promise((resolve, reject) => {
    sendOffRealReRequest({
      aid: officeDoctor?.aid,
      orders: orders.map(order => getWriteableOrder({
        ...order,
        ...globalSettings,
        aid: officeDoctor?.aid,
        lanr: officeDoctor?.lanr,
        bsnr: officeDoctor?.bsnr,
        selectedDoctor: officeDoctor?.displayName,
      })),
    })
      .then(response => onWizardDone(response, resolve))
      .catch(reject);
  });

  return (
    <Control
      disabled={disabled}
      icon={faPaperPlane}
      label={messages.orders.controls.sendOffReRequest.label}
      onClick={async () => {
        await sendOffReRequest();
      }}
      notifications={messages.orders.controls.sendOffReRequest.notifications}
      button={{
        type: 'primary',
        block: true,
      }}
    />
  );

};
