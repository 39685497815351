import React from 'react';
import { Feature, Patient, Product, UserType } from 'interfaces/api';
import { Guard } from 'containers';
import messages from 'messages';
import { FormatDate } from 'providers';
import { Card, Control, Icon } from 'components';
import { faCakeCandles, faEnvelope, faFileMedicalAlt, faPhone, faUserCog } from '@fortawesome/pro-regular-svg-icons';
import { filter } from 'lodash';
import { getGenderIcon } from 'modules/reports/utils';
import { EndlessReportControl } from 'modules/reports/components/Controls/Endless';
import { useLocation } from 'react-router';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

interface Props {
  patient: Patient;
  extra?: React.ReactNode;
}

export default React.memo((props: Props) => {

  const {
    pid,
    displayName,
    email,
    phone,
    address,
    houseNumber,
    insuranceNumber,
    zipCode,
    city,
    birthday,
    patientIdentification,
    gender,
  } = props.patient;

  const birthdate = <FormatDate date={birthday} options={{ dateOnly: true }}/>;
  const age = <FormatDate date={birthday} options={{ age: true }}/>;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Card className={'info-popover'} title={<><Icon icon={getGenderIcon(gender)}/>&nbsp;{displayName}</>} extra={props.extra}>

      {(patientIdentification || insuranceNumber) && (
        <h3>{filter([patientIdentification, insuranceNumber]).join(', ')}</h3>
      )}

      {(address || houseNumber || zipCode || city) && (
        <p>
          {address} {houseNumber}
          <br/>
          {zipCode} {city}
        </p>
      )}

      {birthday && (
        <span>
          <Icon icon={faCakeCandles}/>
          {birthdate}&nbsp;({age})
        </span>
      )}

      {phone && (
        <span>
          <Icon icon={faPhone}/>
          {phone}
        </span>
      )}

      {email && (
        <a href={'mailto:' + email}>
          <Icon icon={faEnvelope}/>
          {email}
        </a>
      )}

      {pid > 0 && (
        <div className={'margin-top-1'}>
          <Guard product={Product.OB} forbidden={[UserType.PAT]}>
            <Control
              label={messages.reports.showAllReports}
              icon={faFileMedicalAlt}
              className={cx({ 'hidden-in-sidebar': location.pathname.startsWith('/ob') })}
              onClick={() => navigate('/ob', { state: { patients: { pid } } })}
            />
          </Guard>

          <Guard product={Product.ADMIN} forbidden={[UserType.PAT]}>
            <Control
              icon={faUserCog}
              label={messages.admin.pages.users}
              onClick={() => {
                navigate('/admin/users/patients/' + pid);
              }}
            />
          </Guard>

          <Guard feature={Feature.EndlessScrollReports}>
            <EndlessReportControl patient={props.patient}/>
          </Guard>
        </div>
      )}
    </Card>
  );
});
