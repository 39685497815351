import { ExchangeFileUpdateModes, LDT2ModeLaboratoryGroupIdentificationTypes, LDT3ModeLaboratoryGroupIdentificationTypes } from 'interfaces/api';

export default {
  header: 'Labexchange Settings',
  import: {
    title: {
      ldt: 'LDT Import Settings',
      pdf: 'PDF Import Settings',
    },
    save: 'Save settings',
    importDir: {
      ldt: 'Import directory',
      pdf: 'PDF import directory',
    },
    sourceSection: 'Source files',
    subDirSection: 'Subdirectories',
    ldt2section: 'LDT2 settings',
    ldt3section: 'LDT3 settings',
    archiveDir: {
      ldt: 'LDT archive directory',
      pdf: 'PDF archive directory',
    },
    sourceEncoding: 'File encoding',
    ldt2ModeLaboratoryGroupIdentification: {
      title: 'LDT2 laboratory group identifier',
      values: {
        [LDT2ModeLaboratoryGroupIdentificationTypes.Ident8000]: 'Identify with 8000',
        [LDT2ModeLaboratoryGroupIdentificationTypes.Ident8300]: 'Identify with 8300',
        [LDT2ModeLaboratoryGroupIdentificationTypes.None]: 'No Identification',
      },
    },
    ldt2ModeLaboratoryGroupIdentificationValueList: 'LDT2 Identification value list',
    fileUpdateMode: {
      title: {
        ldt: 'LDT file update mode',
        pdf: 'PDF file update mode',
      },
      values: {
        [ExchangeFileUpdateModes.NoFileUpdate]: 'No file update',
        [ExchangeFileUpdateModes.UpdateDependingTNR]: 'Update depending TNR',
        [ExchangeFileUpdateModes.UpdateDependingTNRandLG]: 'Update depending TNR + LG',
      },
    },
    tnrFieldIdentification: 'TNR field identifier',
    ldt3ModeLaboratoryGroupIdentification: {
      title: 'LDT3 laboratory group identifier',
      values: {
        [LDT3ModeLaboratoryGroupIdentificationTypes.Ident7266]: 'Identify with 7266',
        [LDT3ModeLaboratoryGroupIdentificationTypes.DynObj]: 'Identify with DynObj and LDT field',
        [LDT3ModeLaboratoryGroupIdentificationTypes.None]: 'No Identification',
      },
    },
    ldt3ModeLaboratoryGroupIdentificationValueList: 'LDT3 Identification value list',
    ldt3Mode2ObjectAndField: 'LDT3 dynObj and field comma separated',
    directories: {
      add: 'Add directory',
      path: 'Path',
      description: 'You can define additional subdirectories for import.',
    },
    notifications: {
      success: 'Saved settings successfully',
      error: 'An error occurred',
    },
  },
};
