import { FormatPriceDecimalType, LinkPlatform } from 'interfaces/api';

export default {

  global: {
    header: 'Global Settings',
  },
  sms: {
    title: 'SMS Settings',
    apiSettings: 'Api Settings',
    apiId: {
      label: 'Api Id',
      description: 'Provide the api id or username',
    },
    apiKey: {
      label: 'Api Key',
      description: 'Provide the api key or password',
    },
    url: {
      label: 'Url',
      description: 'Provide the url template, e.g.: {url}',
    },
    method: {
      label: 'Method',
    },
  },
  links: {
    title: 'Links',
    entries: 'Entries',
    showToc: 'Show Terms and Conditions',
    showPrivacy: 'Show Privacy Statement',
    showContact: 'Show Contact Info',
    showContactForm: 'Show Contact From',
    description: 'You can define additional links to be shown in the right bottom of the screen.',
    add: 'Add Link',
    save: 'Save links',
    name: 'Name',
    href: 'Location',
    product: 'Product',
    platform: 'Platform',
    platforms: {
      [LinkPlatform.All]: 'All',
      [LinkPlatform.Web]: 'Web',
      [LinkPlatform.App]: 'App',
    },
    products: {
      all: 'Everywhere',
      login: 'Login',
      reportquickaccess: 'Report Quick Access',
    },
  },
  auth: {
    header: 'Auth Config',
    login: 'Login',
    autoLock: 'Auto lock',
    twoFactor: 'Two-Factor-Auth',
    timeouts: 'Timeouts',
    misc: 'Miscellaneous',
    recoveryUrl: 'Recovery URL',
    activateRecoveryUrl: 'Activate recovery URL',
    authTokenExpiryTime: 'Auth token expiry time',
    resetTokenExpiryTime: 'Password reset token expiry time',
    smsVerificationTokenExpiryTime: 'SMS verification token expiry time',
    lcRegistrationTokenDefaultExpiryTime: 'LC registration token default expiry time in hours',
    throttleLoginCount: {
      label: 'Throttle login count',
      description: 'After how many wrong attempts account gets locked',
    },
    throttleLoginTime: {
      label: 'Throttle login time',
      description: 'Time in which wrong attempts get counted',
    },
    identifierLength: {
      label: 'Identifier length',
      description: 'Length without Server ID (e.g. 1Aabcd is 4)',
    },
    passwordPolicyPattern: {
      label: 'Password policy',
      description: 'Regular expression for password policy',
    },
    enablePasswordVisibilityToggleButton: 'Enable password toggle button',
    logWrongPasswordCleartext: 'Log wrong password in cleartext',
    deviceApproveBrowsers: 'Browsers have to be approved',
    enableAcceptToc: 'TOC (if available) have to be accepted',
    enableHideEmail: 'Hide E-Mail Fields',
    sendDoctorFirstLoginEmail: {
      label: 'Send E-mail on doctors first login',
      description: 'Send an E-mail to the laboratory when a doctor activates his account',
    },
    disableUserServerId: {
      label: 'Disable user server id',
      description: 'User names dont get prefixed with server id',
    },
    customUsernamePrefix: 'Custom user name prefix',
    enableAlternateLogin: {
      label: 'Enable alternate login',
      description: 'Identifier can also be found in x_user.alternative_kennung (SVNR)',
    },
    twoFactorAuthEnabled: 'Enable 2FA',
    twoFactorAppDisabled: 'Disable 2FA in App',
    twoFactorAuthIdentifier: 'Authenticator Identifier',
    twoFactorShowInUserList: 'Show status in user list',
  },
  preferences: {
    header: 'Preferences',
    resetAll: 'Reset all',
    confirm: 'Do you really want to reset all {key} preferences?',
  },
  system: {
    header: 'System',
    maintenance: {
      notice: {
        title: 'Maintenance Notice',
        prefix: 'Title',
        message: 'Message',
        enabled: 'Enable Maintenance Notice',
      },
    },
    banner: {
      title: 'Banner',
      message: 'Message',
      enabled: 'Enable Banner',
    },
    search: {
      title: 'Global search config',
      autocompleteEntries: {
        label: 'Autocomplete entries',
        description: 'Set the number of patients and doctors in autocomplete list',
      },
    },
    genderFormat: {
      title: 'Gender format',
      label: 'Extended Genderselection',
    },
    priceFormat: {
      title: 'Price format',
      decimalType: {
        label: 'Decimal type',
        description: 'Set the decimal to either dot, comma or locale aware',
        types: {
          [FormatPriceDecimalType.LocalString]: 'Local aware',
          [FormatPriceDecimalType.Comma]: 'Comma decimal separator',
          [FormatPriceDecimalType.Dot]: 'Dot decimal separator',
        },
      },
    },
    regexSearch: {
      title: 'Regex search',
      tnr: {
        enable: 'Enable tnr regex search',
        description: 'Automatically switch to tnr search if regex matches',
        regex: 'Regular expression for tnr Search',
      },
      exttnr: {
        enable: 'Enable exttnr regex search',
        description: 'Automatically switch to exttnr search if regex matches',
        regex: 'Regular expression for exttnr Search',
      },
      caseNumber: {
        enable: 'Enable case number regex search',
        description: 'Automatically switch to case number search if regex matches',
        regex: 'Regular expression for case number Search',
      },
      insuranceNumber: {
        enable: 'Enable insurance number regex search',
        description: 'Automatically switch to insurance number search if regex matches',
        regex: 'Regular expression for insurance number Search',
      },
      patientNameOrEmail: {
        enable: 'Enable Patient Name or Email regex search',
        description: 'Automatically switch to Patient Name or Email search if regex matches',
        regex: 'Regular expression for Patient Name or Email Search',
      },
    },
    freeBarcodes: {
      title: 'Free barcodes',
      policy: {
        enable: 'Enable format policy of free barcodes',
        description: 'Checks whether the entered free barcode format matches the given regex pattern',
        regex: 'Regular expression for checking the format policy',
      },
    },
  },
  hierarchy: {
    title: 'Hierarchy',
    member: 'Member',
    owner: 'Owner',
    type: 'Hierarchy Type',
    edit: {
      label: 'Edit user',
    },
    remove: {
      label: 'Remove user',
      confirm: {
        title: 'Do you really want to remove the user from this hierarchy entity?',
      },
    },
    addUser: {
      label: 'Add user',
      existingUser: 'Existing user',
      newUser: 'New user',
      assign: 'Assign user',
      create: 'Create user',
      notifications: {
        success: 'Added user successfully',
      },
    },
  },
  groups: {
    laboratory: {
      title: 'Laboratory Groups',
      labels: {
        shortIdentifier: 'Identifier',
        title: 'Title',
        phone: 'Phone',
        fax: 'Fax',
        url: 'URL',
        address: 'Address',
        city: 'City',
        zip: 'ZIP',
        add1: 'Addendum 1',
        add2: 'Addendum 2',
        add3: 'Addendum 3',
      },
    },
    doctor: {
      title: 'Doctor Groups',
      labels: {
        name: 'Group Name',
        members: 'Members',
        owners: 'Owners',
        oagrp: 'Online Order Group',
        lsgrp: 'Labstore Group',
        addMember: 'Add Doctor',
        member: 'Member',
        owner: 'Owner',
        reportNotification: 'Report Notification',
        search: 'Search Doctors',
      },
      errors: {
        invalidGroupId: 'The specified doctor group does not exist.',
      },
    },
    labels: {
      newGroup: 'New Group',
      deleteGroup: 'Delete Group',
      editGroup: 'Edit Group',
      confirm: 'Confirm',
      save: 'Save changes',
    },
    totalMembers: '{COUNT} total members',
    notifications: {
      create: {
        success: 'Successfully created new group!',
        error: 'Something went wrong',
      },
      update: {
        success: 'Changes saved',
        error: 'Something went wrong',
        nothingToUpdate: 'No changes',
      },
      delete: {
        confirm: 'Do you really want to delete this group?',
        success: 'Group deleted',
        error: 'Something went wrong',
      },
    },
  },
  overtaker: {
    description: 'Overtake Order Entry Settings',
    overtake: 'Overtake Settings From Selected Doctor',
    success: 'Settings overtake was successful',
    error: 'There was a problem during settings overtake',
    overtakeCertificateAssignment: 'Overtake certificates',
    overtakeGrpCertificateAssignment: 'Overtake group certificates',
    overtakeAwizButtons: 'Overtake wizard buttons',
    overtakeRequirementProfiles: 'Overtake requirement profiles',
  },

};
