import React, { useMemo } from 'react';
import { ListGroupFooter, ListGroupHeader, ListItem } from 'components/List/List';
import { ListHeader } from 'components/List/Header/Header';
import cx from 'classnames';
import { useRenderContent, WithContentOuterProps } from 'hocs';

export type ListGroupProps = {
  items: ListItem[];
  header: string;
  fragment?: boolean;
  groupHeader?: ListGroupHeader;
  groupFooter?: ListGroupFooter;
  groupClassName?: (items: ListItem[]) => any;
} & WithContentOuterProps;

export const ListGroup: React.FC<ListGroupProps> = (props) => {

  const [renderContent, rest] = useRenderContent(props);
  const { items, header } = rest;

  const groupHeader = useMemo(() => {
    const group = props.groupHeader?.(header, items) || (header ? { title: header, styles: undefined } : undefined);
    return group ? (<ListHeader key={header} {...group}/>) : null;
  }, [props.groupHeader, header, items]);

  const groupFooter = useMemo(() => props.groupFooter?.(header, items), [props.groupFooter, header, items]);

  const groupContent = (
    <>
      {groupHeader}
      {renderContent()}
      {groupFooter}
    </>
  );

  return props.fragment === undefined
    ? <React.Fragment key={header}>{groupContent}</React.Fragment>
    : <div key={header} className={cx('list-group', props.groupClassName?.(items))}>{groupContent}</div>;

};
