import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { DatePicker, useConfirmModal } from 'components';
import messages from 'messages';
import { useTranslate } from 'providers';
import { PromiseCancelError } from 'utils/helpers';

const labels = messages.orders.controls.sampleDate;

export type SampleDateModalOptions = {
  onDone: (value: dayjs.Dayjs) => Promise<void>;
  defaultValue: dayjs.Dayjs;
  autoFocus?: boolean;
};

export const useSampleDateModal = () => {

  const translate = useTranslate();
  const confirmModal = useConfirmModal();

  let isLoading = false;

  return useCallback(({ autoFocus, onDone, defaultValue }: SampleDateModalOptions) => new Promise((resolve, reject) => {

    let sampleDate = defaultValue;

    const modal = confirmModal({
      title: translate(labels.form.title),
      content: (
        <div>
          <DatePicker
            defaultValue={sampleDate}
            autoFocus={autoFocus}
            disabled={isLoading}
            placeholder={translate(labels.form.placeholder)}
            format={'L - HH:mm'}
            showTime={true}
            onChange={(d) => {
              sampleDate = d;
              modal.update({ okButtonProps: { disabled: !sampleDate } });
            }}
          />
        </div>
      ),
      okButtonProps: { disabled: !sampleDate },
      onOk: async () => {
        isLoading = true;
        await onDone(sampleDate);
        isLoading = false;
        resolve(sampleDate?.format());
      },
      onCancel: () => {
        reject(new PromiseCancelError());
      },
    });
  }), [confirmModal]);

};
