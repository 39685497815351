import { useEnv } from 'providers/EnvProvider';
import { useApi } from 'providers';
import { useCallback } from 'react';
import { CapacitorCookies } from '@capacitor/core';
import { useAuthStoreSelectors } from 'modules/auth/store';

export const useLogout = () => {

  const isWeb = useEnv.isWeb();
  const logout = useAuthStoreSelectors.logout();
  const { authentication: { logout: apiLogout } } = useApi();

  return useCallback(async () => {
    if (!isWeb) {
      await CapacitorCookies.clearAllCookies();
    }
    return apiLogout().finally(() => logout());
  }, [apiLogout]);

};
