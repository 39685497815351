import React, { useCallback, useMemo } from 'react';
import { BadgeType, Container, ControlMenu, ControlProps, Flag, Icon, Images, ListItem, Popover, PopoverProps } from 'components';
import cx from 'classnames';
import { faCheckSquare, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faSquare, faSquareXmark, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ListItemField } from 'components/List/Item/Field/Field';
import './Item.less';
import { getCssColor } from 'utils/dom';
import { filter } from 'lodash';
import { arrayify, rgbFromHex } from 'utils/helpers';
import { Translate } from 'providers';

export type ListItemProps = ListItem & {
  isSelected?: boolean;
  measure?: () => any;
  isCloseable?: boolean;
  checkboxes?: boolean;
  getControls?: (item: ListItem) => React.ReactElement<ControlProps>[];
  getPopover?: (item: ListItem) => PopoverProps;
  className?: any;
  disabled?: boolean;
  onClick?: (item: ListItem, event: any) => void;
  linkProps?: (item: ListItem) => React.AnchorHTMLAttributes<HTMLAnchorElement>;
  itemIsControl?: (item: ListItem) => React.ReactElement<ControlProps>;
};

export const Item = React.memo((props: ListItemProps) => {

  const { isSelected, faded, highlighted, backgroundColor, icon, disabled } = props;

  const hasImages = props.images?.length > 0;

  const badgeType = props.badgeType ?? hasImages ? BadgeType.ImageOverlay : (icon ? BadgeType.IconOverlay : undefined);

  const fields = useMemo(() => filter(props.fields || []).map((field, fieldIndex) => (
    <ListItemField key={fieldIndex} {...field}/>
  )), [props.fields]);

  const itemClassName = useMemo(() => cx('list-item', {
    'list-item-selected': isSelected,
    'list-item-faded': faded || disabled,
    'list-item-disabled': disabled,
    'list-item-highlighted': highlighted,
    'list-item-highlighted-link': backgroundColor,
    'list-item-has-body': !!props.body,
    'list-item-no-fields': !fields.length,
    'list-item-has-flag': !!props.flag,
    'list-item-closable': props.isCloseable,
    'list-item-has-checkbox': props.checkboxes,
    'list-item-has-control-menu': props.controls,
    'list-item-has-icon': !!icon,
    'list-item-has-images': hasImages,
    'list-item-badge-image-overlay': badgeType === BadgeType.ImageOverlay,
    'list-item-badge-icon-overlay': badgeType === BadgeType.IconOverlay,
  }, props.className), [isSelected, faded, highlighted, backgroundColor, hasImages, badgeType, icon, props.checkboxes, props.isCloseable, props.body, props.flag]);

  const linkClasses = useMemo(() => cx('list-item-link', {
    'list-item-no-click': !(props.onClick),
  }), [props.onClick]);

  const checkboxes = useMemo(() => props.checkboxes && (!isSelected && props.disabled
    ? (
      <span className={'list-item-checkbox'}>
        <Icon icon={faSquareXmark}/>
      </span>
    )
    : (
      <span className={'list-item-checkbox'}>
        <Icon icon={faSquare}/>
        <Icon icon={faCheckSquare}/>
      </span>
    )), [props.checkboxes]);

  const wrapPopOver = useCallback((content: React.ReactElement) => {
    const popoverContent = props.getPopover ? props.getPopover(props) : undefined;
    return popoverContent ? <Popover {...popoverContent}>{content}</Popover> : <>{content}</>;
  }, [props.getPopover]);

  const flag = useMemo(() => props.flag && <Flag className={'list-item-flag'} colors={props.flag}/>, [props.flag]);

  const renderedIcon = useMemo(() => icon && (
    <>
      <span className={cx('list-item-icon', { 'list-item-has-hover-icon': props.hoverIcon })}>
        <Icon {...icon}/>
        {props.hoverIcon && <Icon {...props.hoverIcon}/>}
      </span>
    </>
  ), [icon, props.hoverIcon]);

  const images = useMemo(() => props.images && (
    <div className={'list-item-images'}>
      <Images items={props.images}/>
    </div>
  ), [props.images]);

  const highlightedDot = useMemo(() => props.highlightedDot && (
    <div className={'list-item-dot'}>
      <span style={typeof props.highlightedDot !== 'boolean' ? ({ backgroundColor: getCssColor(props.highlightedDot) }) : undefined}/>
    </div>
  ), [props.highlightedDot]);

  const badge = useMemo(() => props.badge && arrayify(props.badge).map(badge => (
    <span key={badge} className={'list-item-badge'}>{badge}</span>
  )), [props.badge]);

  const icons = useMemo(() => (props.icons && props.icons.length > 0) && (
    <span className={'list-item-icons'}>
      {props.icons.map((icon, idx) => <Icon {...icon} key={idx}/>)}
    </span>
  ), [props.icons]);

  const controlMenu = useMemo(() => (props.controls && (
    <span className={'list-control-menu'}>
      <ControlMenu
        controls={props.controls}
        maxVisibleControls={1}
      />
    </span>
  )), []);

  const listItemText = useMemo(() => (
    <div className={'list-item-text'}>

      <span className={'list-item-title'}>
        <Translate message={props.title}/>
      </span>

      {props.subtitle && (
        <span className={'list-item-subtitle'}>
          <Translate message={props.subtitle}/>
        </span>
      )}

    </div>
  ), [props.title, props.subtitle]);

  const selectedIcon = useMemo(() => !props.checkboxes && (
    <span className={'list-item-selected-icon'}>
      <Icon icon={faChevronRight}/>
      <Icon icon={faTimes}/>
    </span>
  ), [props.checkboxes]);

  const listItemPrimary = useMemo(() => (
    <div className={'list-item-primary'}>
      {renderedIcon}
      {images}
      {highlightedDot}
      {listItemText}
      {badge}
      {icons}
      {selectedIcon}
    </div>
  ), [renderedIcon, images, highlightedDot, listItemText, badge, icons, selectedIcon]);

  const body = useMemo(() => props.body && (
    <Container className={'list-item-body'}>{props.body}</Container>
  ), [props.body]);

  const children = useMemo(() => (
    <>
      {checkboxes}
      {flag}
      {listItemPrimary}
      {fields}
      {body}
    </>
  ), [checkboxes, flag, listItemPrimary, fields, body]);

  const onClick = useCallback((event: any) => props.onClick(props, event), [props.onClick]);
  const linkProps = useMemo(() => props.linkProps?.(props), [props.linkProps]);
  const itemIsControl = useMemo(() => props.itemIsControl?.(props), [props.itemIsControl]);

  const controls = useMemo(() => props.getControls && (
    <ul className={'list-item-controls'}>
      {filter(props.getControls(props)).map((control, index) => (
        <li key={index}>{React.cloneElement(control)}</li>
      ))}
    </ul>
  ), [props.getControls]);

  const linkElement = useMemo(() => {

    const style: React.CSSProperties = {};
    if (backgroundColor) {
      const rgb = rgbFromHex(props.backgroundColor);
      style.backgroundColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`;
    }

    const element = itemIsControl
      ? React.cloneElement(itemIsControl, { children, style, className: cx('list-item-link', linkClasses) })
      : (
        <a className={linkClasses} style={style} onClick={onClick} {...linkProps}>
          {children}
        </a>
      );
    return wrapPopOver(element);
  }, [itemIsControl, children, linkClasses, onClick, linkProps, backgroundColor]);

  return (
    <div className={itemClassName} style={props.style}>
      {linkElement}
      {controls}
      {controlMenu}
    </div>
  );

});
