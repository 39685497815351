import React, { useMemo } from 'react';
import { Color, CssColor, Message } from 'interfaces';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconDefinition, IconLookup } from '@fortawesome/fontawesome-common-types';
import { Tooltip } from 'components';
import { getCssColor } from 'utils/dom';
import { isArray, omit } from 'lodash';
import cx from 'classnames';

export type CoreFontAwesomeIcon = IconDefinition & IconLookup;

export type BaseIconProps = Omit<FontAwesomeIconProps, 'icon' | 'color'> & {
  icon: IconLookup | IconDefinition;
  color?: Color | CssColor | string;
  tooltip?: Message;
};

export type IconLayerProps = Omit<BaseIconProps, 'icon'> & {
  icon: BaseIconProps[];
};

export type IconProps = CoreFontAwesomeIcon | BaseIconProps | IconLayerProps;

export const isCoreFontAwesomeIcon = (prop: IconProps): prop is CoreFontAwesomeIcon => {
  return prop !== undefined && (prop as CoreFontAwesomeIcon).iconName !== undefined;
};

export const isIconLayer = (prop: IconProps): prop is IconLayerProps => {
  return prop !== undefined && isArray((prop as IconLayerProps).icon);
};

const IconToFontAwesomeIcon: React.FC<BaseIconProps> = (props) => {

  const { color, tooltip, ...iconProps } = props;

  const cssColor = useMemo(() => color ? getCssColor(color) : undefined, [color]);
  const style = useMemo(() => ({ color: cssColor + ' !important', ...props.style }), [cssColor, props.style]);

  return (
    <FontAwesomeIcon
      {...iconProps}
      color={cssColor}
      style={style}
    />
  );
};

export const Icon: React.FC<IconProps> = (props) => {

  if (isCoreFontAwesomeIcon(props)) {
    const { iconName, prefix, icon, ...rest } = props;
    return <IconToFontAwesomeIcon {...rest} icon={{ iconName, prefix, icon }}/>;
  }

  const content = isIconLayer(props)
    ? (
      <span className={cx('fa-layers fa-fw', props.className)}>
        {props.icon.map((i, idx) => (
          <IconToFontAwesomeIcon key={idx} {...omit(props, 'className')} {...i}/>
        ))}
      </span>
    )
    : (<IconToFontAwesomeIcon {...props} icon={props.icon}/>);

  if (props.tooltip) {
    return (<Tooltip title={props.tooltip}>{content}</Tooltip>);
  }

  return content;

};
