import React from 'react';
import { Container, ContainerProps } from 'components';
import { SwipeableProps, useSwipeable } from 'react-swipeable';

export const SwipeableContainer: React.FC<ContainerProps & { swipeableConfig: SwipeableProps }> = (props) => {

  const { swipeableConfig, ...containerProps } = props;
  const handlers = useSwipeable(swipeableConfig);

  return (
    <Container
      {...containerProps}
      {...handlers}
    />
  );

};
