import React from 'react';
import { useApi } from 'providers';
import messages from 'messages';
import './NotificationSettings.less';
import { ModalFormControl } from 'components';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FormLayout } from 'containers';
import { NotificationSettingsForm } from 'modules/notifications/components/Forms';

export default () => {

  const { globals: { updateConfig, getUserConfig } } = useApi();

  return (
    <ModalFormControl
      icon={faCog}
      label={messages.general.topBar.editProfile.notificationSettings.change}
      modal={{
        narrow: true,
        scrollY: true,
      }}
      form={{
        noShrink: true,
        initialValue: {
          request: async () => getUserConfig(),
        },
        request: ({ preferences }) => updateConfig({ preferences }),
        children: ({ Property }) => (
          <FormLayout embedded>
            <Property property={'preferences'} component={NotificationSettingsForm}/>
          </FormLayout>
        ),
      }}
    />
  );

};
