import { useCallback, useMemo } from 'react';
import { flatten, set, uniqBy } from 'lodash';
import { OrderRulesMandatoryField } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';

export type PendingOrderRuleMissingField = {
  field: OrderRulesMandatoryField;
  index: number;
  value?: any;
};

// hook to get missing fields
export const useMissingFields = () => {

  const { getErrorsByName, pending, setPending, errors } = useOrderRulesContext();

  // get missing fields from errors
  const missingFieldErrors = useMemo(() => getErrorsByName('OrderRulesMandatoryFieldsError'), [getErrorsByName]);

  // create missing fields array
  const missingFields: PendingOrderRuleMissingField[] = useMemo(() => {
    const errors = flatten(missingFieldErrors?.map(
      result => uniqBy(result.errors, e => e.message).map(
        ({ message: field }) => ({ field, index: result.idx }),
      ),
    ));

    return errors.length > 0 ? errors : undefined;

  }, [missingFieldErrors]);

  // update values functionality
  const updateValues = useCallback((values: PendingOrderRuleMissingField[]) => {

    const orders = [...errors.map(e => e.order)];

    values.forEach((v) => {
      set(orders, v.index + '.' + v.field, v.value);
    });

    setPending({ ...pending, orders, isUpsert: true });

  }, [pending, setPending, errors]);

  return { missingFields, updateValues };

};
