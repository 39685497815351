import React from 'react';
import { List, ListItem, ModalControl } from 'components';
import { ReportAccessLog } from 'interfaces/api';
import { ApiRequest, InfiniteScroll, RequestResultComponentProps } from 'containers';
import { FormatDate, PaginationResponse, Translate, useApi } from 'providers';
import { ReportControlContext } from 'modules/reports/index';
import messages from 'messages';
import { faFileUser } from '@fortawesome/pro-light-svg-icons';
import { SortGrouping } from 'interfaces';

const toListItem = (log: ReportAccessLog): ListItem => ({
  id: log.id,
  title: log.user_name || (<Translate message={messages.reports.controls.accessLog.unknownUser}/>),
  subtitle: <FormatDate date={log.created_at} options={{ withSeconds: true }}/>,
  fields: [{
    label: 'Type',
    value: (<Translate message={messages.reports.controls.accessLog.types[log.action]}/>),
  }, {
    label: 'IP',
    value: log.ip,
  }],
  groupByValue: log.created_at,
});

const labels = messages.reports.controls.accessLog;

export const AccessLog = (props: ReportControlContext) => {

  const { data: { bid } } = props;
  const { reports: { getReportAccessLog } } = useApi();

  return (

    <ModalControl
      icon={faFileUser}
      tooltip
      label={labels.label}
      modal={{
        scrollY: true,
        footer: null,
        children: (
          <ApiRequest
            request={(offset: number) => getReportAccessLog({ offset, bid })}
            children={({ data, next }: RequestResultComponentProps<PaginationResponse<ReportAccessLog>>) => (
              <InfiniteScroll onNext={next}>
                <List items={data.results.map(toListItem)} groupBy={SortGrouping.DATE}/>
              </InfiniteScroll>
            )}
          />
        ),
      }}
    />
  );

};
