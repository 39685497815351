import React, { MouseEventHandler, useMemo } from 'react';
import { Color, Message, SortGrouping } from 'interfaces';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import './List.less';
import cx from 'classnames';
import { ControlGroupProps, ControlProps, Icon, IconProps, ImageProps, PopoverProps, TooltipProps } from 'components';
import { ListHeaderProps } from 'components/List/Header/Header';
import { filter, groupBy, map } from 'lodash';
import messages from 'messages';
import { Translate, useIntlStore } from 'providers';
import { ListColumnObserver } from 'components/List/ListColumnObserver';
import { ListGroup } from 'components/List/ListGroup';
import { ListWrapCollapse } from 'components/List/ListWrapCollapse';

export type ListField = {
  label?: Message;
  value: Message;
  flipped?: boolean;
  className?: string;
  narrow?: boolean;
  wide?: boolean;
  tooltip?: TooltipProps;
  onClick?: MouseEventHandler;
};

export const enum BadgeType {
  Inline,
  ImageOverlay,
  IconOverlay,
}

export interface ListItem<MetaInfo = any> {
  id: string | number;
  key?: string | number;
  title?: Message | React.ReactNode;
  subtitle?: Message | React.ReactNode;
  fields?: ListField[];
  body?: string | React.ReactNode;
  className?: string;
  flag?: Color | Color[];
  icon?: IconProps;
  iconColor?: Color;
  icons?: IconProps[];
  images?: ImageProps[];
  hoverIcon?: IconProps;
  highlighted?: boolean;
  immutable?: boolean;
  faded?: boolean;
  disabled?: boolean;
  highlightedDot?: boolean | Color;
  groupByValue?: string;
  backgroundColor?: string;
  badge?: string | string[] | number;
  badgeIcon?: BadgeIconProps;
  badgeType?: BadgeType;
  meta?: MetaInfo;
  controls?: React.ReactElement<ControlProps | ControlGroupProps>[];
  style?: React.CSSProperties;
}

export type BadgeIconProps = {
  icon: IconProps;
  backgroundColor?: Color;
};

export const enum ListColumnType {
  Horizontal,
  Vertical,
  Masonry,
  HorizontalBroader,
  VerticalBroader,
  MasonryBroader,
  ImageCard,
}

export type ListGroupHeader = (group: string, items: ListItem[]) => ListHeaderProps;
export type ListGroupFooter = (group: string, items: ListItem[]) => React.ReactNode;

export interface ListProps {
  items?: ListItem[];
  children?: React.ReactElement[];
  selected?: ListItem[];
  onSelect?: (selected: ListItem) => void;
  checkboxes?: boolean;
  disabled?: boolean;
  linkTo?: (selected: ListItem) => string;
  onItemClick?: (listItem: ListItem) => void;
  hideCaretOnSelect?: boolean;
  backUrl?: string;
  linkProps?: (item: ListItem) => React.AnchorHTMLAttributes<HTMLAnchorElement>;
  itemIsControl?: (item: ListItem) => React.ReactElement<ControlProps>;
  itemIsCollapse?: (item: ListItem) => React.ReactElement;
  suppressCollapseAccordion?: boolean;
  expandAll?: boolean;
  className?: any;
  condensed?: boolean;
  fixedPrimaryColumnWidth?: boolean;
  getControls?: (listItem: ListItem) => React.ReactElement<ControlProps>[];
  getPopover?: (item: ListItem) => Omit<PopoverProps, 'children'>;
  groupBy?: SortGrouping;
  groupHeader?: ListGroupHeader;
  groupFooter?: ListGroupFooter;
  groupClassName?: (items: ListItem[]) => any;
  columnType?: ListColumnType;
  columns?: number;
  suppressNoResults?: boolean;
  bodyCode?: boolean;
}

const ListContentRenderer: React.FC<ListProps> = (props) => {

  const groupedItems = useMemo(() => groupBy<ListItem>(props.items, (item) => {

    const value = item.groupByValue;

    switch (props.groupBy) {

      case SortGrouping.DATE:
        return useIntlStore.getState().formatDate(value, { ago: true, showInvalid: true });

      case SortGrouping.LETTER:
        return value ? value[0].toUpperCase() : '';

      case SortGrouping.STRING:
        return value ? value + '' : '';

      default:
        return '';

    }
  }), [props.items, props.groupBy]);

  if (props.groupBy === undefined) {
    return <ListWrapCollapse {...props}/>;
  }

  const content = map(groupedItems, (groupItems, header) => (
    <ListGroup
      {...props}
      key={header}
      items={groupItems}
      header={header}
      fragment={props.columnType === undefined}
    >
      <ListWrapCollapse {...props} items={groupItems}/>
    </ListGroup>
  ));

  if ([ListColumnType.Masonry, ListColumnType.MasonryBroader, ListColumnType.Vertical, ListColumnType.VerticalBroader].includes(props.columnType)) {
    return (
      <ListColumnObserver columnType={props.columnType} columns={props.columns} key={props.columnType}>
        {content}
      </ListColumnObserver>
    );
  }

  return content;

};

export const List: React.FC<ListProps> = (props) => {

  const { items, children, groupBy, selected, onSelect, linkTo } = props;

  const isSelectable = onSelect || linkTo;
  const hasSelected = filter([].concat(selected)).length > 0;

  const showNoResult = !children && (items || []).length === 0 && !props.suppressNoResults;

  const { columnType, columns, condensed, fixedPrimaryColumnWidth, bodyCode } = props;

  const columnTypeClassNames = useMemo(() => ({
    'list-columns-horizontal': columnType === ListColumnType.Horizontal,
    'list-columns-horizontal-broader': columnType === ListColumnType.HorizontalBroader,
    'list-columns-image-card': columnType === ListColumnType.ImageCard && !showNoResult,
    'list-columns-vertical': columnType === ListColumnType.Vertical && !showNoResult,
    'list-columns-vertical-broader': columnType === ListColumnType.VerticalBroader && !showNoResult,
    'list-masonry': [ListColumnType.Masonry, ListColumnType.MasonryBroader].includes(columnType),
  }), [columnType, showNoResult]);

  const containerStyles = useMemo(() => ({
    ...columnTypeClassNames,
    'list-selectable': isSelectable,
    'list-has-selected': hasSelected,
    'list-condensed': condensed,
    'list-body-code': bodyCode,
    'list-fixed-primary-column-width': fixedPrimaryColumnWidth,
    'list-no-grouping': groupBy === undefined,
  }), [columnTypeClassNames, isSelectable, hasSelected, condensed, bodyCode, fixedPrimaryColumnWidth, groupBy]);

  const containerClassName = cx('list-container', containerStyles, props.className);

  const withGridTemplateColumns = [ListColumnType.Horizontal, ListColumnType.HorizontalBroader].includes(columnType) && columns;

  const styles: React.CSSProperties = useMemo(() => ({
    gridTemplateColumns: withGridTemplateColumns ? 'repeat(6, minmax(0, 1fr))' : undefined,
  }), [withGridTemplateColumns]);

  return (
    <div className={containerClassName} style={styles}>
      {showNoResult
        ? (
          <div className={'list-no-results'}>
            <Icon icon={faBan}/>
            <Translate message={messages.general.list.noResults}/>
          </div>
        )
        : <ListContentRenderer {...props}/>
      }
    </div>
  );

};
