import { FormatPriceDecimalType, LanguageCode } from 'interfaces/api';

export type FormatPriceFunc = (price: number | string, options?: FormatPriceOptions) => string | undefined;

export interface FormatPriceOptions {
  fractionDigits?: number;
}

export const useFormatPriceFunction = (locale: () => LanguageCode, decimalType: () => FormatPriceDecimalType): FormatPriceFunc => {

  return (price: number | string, options = { fractionDigits: 2 }) => {

    const localeString = {
      [FormatPriceDecimalType.LocalString]: locale(),
      [FormatPriceDecimalType.Comma]: LanguageCode.DE,
      [FormatPriceDecimalType.Dot]: LanguageCode.EN,
    }[decimalType()];

    const parsedPrice = (price === typeof Number ? price : parseFloat(price as string)) as number;

    const { fractionDigits } = options;
    return parseFloat(parsedPrice.toFixed(fractionDigits)).toLocaleString(localeString, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });

  };
};
