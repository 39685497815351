import React from 'react';
import { ReportControlContext } from 'modules/reports/index';
import { Controls } from 'modules/orders/components';
import { OrdersConfigProvider } from 'modules/orders/providers';
import { OrdersRoot } from 'modules/orders/containers/OrdersRoot/OrdersRoot';
import { faTag } from '@fortawesome/pro-light-svg-icons';

export const NewOrder = (props: ReportControlContext) => {
  return (
    <OrdersConfigProvider>
      <OrdersRoot>
        <Controls.NewOrder
          button={undefined}
          icon={faTag}
          aid={props.data.aid}
          initialOrders={[{ patient: props.data.patient }]}
        />
      </OrdersRoot>
    </OrdersConfigProvider>
  );

};
