import React, { useMemo } from 'react';
import cx from 'classnames';
import './Field.less';
import { Translate } from 'providers';
import { ListField, Tooltip } from 'components';

export const ListItemField: React.FC<ListField> = (props) => {

  const { label, value, flipped, narrow, wide, onClick } = props;

  const className = useMemo(() => cx('list-item-field', props.className, {
    'list-item-field-flipped': flipped,
    'list-item-field-narrow': narrow,
    'list-item-field-wide': wide,
    'list-item-field-clickable': !!onClick,
  }), [props.className, flipped, narrow, wide]);

  const content = useMemo(() => (
    <div className={className} onClick={onClick}>
      {label && <span className={'list-item-field-label'}><Translate message={label}/></span>}
      <span className={'list-item-field-value'}><Translate message={value}/></span>
    </div>
  ), [label, value, className, onClick]);

  return props.tooltip ? <Tooltip {...props.tooltip}>{content}</Tooltip> : content;

};
