import { createContext, useContext } from 'react';
import { useStore } from 'zustand';
import { ListLayoutBindingsState, ListLayoutBindingsStore } from 'containers';
import { StoreOrdersListLayoutContext } from './interfaces';

export const StoreOrdersListLayoutBindingsContext = createContext<ListLayoutBindingsStore<StoreOrdersListLayoutContext> | null>(null);

export function useStoreOrdersListLayoutBindingsContext<T>(selector: (state: ListLayoutBindingsState<StoreOrdersListLayoutContext>) => T): T {
  const store = useContext(StoreOrdersListLayoutBindingsContext);
  if (!store) throw new Error('Missing StoreOrdersListLayoutBindingsContext.Provider in the tree');
  return useStore(store, selector);
}
