import React from 'react';
import { Control } from 'components';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';

type Props = {
  readNotification: () => any;
};

export const Read = (props: Props) => (
  <Control
    icon={faCheck}
    onClick={props.readNotification}
    label={messages.notifications.read}
  />
);
