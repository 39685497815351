import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { Control } from 'components';
import React from 'react';
import messages from 'messages';
import { useSaveOrExecuteOrders } from 'modules/orders/containers/OrderWizard/providers';
import { useOrdersConfig } from 'modules/orders/providers';

export const PreprintOrderControl = () => {

  const saveOrExecute = useSaveOrExecuteOrders();

  const { preferences: { ordersPrePrintIsC2A } } = useOrdersConfig();

  return (
    <Control
      icon={faPrint}
      label={messages.orders.controls.prePrint.label}
      onClick={() => saveOrExecute({ prePrint: true })}
      button={{
        block: true,
        type: ordersPrePrintIsC2A ? 'primary' : 'default',
      }}
    />
  );

};
