import React, { useCallback, useState } from 'react';
import constate from 'constate';
import { ReportsConfig } from 'interfaces/api';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useApi } from 'providers';
import { useAsync } from 'react-use';

type ConfigContextInitialValue = {
  config: ReportsConfig;
};

const configContext = (initialValue: ConfigContextInitialValue) => {

  const [config, setInternalConfig] = useState<ReportsConfig>(initialValue.config);
  const setConfig = useCallback((newConfig: Partial<ReportsConfig>) => setInternalConfig(prev => ({ ...prev, ...newConfig })), []);

  return { config, setConfig };

};

const [ConfigInternalProvider, useReportsConfig, useSetReportsConfig] = constate(
  configContext,
  values => values.config,
  values => values.setConfig,
);

export {
  useReportsConfig,
  useSetReportsConfig,
};

export const ReportsConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const lid = useAuthStoreSelectors.lid();

  const { reports: { getReportsConfig } } = useApi();

  const config = useAsync(getReportsConfig, [lid]);

  if (config.loading) {
    return null;
  }

  if (config.error) {
    throw config.error;
  }

  return (
    <ConfigInternalProvider config={config.value}>
      {children}
    </ConfigInternalProvider>
  );
};
