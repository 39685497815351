import { find } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useOrderWizardParametersSelectors } from 'modules/orders/containers/OrderWizard/providers';
import { usePrevious } from 'react-use';
import { useOfficeDoctorSelectors } from 'modules/orders/providers/OfficeDoctorProvider';
import { useCurrentOrder } from 'modules/orders/providers/OrdersProvider/useCurrentOrder';
import { useLogger } from 'providers';

export const useSetStartGroupForm = () => {

  const setGroupForm = useOrderWizardParametersSelectors.setGroupForm();
  const setGroupBy = useOrderWizardParametersSelectors.setGroupBy();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const currentOrder = useCurrentOrder();
  const previousCostUnit = usePrevious(currentOrder?.costUnit);

  const isPoolMode = useOrderWizardParametersSelectors.pool();

  const groupFormId = useOrderWizardParametersSelectors.groupFormId();

  const groupForms = useMemo(() => wizardSettings?.groupForms?.filter((f) => {
    return !wizardSettings?.preferences?.orderWizardShowCostUnitSelect || currentOrder?.costUnit;
  }), [wizardSettings, currentOrder?.costUnit]);

  const logger = useLogger('OrdersProvider.setStartGroupForm');

  const setStartGroupForm = () => {
    if (currentOrder?.costUnit !== previousCostUnit && !isPoolMode) {
      const startGroupForm = wizardSettings?.preferences?.orderWizardDefaultGroupForm[currentOrder?.costUnit || 'none'];
      if (groupForms?.map(g => g.id).includes(startGroupForm)) {
        setGroupForm(startGroupForm);
        logger.debug('set start group form by default settings', startGroupForm);
      } else {
        if (groupFormId) {
          const currentGroupForm = find(groupForms, { id: groupFormId });
          const sameNameGroupForm = find(groupForms, g => g.name === currentGroupForm?.name && g.costUnit === currentOrder?.costUnit);
          logger.debug('try to set start group form by same name', currentGroupForm, sameNameGroupForm);

          if (sameNameGroupForm) {
            setGroupForm(sameNameGroupForm.id);
            return;
          }
        }

        logger.debug('reset start group form');
        setGroupBy(null);

      }
    }
  };

  useEffect(setStartGroupForm, [currentOrder?.costUnit, groupForms, wizardSettings?.preferences?.orderWizardStartGroupForm]);

};
