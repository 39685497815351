import { useMemo } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';

export const useMandatoryWorkstation = () => {

  const { getErrorsByName } = useOrderRulesContext();
  const hasMissingWorkstationError = useMemo(() => !!getErrorsByName('OrderRulesWorkstationMandatoryError')?.length, [getErrorsByName]);

  return {
    hasMissingWorkstationError,
  };

};
