import React from 'react';
import { List, ListItem, ModalControl } from 'components';
import { ReportChangeLog } from 'interfaces/api';
import { ApiRequest, InfiniteScroll, RequestResultComponentProps } from 'containers';
import { FormatDate, PaginationResponse, Translate, useApi } from 'providers';
import { ReportControlContext } from 'modules/reports/index';
import messages from 'messages';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { SortGrouping } from 'interfaces';

const labels = messages.reports.controls.changeLog;

const toListItem = (log: ReportChangeLog): ListItem => {

  return {
    id: log.id,
    title: <Translate message={messages.reports.changeLog['action_' + log.action]}/>,
    subtitle: log.data,
    fields: [{
      label: <FormatDate date={log.created_at} options={{ withSeconds: true }}/>,
      value: log.user_name || (<Translate message={messages.reports.controls.changeLog.unknownUser}/>),
      flipped: true,
    }],
    groupByValue: log.created_at,
  };
};

export const ChangeLog: React.FC<ReportControlContext> = (props) => {

  const { data: { bid } } = props;
  const { reports: { getReportChangeLog } } = useApi();

  return (
    <ModalControl
      icon={faFileAlt}
      label={labels.label}
      modal={{
        scrollY: true,
        footer: null,
        children: (
          <ApiRequest
            request={(offset: number) => getReportChangeLog({ offset, bid })}
            children={({ data, next }: RequestResultComponentProps<PaginationResponse<ReportChangeLog>>) => (
              <InfiniteScroll onNext={next}>
                <List items={data.results.map(toListItem)} groupBy={SortGrouping.DATE}/>
              </InfiniteScroll>
            )}
          />
        ),
      }}
    />
  );

};
