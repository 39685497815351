import { createContext, useContext } from 'react';
import { useStore } from 'zustand';
import { ListLayoutBindingsState, ListLayoutBindingsStore, ListLayoutContext } from 'containers';

export const OrderWizardListLayoutBindingsContext = createContext<ListLayoutBindingsStore<ListLayoutContext> | null>(null);

export function useOrderWizardListLayoutBindingsContext<T>(selector: (state: ListLayoutBindingsState<ListLayoutContext>) => T): T {
  const store = useContext(OrderWizardListLayoutBindingsContext);
  if (!store) throw new Error('Missing OrderWizardListLayoutBindingsContext.Provider in the tree');
  return useStore(store, selector);
}
