import React from 'react';
import { Laboratory } from 'interfaces/api';
import { faEnvelope, faFax, faGlobe, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { Card, Icon } from 'components';

interface Props {
  labor: Laboratory;
}

export default React.memo((props: Props) => {
  const { displayName, companyName, email, phone, fax, www, address, city, zipCode } = props.labor;

  return (
    <Card className={'info-popover'} title={displayName}>
      {companyName}
      <br/>
      {address}
      <br/>
      {zipCode ? zipCode + ' ' : ''}{city}
      <br/>
      {phone && (
        <span>
          <Icon icon={faPhone}/>
          {phone}
          <br/>
        </span>
      )}
      {fax && (
        <span>
          <Icon icon={faFax}/>
          {fax}
          <br/>
        </span>
      )}
      {email && (
        <a href={'mailto:' + email}>
          <Icon icon={faEnvelope}/>
          {email}
          <br/>
        </a>
      )}
      {www && (
        <a href={www} target={'_blank'} rel="noreferrer">
          <Icon icon={faGlobe}/>
          {www}
          <br/>
        </a>
      )}
    </Card>
  );
});
