import React from 'react';
import { ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { Container, Control, Filter, Filters, useFileTransfer } from 'components';
import { OrderListMode } from 'interfaces/api';
import messages from 'messages';
import { useApi } from 'providers';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';

export const journalFilters: Filter[] = [{
  label: messages.general.filter.dateFrom,
  filter: value => ({ dateFrom: value }),
  isDate: { disableFuture: true },
}, {
  label: messages.general.filter.dateTo,
  filter: value => ({ dateTo: value }),
  isDate: { disableFuture: true },
}];

const Component = React.memo((props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;

  const { download } = useFileTransfer();
  const { journal: { exportJournalPdf } } = useApi();

  const { dateFrom, dateTo } = context.filters;

  return (
    <Container>

      <Container className={'margin-top-1'}>

        <Filters
          filters={journalFilters}
          onFilter={value => setContext({ filters: { ...context.filters, ...value } })}
          filterValues={context.filters}
        />

      </Container>

      <Container className={'margin-top-1'}>

        <Control
          icon={faFilePdf}
          label={messages.general.exportPdf}
          button={{
            block: true,
            type: 'primary',
          }}
          disabled={!dateFrom || !dateTo}
          onClick={async () => {
            await download({
              request: exportJournalPdf,
              data: { dateFrom, dateTo },
            });
          }}
        />

      </Container>

    </Container>
  );
});

export const JournalSection: ListLayoutSideBarHiddenSectionCallback<OrdersListContext> = args => ({
  active: args.context.mode === OrderListMode.Journal,
  component: Component,
});
