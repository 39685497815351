import { useGuard } from 'containers';
import { useMemo } from 'react';
import { Feature, OrderWizardLocalization } from 'interfaces/api';
import { useOfficeDoctorSelectors } from 'modules/orders/providers';

export const useKisDisabled = () => {

  const guard = useGuard();
  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();

  return useMemo(
    () => guard(
      { feature: Feature.KisModusEdit },
      () => officeDoctor?.localisation === OrderWizardLocalization.KIS && !officeDoctor?.editKisMode),
    [officeDoctor],
  );

};
