import { useMemo } from 'react';
import { filter } from 'lodash';
import { useOrdersSelectors } from './useOrdersStore';

/**
 * useValidPatientsCount
 * returns count of orders with valid first- and lastname
 */
export const useValidPatientsCount = () => {

  const orders = useOrdersSelectors.orders();

  return useMemo(() => filter(orders, o => (
    o.patient.firstName?.length > 0
    && o.patient.lastName?.length > 0
  )).length, [orders]);
};
