import React from 'react';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useApi, useConfig } from 'providers';
import { OrderControlContext } from 'modules/orders/index';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';

const labels = messages.orders.controls.delete;

export const SoftDeleteOrder = (props: OrderControlContext) => {

  const { orders: { softDeleteOrder } } = useApi();
  const { bindings: { reload, setListView } } = props;

  const { softDeleteOrders } = useConfig();

  if (!softDeleteOrders) {
    return null;
  }

  return (
    <ChooseOrdersControl
      orders={props.data.orders}
      icon={faTrash}
      label={labels.label}
      tooltip
      confirm={labels.confirm}
      notifications={labels.notifications}
      onDone={async (orders) => {
        await softDeleteOrder({ orderIds: orders.map(o => o.id) });
        reload();
        setListView();
      }}
    />
  );
};
