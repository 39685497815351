import React from 'react';
import { Message } from 'interfaces';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import './Search.less';
import { Control, ControlProps, Filter, Input, ScanModalControl } from 'components';
import { Translate } from 'providers';
import cx from 'classnames';
import messages from 'messages';
import { useEnvStore } from 'providers/EnvProvider';
import { AutoComplete, AutocompleteProps } from 'components/Search/AutoComplete';

export type SelectedAutocompleteFilter = {
  query: string;
  label: Message;
  filter: any;
};

export type SelectedAutocompleteGroupFilter = {
  group: string;
  item: any;
  filter?: any;
};

export type SelectedAutocomplete = SelectedAutocompleteFilter | SelectedAutocompleteGroupFilter;

export type SearchProps = {
  placeholder?: Message;
  onSearch: (value: any, reset: () => void) => void;
  onChange?: (value: string) => void;
  className?: any;
  autocomplete?: AutocompleteProps;
  onAutocomplete?: (selected: SelectedAutocomplete) => void;
  barcodeScanner?: boolean;
  searchControl?: ControlProps;
  disableFreetext?: boolean;
};

export type AutocompleteFilter = Filter & {
  regex?: string;
  queryLabelRender?: (...value: any[]) => React.ReactNode;
};

interface State {
  query?: string;
  barcodeScannerAvailable?: boolean;
}

export const isAutocompleteFilter = (filter: SelectedAutocomplete): filter is SelectedAutocompleteFilter => {
  return (filter as SelectedAutocompleteFilter).label !== undefined;
};

export const isAutocompleteGroupFilter = (filter: SelectedAutocomplete): filter is SelectedAutocompleteGroupFilter => {
  return (filter as SelectedAutocompleteGroupFilter).group !== undefined;
};

export const AutocompleteItem = (props: { label: Message; value: React.ReactNode }) => (
  <span className={'autocomplete-title'}>
    <Translate message={props.label}/>
    <span className={'autocomplete-value'}>{props.value}</span>
  </span>
);

export class Search extends React.Component<SearchProps, State> {

  // static whyDidYouRender = true;

  constructor(props: SearchProps) {
    super(props);
    this.state = { query: '' };
  }

  handleSearch = (event?: any) => {
    event?.preventDefault();
    if (this.props.disableFreetext) {
      return;
    }
    this.props.onSearch(this.state.query, () => this.setState({ query: '' }));
  };

  onAutocompleteSelect = (value: string) => {

    const item: SelectedAutocomplete = JSON.parse(value);

    this.props.onAutocomplete(item);
    this.setState({ query: '' });

  };

  onAutocompleteSearch = (query: string) => {
    this.setState({ query });
  };

  handleScanned = (barcode: string) => {
    this.setState({ query: barcode }, () => {
      this.props.onSearch(this.state.query, () => this.setState({ query: '' }));
    });
  };

  handleChange = (query: string) => {
    this.setState({ query });
    this.props.onChange?.(query);
  };

  reset = () => {
    this.setState({ query: '' }, this.handleSearch);
  };

  render() {

    const { className, autocomplete, barcodeScanner } = this.props;
    const placeholder = this.props.placeholder || messages.general.search.placeholder;

    const isWeb = useEnvStore.getState().isWeb;

    return (
      <div className={cx('search-container', className)}>
        <Control
          onClick={this.handleSearch}
          icon={faSearch}
          className={'search-control'}
          {...this.props.searchControl}
        />
        {autocomplete
          ? (
            <AutoComplete
              query={this.state.query}
              onSelect={this.onAutocompleteSelect}
              onSearch={this.onAutocompleteSearch}
              {...autocomplete}
            >
              <Input
                type="search"
                placeholder={placeholder}
                onPressEnter={this.handleSearch}
                onChange={event => this.handleChange(event.target.value)}
              />
            </AutoComplete>
          )
          : (
            <Input
              autoFocus={isWeb}
              type="search"
              value={this.state.query}
              onChange={event => this.handleChange(event.target.value)}
              onPressEnter={this.handleSearch}
              placeholder={placeholder}
            />
          )}
        {this.state.query.length === 0 && barcodeScanner && (
          <ScanModalControl
            title={messages.general.search.searchModal.title}
            close={messages.general.close}
            className={'search-barcode-scanner'}
            onScanned={this.handleScanned}
          />
        )}
        {this.state.query.length > 0 && (
          <Control
            icon={faTimes}
            className={'search-reset'}
            onClick={this.reset}
          />
        )}
      </div>
    );

  }

}

