import React from 'react';
import { default as AntRadio, RadioGroupProps as AntRadioGroupProps } from 'antd/es/radio';
import { Message } from 'interfaces';
import { Translate } from 'providers';
import { CheckboxOptionType as AntCheckboxOptionType } from 'antd/es/checkbox/Group';

export type CheckboxOptionType = Omit<AntCheckboxOptionType, 'label'> & {
  label: Message;
};

type OverwriteProps = {
  values: CheckboxOptionType[];
};
export type RadioGroupProps = Omit<AntRadioGroupProps, keyof OverwriteProps> & OverwriteProps;

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {

  return (
    <AntRadio.Group
      {...props}
      options={props.values.map(({ value, label }) => ({ value, label: <Translate message={label}/> }))}
      size={'large'}
    />
  );
};
