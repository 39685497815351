import React from 'react';
import messages from 'messages';
import { useApi } from 'providers';
import { ReportControlContext } from 'modules/reports/index';
import { Control } from 'components';
import { faEye } from '@fortawesome/pro-light-svg-icons';

export const SetRead = (props: ReportControlContext) => {

  const { reports: { setReadReport } } = useApi();

  const { data: report, bindings: { updateItem } } = props;
  const labels = messages.reports.controls.setRead;

  return (
    <Control
      icon={faEye}
      label={labels.label}
      onClick={async () => {
        await setReadReport({ bid: report.bid });
        await updateItem(report.bid, { ...report, is_unread: false });
      }}
    />
  );

};
