import React from 'react';
import { Case } from 'interfaces/api';
import { Card } from 'components/Card';
import { FormatDate } from 'providers';
import { Icon } from 'components/Icon';
import { getGenderIcon } from 'modules/reports/utils';
import { faCakeCandles, faStethoscope } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  case: Case;
  reset?: React.ReactNode;
}

export default React.memo((props: Props) => {
  const {
    caseNumber,
    doctor: { displayName: doctorDisplayName },
    patient: {
      gender,
      birthday,
      insuranceNumber,
      patientIdentification,
      displayName: patientDisplayName,
    },
  } = props.case;

  const birthdate = <FormatDate date={birthday} options={{ dateOnly: true }}/>;
  const age = <FormatDate date={birthday} options={{ age: true }}/>;

  return (
    <Card className={'info-popover'} title={caseNumber} extra={props.reset}>

      {(patientIdentification || insuranceNumber) && (
        <h3>{[patientIdentification, insuranceNumber].join(', ')}</h3>
      )}

      <span>
        <Icon icon={getGenderIcon(gender)}/>{patientDisplayName}
      </span>

      <span>
        <Icon icon={faCakeCandles}/>
        {birthdate}&nbsp;({age})
      </span>

      <span>
        <Icon icon={faStethoscope}/>
        {doctorDisplayName}
      </span>

    </Card>
  );
});
