import { useCallback, useMemo } from 'react';
import { AggregatedDiagnosisProperties, Feature, Gender, OrderRuleActionType, WriteableOrderProperties } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';
import { useGuard } from 'containers';
import { filter, flatten, intersection, keys, sortBy } from 'lodash';
import { useOrdersConfig } from 'modules/orders/providers/OrdersConfigProvider';
import dayjs from 'dayjs';

export type AvailableDiagnosis = AggregatedDiagnosisProperties & {
  requiredBy?: string[];
};

export type PendingOrderRuleMissingDiagnoses = {
  index: number;
  value?: any;
};

export const useMandatoryDiagnoses = () => {

  const guard = useGuard();

  const { getActionsByType, getErrorsByName } = useOrderRulesContext();

  const { diagnoses } = useOrdersConfig();

  // get anamnesis errors
  const diagnosisErrors: PendingOrderRuleMissingDiagnoses[] = useMemo(() => {
    const errors = flatten(getErrorsByName('OrderRulesDiagnosisMandatoryError')?.map(
      ({ errors, idx: index, order: { selectedDiagnoses: value } }) => errors.map(() => ({ index, value })),
    ));

    return errors.length > 0 ? errors : undefined;

  }, [getErrorsByName]);

  const actionEnabled = useMemo(() => guard(
    { feature: Feature.RequirementDiagnoses },
    () => Boolean(filter(getActionsByType(OrderRuleActionType.DiagnosisMandatory)).length || getErrorsByName('OrderRulesDiagnosisMandatoryError')?.length),
  ), [guard, getActionsByType, getErrorsByName]);

  const getAvailableDiagnoses = useCallback((order: WriteableOrderProperties): AvailableDiagnosis[] => {

    if (!actionEnabled) {
      return [];
    }

    // get requirements with missing diagnoses
    const missingRequirements = filter(order.requirements, (r) => {

      if (!r.diagnoses?.length) {
        return false;
      }

      return intersection(keys(order.selectedDiagnoses), r.diagnoses.map(d => d.id + '')).length === 0;
    });

    const sortedDiagnoses = sortBy(filter(diagnoses, d => !d.costUnit || d.costUnit === order.costUnit), 'text');

    const patient = order.patient;
    const minAge = 12;
    const maxAge = 60;

    const filteredDiagnoses = filter(sortedDiagnoses, (d) => {
      if (d.onlyAdultFemale) {
        return patient.gender === Gender.Female
          && dayjs(patient.birthday).isBefore(dayjs().subtract(minAge, 'years'))
          && dayjs(patient.birthday).isAfter(dayjs().subtract(maxAge, 'years'));
      }
      return true;
    });

    return filteredDiagnoses.map(d => ({ ...d, requiredBy: missingRequirements.filter(r => r.diagnoses.map(d => d.id).includes(d.id)).map(r => r.shortName) }));
  }, [actionEnabled, diagnoses]);

  return {
    getAvailableDiagnoses,
    diagnosisErrors,
  };

};
