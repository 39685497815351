import React, { useRef } from 'react';
import { CollapseProps as AntCollapseProps, default as AntCollapse } from 'antd/es/collapse';
import './Collapse.less';
import { getParentScrollContainer } from 'utils/dom';

type OverwriteProps = object;

export type CollapseProps = Omit<AntCollapseProps, keyof OverwriteProps> & OverwriteProps;

export const Collapse: React.FC<CollapseProps> = (props) => {

  const ref = useRef();

  return (
    <AntCollapse
      {...props}
      ref={ref}
      onChange={(key) => {
        const container = getParentScrollContainer(ref.current);
        container?.dispatchEvent(new Event('scroll'));
        props.onChange?.(key);
      }}
    />
  );
};
