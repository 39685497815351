import React from 'react';
import './ErrorBoundary.less';
import * as Sentry from '@sentry/browser';
import { ErrorBoundaryHelga } from 'containers';

type State = {
  error?: Error;
};

const initialState: State = {
  error: null,
};

export class ErrorBoundary extends React.Component<{ children?: React.ReactNode }, State> {

  readonly state: State = initialState;

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.captureException(error);
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return error ? <ErrorBoundaryHelga error={error}/> : children;
  }

}
