import { Product } from 'interfaces/api';

export default {
  product: {
    [Product.OA]: {
      title: 'Online Orders',
      description: '',
    },
    [Product.DASHBOARD]: {
      title: 'Dashboard',
      description: '',
    },
    [Product.OB]: {
      title: 'Online Reports',
      description: '',
    },
    [Product.LS]: {
      title: 'Lab Store',
      description: '',
    },
    [Product.LL]: {
      title: 'Lab Lexika',
      description: '',
    },
    [Product.LE]: {
      title: 'Lab Exchange',
      description: '',
    },
    [Product.LC]: {
      title: 'Lab Connect',
      description: '',
    },
    [Product.DD]: {
      title: 'Doc Designer',
      description: '',
    },
    [Product.MC]: {
      title: 'Message Center',
      description: '',
    },
    [Product.ADMIN]: {
      title: 'Administration',
      description: '',
    },
    [Product.OF]: {
      title: 'Online cases',
      description: '',
    },
    [Product.LW]: {
      title: 'Webshop',
      description: '',
    },
    [Product.NC]: {
      title: 'Notification Center',
      description: '',
    },
  },

  no_features: 'There are no features to configure',

};
