import React from 'react';
import { AggregatedProfileProperties, AggregatedProfileType, OrderWizardRequirement } from 'interfaces/api';
import { faShoppingCart, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Control } from 'components/Control';
import messages from 'messages';
import { useBasketContext, useToggleBasket } from 'modules/orders/containers/OrderWizard/providers';
import { isProfile } from 'modules/orders/containers/OrderWizard/utils';

interface Props {
  requirement: OrderWizardRequirement | AggregatedProfileProperties;
  profileId?: number;
  profileType?: AggregatedProfileType;
}

export const AddBasket: React.FC<Props> = (props) => {

  const { requirement, profileId, profileType } = props;

  const { profileRequirementsAllInBasket, isInBasket } = useBasketContext();
  const toggleBasket = useToggleBasket();
  const showRemove = isProfile(requirement) ? profileRequirementsAllInBasket(requirement) : isInBasket(requirement);

  return (
    <Control
      icon={showRemove ? faTrash : faShoppingCart}
      label={showRemove ? messages.orders.removeFromBasket : messages.orders.addToBasket}
      tooltip={{ placement: 'top' }}
      onClick={async (e) => {
        e.stopPropagation();
        if (isProfile(requirement)) {
          toggleBasket({ ...requirement });
        } else {
          toggleBasket({ ...requirement, profileId, profileType, formId: undefined });
        }
      }}
    />
  );

};
