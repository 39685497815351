import React, { useMemo } from 'react';
import { Color, CssColor } from 'interfaces';
import './Flag.less';
import cx from 'classnames';
import { Container } from 'components';
import { arrayify } from 'utils/helpers';
import { getCssColor } from 'utils/dom';
import { filter } from 'lodash';

export type FlagProps = {
  colors: Color | CssColor | (Color | CssColor)[];
  className?: string;
  style?: React.CSSProperties;
};

export const Flag: React.FC<FlagProps> = ({ colors, className, style }) => {

  const backgroundStyle = useMemo(() => {

    const cssColors = filter(arrayify(colors)).map(getCssColor);

    const mappedColors = cssColors.map((c, i) => {
      if (i === 0) {
        return c;
      }
      const step = Math.floor(100 / cssColors.length) * i;
      return `${cssColors[i - 1]} ${step}%, ${cssColors[i]} ${step + 1}%`;
    }).join(', ') + ')';

    return { background: cssColors.length > 1 ? 'linear-gradient(180deg, ' + mappedColors : cssColors[0] };

  }, [colors]);

  return (
    <Container className={cx('flag', className)} style={style}>
      <span style={backgroundStyle}/>
    </Container>
  );

};
