import React, { useMemo } from 'react';
import './Select.less';
import { default as AntSelect, OptionProps as AntOptionProps, SelectProps as AntSelectProps, SelectValue as AntSelectValue } from 'antd/es/select';
import { Translate, useTranslate } from 'providers';
import { Message } from 'interfaces';
import { OptGroupProps as AntOptGroupProps } from 'rc-select/lib/OptGroup';
import { Control, Icon } from 'components';
import { faChevronDown, faTimes } from '@fortawesome/pro-regular-svg-icons';

export type SelectValue = AntSelectValue;

type OptionOverwriteProps = {
  label?: Message;
};

export type OptionProps = Omit<AntOptionProps, keyof OptionOverwriteProps> & OptionOverwriteProps;

type OptionGroupOverwriteProps = {
  label: Message;
  options?: OptionProps[];
};

export type OptionGroupProps = Omit<AntOptGroupProps, keyof OptionGroupOverwriteProps> & OptionGroupOverwriteProps;

type SelectOverwriteProps = {
  placeholder?: Message;
  options?: OptionProps[];
  optionGroup?: OptionGroupProps[];
  allowClear?: boolean;
};

export type SelectProps<VT> = Omit<AntSelectProps<VT>, keyof SelectOverwriteProps> & SelectOverwriteProps;

export const Select: React.FC<SelectProps<any>> = (props) => {

  const { options, optionGroup, placeholder, children, ...rest } = props;
  const translate = useTranslate();

  const renderedPlaceholder = useMemo(() => translate(placeholder), [placeholder]);

  const suffixIcon = useMemo(() => (
    <Control>
      <Icon icon={faChevronDown}/>
    </Control>
  ), []);

  const clearIcon = useMemo(() => (
    <Control onClick={() => props.onChange(undefined, undefined)}>
      <Icon icon={faTimes}/>
    </Control>
  ), []);

  return (
    <AntSelect {...rest} suffixIcon={suffixIcon} allowClear={props.allowClear ? { clearIcon } : undefined} placeholder={renderedPlaceholder}>
      {props.options?.map(({ value, label, children }) => (
        <AntSelect.Option value={value} key={value + ''}>
          {children || <Translate message={label}/>}
        </AntSelect.Option>
      ))}

      {props.optionGroup?.map(({ label, options }, idx) => (
        <AntSelect.OptGroup label={<Translate message={label}/>} key={idx}>
          {options.map(({ value, label, children }) => (
            <AntSelect.Option value={value} key={value + ''}>
              {children || <Translate message={label}/>}
            </AntSelect.Option>
          ))}
        </AntSelect.OptGroup>
      ))}

      {props.children}

    </AntSelect>
  );
};
