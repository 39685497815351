import React, { useCallback } from 'react';
import { getRequirementReportFlag, useBasketContext, useOrderWizardParametersSelectors } from 'modules/orders/containers/OrderWizard/providers';
import {
  AggregatedProfileProperties,
  AggregatedProfileType,
  AggregatedRequirementFlags,
  Feature,
  OrderWizardLocalization,
  OrderWizardRequirement,
  RequirementAggregationType,
  RequirementType,
} from 'interfaces/api';
import { IconProps, ListItem } from 'components';
import {
  faBug,
  faClipboardMedical,
  faCommentAlt,
  faCommentAltCheck,
  faCommentAltExclamation,
  faEuroSign,
  faFileCheck,
  faGrid,
  faHand,
  faLayerGroup,
  faLink,
  faLocationCheck,
  faLocationPen,
  faStethoscope,
} from '@fortawesome/pro-light-svg-icons';
import { faChfSign, faStar as faStarFull } from '@fortawesome/pro-solid-svg-icons';
import { Color } from 'interfaces';
import { useGuard } from 'containers';
import messages from 'messages';
import cx from 'classnames';
import { useCurrentOrder, useIncompatibleRequirements, useOfficeDoctorSelectors, useOrdersSelectors } from 'modules/orders/providers';
import {
  freeTextByType,
  getBadgeForRequirement,
  isProfile,
  useGetRequirementBasketForm,
  useIsRequirementPrivateOnly,
  useRequirementSubtitle,
} from 'modules/orders/containers/OrderWizard/utils';
import { flatten, includes } from 'lodash';
import { getRequirementId, getRequirementShortName, useProfileRequirements } from 'modules/orders/utils';
import { faExclamationCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

type ListItemType = OrderWizardRequirement | AggregatedProfileProperties;

export const useConvertRequirementToListItem = () => {

  const immutableRequirementIds = useOrdersSelectors.immutableRequirementIds();

  const currentOrder = useCurrentOrder();

  const guard = useGuard();
  const getFlag = getRequirementReportFlag();

  const isPoolMode = useOrderWizardParametersSelectors.pool();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();

  const getIncompatibleRequirements = useIncompatibleRequirements();

  const {
    basketRequirements,
    profileRequirementsAllInBasket,
    profileRequirementsPartialInBasket,
    superRequirementPartialInBasket,
    superRequirementAllInBasket,
    poolRequirementsPartialInBasket,
    poolRequirementsAllInBasket,
    inBasketAsDuplicateRequirement,
  } = useBasketContext();

  const createSubtitle = useRequirementSubtitle();
  const getProfileRequirements = useProfileRequirements();

  const freeTextFeatureAllowed = guard({ feature: Feature.RequirementFreeText }, () => true);

  // convertProfileToListItem
  const convertProfileToListItem = useCallback((profile: AggregatedProfileProperties): ListItem<AggregatedProfileProperties> => {

    const filterString = JSON.stringify(profile.filter);

    return {
      icons: [{ icon: faLayerGroup, color: Color.Smoke }],
      title: profile.longName,
      id: getRequirementId(profile),
      className: cx('list-item-profile', {
        'list-item-global-profile': profile.profileType === AggregatedProfileType.Global,
        'list-item-requirement-collection': profile.profileType === AggregatedProfileType.RequirementCollection,
        'list-item-partial-in-basket': profileRequirementsPartialInBasket(profile),
      }),
      highlighted: isProfile && profileRequirementsAllInBasket(profile),
      subtitle: (
        <>
          {profile.shortName}
        </>
      ),
      meta: profile,
      groupByValue: filterString,
      faded: flatten(getProfileRequirements(profile).map(r => getIncompatibleRequirements(r.shortName))).length > 0,
      style: profile.color
        ? {
          '--oa-filter-profiles-color': '#' + profile.color,
        }
        : undefined,
    } as ListItem;
  }, [profileRequirementsPartialInBasket, profileRequirementsAllInBasket]);

  // convertSuperRequirementToListItem
  const convertSuperRequirementToListItem = useCallback((requirement: OrderWizardRequirement): ListItem<OrderWizardRequirement> => {

    const filterString = JSON.stringify(requirement.filter);

    return {
      icons: [{ icon: faGrid, color: Color.Smoke }],
      title: requirement.longName,
      id: getRequirementId(requirement),
      className: cx('list-item-requirement-collection', {
        'list-item-partial-in-basket': superRequirementPartialInBasket(requirement),
      }),
      highlighted: superRequirementAllInBasket(requirement),
      subtitle: requirement.shortName,
      meta: requirement,
      groupByValue: filterString,
    };
  }, [superRequirementAllInBasket, superRequirementPartialInBasket]);

  const getCurrencyIcon = useCallback(() => {
    if (officeDoctor?.localisation === OrderWizardLocalization.CHE) {
      return faChfSign;
    }
    return faEuroSign;
  }, [officeDoctor?.localisation]);

  const isRequirementPrivateOnly = useIsRequirementPrivateOnly();
  const getRequirementBasketForm = useGetRequirementBasketForm();

  // convertToListItem
  return useCallback((requirement: ListItemType, basket?: boolean): ListItem<ListItemType> => {

    if (isProfile(requirement)) {
      return convertProfileToListItem(requirement);
    }

    if (requirement.flags?.includes(AggregatedRequirementFlags.IsSuperRequirement)) {
      return convertSuperRequirementToListItem(requirement);
    }

    const isIncompatible = basket && !isPoolMode && currentOrder?.costUnit && requirement.form?.costUnit !== currentOrder?.costUnit;

    const icons: IconProps[] = [];

    if (isIncompatible) {
      icons.push({ icon: faExclamationCircle, color: Color.Yellow });
    }

    if (requirement.ruleInfoText) {
      icons.push(faFileCheck);
    }

    if (requirement.hint && guard({ feature: Feature.RequirementsHint }, () => true)) {
      icons.push(faClipboardMedical);
    }

    if (requirement.infoUrl) {
      icons.push(faLink);
    }

    if (freeTextFeatureAllowed && requirement.freeTextAllowed) {
      if (freeTextByType(requirement)) {
        icons.push(faCommentAltCheck);
      } else if (requirement.freeTextMandatory) {
        if (basket) {
          icons.push({ icon: faCommentAltExclamation, color: Color.Red });
        } else {
          icons.push(faCommentAltExclamation);
        }
      } else {
        icons.push(faCommentAlt);
      }
    }

    if (requirement.flags?.includes(AggregatedRequirementFlags.AskOrigin)) {
      icons.push(faLocationCheck);
    }

    if (requirement.diagnoses?.length > 0 && guard({ feature: Feature.RequirementDiagnoses }, () => true)) {
      icons.push(faStethoscope);
    }

    if (requirement.flags?.includes(AggregatedRequirementFlags.AskOriginOptional)) {
      icons.push(requirement.origin ? faLocationCheck : faLocationPen);
    }

    if ((isRequirementPrivateOnly(requirement) || getRequirementBasketForm(requirement)?.isPrivate) && requirement.isCharged) {
      icons.push(getCurrencyIcon());
    }

    if (requirement.flags?.includes(AggregatedRequirementFlags.IsPinned)) {
      icons.push({ icon: faStarFull, color: Color.Blue });
    }

    let title = requirement.longName;

    if (requirement.entityType === RequirementType.Microbiological) {
      icons.push({ icon: faBug });

      if (wizardSettings?.preferences?.orderWizardCompactView && !wizardSettings?.preferences?.orderWizardHideBakMaterialGroupInfo) {
        title += ` (${getRequirementShortName(requirement)})`;
      }
    }

    if (requirement.cancelled_at) {
      icons.push({
        icon: faTimesCircle, color: Color.Red,
        tooltip: messages.orders.controls.cancelRequirement.isCancelled,
      });
    }

    const isImmutable = includes(immutableRequirementIds, requirement.id);
    if (isImmutable) {
      icons.push({ icon: faHand });
    }

    requirement.selectedAnalyses = requirement.filter?.type === RequirementAggregationType.Analysis && !basket
      ? requirement.analyses.filter(a => a.id === requirement.filter.id).map(a => a.shortName)
      : requirement.selectedAnalyses;

    const filterString = JSON.stringify(requirement.filter);

    const showAdditionalText = !basket
      && requirement.additionalText
      && (!wizardSettings?.preferences?.orderWizardCompactView || wizardSettings?.preferences?.orderWizardAdditionalTextInCompactView);

    return {
      icons,
      title,
      id: getRequirementId(requirement),
      flag: getFlag(requirement),
      className: cx({
        'list-item-mibi': requirement.entityType === RequirementType.Microbiological,
        'list-item-immutable': isImmutable,
        'list-item-incompatible-cost-unit': isIncompatible,
        'list-item-partial-in-basket': poolRequirementsPartialInBasket(requirement),
      }),
      badge: wizardSettings?.preferences?.orderWizardPopoverHideAnalyses ? undefined : getBadgeForRequirement(requirement),
      highlighted: poolRequirementsAllInBasket(requirement),
      subtitle: createSubtitle(requirement),
      meta: requirement,
      groupByValue: filterString,
      backgroundColor: requirement.entityType === RequirementType.Microbiological ? undefined : requirement.filter?.background,
      immutable: isImmutable,
      faded: getIncompatibleRequirements(requirement.shortName).length > 0 || isIncompatible || isImmutable || inBasketAsDuplicateRequirement(requirement).length > 0,
      body: showAdditionalText ? <div dangerouslySetInnerHTML={{ __html: requirement.additionalText }}/> : undefined,
    };
  }, [
    getFlag,
    basketRequirements,
    getIncompatibleRequirements,
    poolRequirementsPartialInBasket,
    poolRequirementsAllInBasket,
    getBadgeForRequirement,
    immutableRequirementIds,
    inBasketAsDuplicateRequirement,
    currentOrder,
    isPoolMode,
    createSubtitle,
  ]);
};
