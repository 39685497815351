import React from 'react';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { useApi } from 'providers';
import { OrderControlContext } from 'modules/orders/index';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';
import messages from 'messages';
import { OrderStatus } from 'interfaces/api';

const labels = messages.orders.controls.delete;

export const DeleteOrder = (props: OrderControlContext) => {

  const { orders: { deleteOrder } } = useApi();
  const { bindings: { reload, setListView } } = props;

  return (
    <ChooseOrdersControl
      orders={props.data.orders}
      icon={faTrash}
      label={labels.label}
      tooltip
      confirm={labels.confirm}
      notifications={labels.notifications}
      disabledCheck={o => o.status !== OrderStatus.Saved}
      onDone={async (orders) => {
        await deleteOrder({ orderIds: orders.map(o => o.id) });
        reload();
        setListView();
      }}
    />
  );
};
