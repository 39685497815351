import React, { useCallback, useMemo } from 'react';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useApiStoreSelectors } from 'providers/ApiProvider/api.store';
import { Translate, useIntlStoreSelectors } from 'providers/IntlProvider';
import { getClient } from './client';
import { ApiDefinition } from 'interfaces/api';
import { useLogger } from 'providers/LoggerProvider';
import { useEnv, useEnvStore } from 'providers/EnvProvider';
import { useWarningModal } from 'components';
import messages from 'messages';

export const API_ENDPOINT_KEY = 'api_endpoint';

export const useApiClient = (lid?: number): ApiDefinition => {

  const locale = useIntlStoreSelectors.locale();

  const isWeb = useEnv.isWeb();

  const originalEndpoint = useEnv.BACKEND_URL();
  const target = useEnv.TARGET();
  const buildNumber = useEnv.BUILD_NUMBER();

  const user = useAuthStoreSelectors.user();
  const logout = useAuthStoreSelectors.logout();

  const savedEndpoint = localStorage.getItem(API_ENDPOINT_KEY) || originalEndpoint;
  const endpoint = user ? savedEndpoint : originalEndpoint;

  const setUpdateAvailable = useApiStoreSelectors.setUpdateAvailable();

  const logger = useLogger('api');

  const warningModal = useWarningModal();

  const onUnauthorizedError = useCallback(() => {
    if (user) {
      logout();
    }
    localStorage.removeItem(API_ENDPOINT_KEY);
    useEnvStore.setState({ endpoint: originalEndpoint });
  }, [user]);

  const onRedirect = (newEndpoint: string) => {
    localStorage.setItem(API_ENDPOINT_KEY, newEndpoint);
    useEnvStore.setState({ endpoint: newEndpoint });
  };

  const checkResponseHeaders = useCallback((headers: any) => {
    if (headers['x-build'] !== buildNumber && isWeb) {
      if (endpoint === originalEndpoint) {
        setUpdateAvailable(true);
      } else if (isWeb && headers['x-version'] !== APP_VERSION) {
        onUnauthorizedError();
        warningModal({
          title: <Translate message={messages.general.multiBackend.wrongVersion.title}/>,
          content: <Translate message={messages.general.multiBackend.wrongVersion.content}/>,
        });
      }
    }
  }, [endpoint, warningModal, onUnauthorizedError]);

  return useMemo(() => getClient({
    lid,
    locale,
    logger,
    endpoint,
    target,
    checkResponseHeaders,
    onUnauthorizedError,
    onRedirect,
    version: APP_VERSION,
  }), [lid, locale, logger, endpoint]);
};
