import React, { useMemo } from 'react';
import { Embed } from 'containers';
import { ReportControlContext } from 'modules/reports/index';
import messages from 'messages';
import { ModalControl } from 'components';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons';
import { useApi } from 'providers';

export const Elpho = (props: ReportControlContext) => {

  const { data: { bid } } = props;

  const labels = messages.reports.controls.elpho;

  const { reports: { getReportElpho } } = useApi();
  const file = useMemo(() => getReportElpho({ bid }, { responseType: 'blob' }), [bid]);

  return (
    <ModalControl
      icon={faFileChartLine}
      label={labels.label}
      modal={{
        children: (<Embed file={file}/>),
        footer: null,
        fullScreen: true,
      }}
    />
  );

};
