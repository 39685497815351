import React, { useMemo } from 'react';
import { Translate, useLocale } from 'providers';
import messages from 'messages';

interface Props {
  className?: string;
}

export const Copyright: React.FC<Props> = (props) => {

  const locale = useLocale();

  return useMemo(() => (
    <li className={props.className}>
      <Translate message={messages.general.meta.copyright} values={{ VERSION: APP_VERSION, YEAR: new Date().getFullYear() }}/>
    </li>
  ), [locale, APP_VERSION]);

};
