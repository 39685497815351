import React, { useEffect, useMemo } from 'react';
import { useSSE } from '../SSEProvider';
import makeError from 'make-error';
import { useNetworkStoreSelectors } from 'providers/NetworkCheckProvider';
import { useEnv } from 'providers/EnvProvider';
import { create } from 'zustand';
import { createSelectors } from 'utils/helpers';
import { AlertModal } from 'components';

export const MaintenanceError = makeError('MaintenanceError');

export interface MaintenanceState {
  isMaintenance: boolean;
  setIsMaintenance: (isMaintenance: boolean) => void;
}

export const MaintenanceStore = create<MaintenanceState>()(set => ({
  isMaintenance: undefined,
  setIsMaintenance: isMaintenance => set({ isMaintenance }),
}));

export const useMaintenanceStoreSelectors = createSelectors(MaintenanceStore).use;

export const MaintenanceProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const connection = useSSE.connection();
  const error = useSSE.error();

  const isMaintenance = useMaintenanceStoreSelectors.isMaintenance();
  const setIsMaintenance = useMaintenanceStoreSelectors.setIsMaintenance();

  const { connected } = useNetworkStoreSelectors.status();

  const isDev = useEnv.isDev();
  const isNative = useEnv.isNative();

  const enabled = !isNative && (useEnv.CHECK_WEBSOCKET_MAINTENANCE() || isDev);

  useEffect(() => {
    if (error && enabled && connected) {
      setIsMaintenance(true);
    }
  }, [error]);

  useEffect(() => {
    if (isMaintenance && error && connection) {
      window.location.reload();
    }
  }, [connection, isMaintenance, error]);

  const errorInstance = useMemo(() => new MaintenanceError(), []);

  return (
    <>
      {children}
      <AlertModal
        closable={false}
        open={isMaintenance}
        showIcon
        type={'warning'}
        error={errorInstance}
      />
    </>
  );
};
