import React from 'react';
import { Loader } from 'components';

export function withSuspense<P>(ComposedComponent: React.ComponentType<P>): React.ComponentType<P> {

  return (props: P) => (
    <React.Suspense fallback={<Loader static/>}>
      <ComposedComponent {...props}/>
    </React.Suspense>
  );

}
