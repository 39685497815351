import React, { useEffect, useMemo } from 'react';
import { ConnectionStatus, Network } from '@capacitor/network';
import makeError from 'make-error';
import { create } from 'zustand';
import { createSelectors } from 'utils/helpers';
import { AlertModal } from 'components';

export const NoNetworkError = makeError('NoNetworkError');

export interface NetworkState {
  status: ConnectionStatus;
  setStatus: (status: ConnectionStatus) => void;
}

export const NetworkStore = create<NetworkState>()(set => ({
  status: { connected: true, connectionType: 'unknown' },
  setStatus: status => set({ status }),
}));

export const useNetworkStoreSelectors = createSelectors(NetworkStore).use;

export const NetworkCheckProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const status = useNetworkStoreSelectors.status();
  const setStatus = useNetworkStoreSelectors.setStatus();

  useEffect(() => {
    Network.addListener('networkStatusChange', (newStatus) => {
      setStatus(newStatus);
    });
    return () => {
      Network.removeAllListeners();
    };
  });

  useEffect(() => {
    (async () => setStatus(await Network.getStatus()))();
  }, []);

  const errorInstance = useMemo(() => new NoNetworkError(), []);

  return (
    <>
      {children}
      <AlertModal
        showIcon
        open={!status?.connected}
        error={errorInstance}
      />
    </>
  );
};
