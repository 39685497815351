import React, { useMemo } from 'react';
import { OrderStatus } from 'interfaces/api';
import { faCalendar, faSave } from '@fortawesome/pro-regular-svg-icons';
import { ButtonGroup, Control } from 'components';
import messages from 'messages';
import { useOrdersConfig, useOrdersSelectors } from 'modules/orders/providers';
import dayjs from 'dayjs';
import { useSaveOrExecuteOrders } from 'modules/orders/containers/OrderWizard/providers';
import { useScheduleDateModal } from 'modules/orders/components';

export const SaveOrderControl = () => {

  const saveLabels = messages.orders.controls.save;
  const scheduleLabels = messages.orders.controls.schedule.form;

  const orders = useOrdersSelectors.orders();
  const globalSettings = useOrdersSelectors.globalSettings();

  const saveOrExecute = useSaveOrExecuteOrders();

  const showModal = useScheduleDateModal();

  const { preferences: { ordersPreferScheduleDateMode } } = useOrdersConfig();
  const isOrdersPreferScheduleDateMode = useMemo(() => ordersPreferScheduleDateMode && !globalSettings.scheduled_at, [ordersPreferScheduleDateMode, globalSettings]);
  const changing = useMemo(
    () => [OrderStatus.Queued, OrderStatus.Processing, OrderStatus.PrePrint].includes(orders[0]?.status),
    [orders],
  );
  const buttonType = useMemo(
    () => (isOrdersPreferScheduleDateMode || changing) ? 'primary' : 'default',
    [isOrdersPreferScheduleDateMode, changing],
  );

  const saveScheduled = () => {
    return showModal({
      defaultValue: globalSettings.scheduled_at ? dayjs(globalSettings.scheduled_at) : undefined,
      onDone: async (value) => {
        await saveOrExecute({ scheduled_at: value.format() });
      },
    });
  };

  return (
    <ButtonGroup>
      {(!isOrdersPreferScheduleDateMode || changing) && (
        <Control
          icon={faSave}
          label={changing ? saveLabels.labelChange : saveLabels.label}
          notifications={saveLabels.notifications}
          onClick={async () => {
            await saveOrExecute({ execute: changing });
          }}
          button={{ type: buttonType }}
        />
      )}
      {!changing && (
        <Control
          icon={faCalendar}
          label={saveLabels.saveScheduled}
          onClick={saveScheduled}
          tooltip={{
            title: scheduleLabels.placeholder,
            placement: 'left',
          }}
          button={{ type: buttonType }}
        />
      )}
    </ButtonGroup>
  );

};
