import React, { useEffect, useState } from 'react';
import { useAuthUser } from 'modules/auth/providers';
import { ActionPerformed, PushNotifications as CorePushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { useApi, useLogger } from 'providers';
import { usePrevious } from 'react-use';
import { useEnv } from 'providers/EnvProvider';

export const PushNotificationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const user = useAuthUser();
  const isNative = useEnv.isNative();
  const { devices: { setPushToken } } = useApi();

  const [token, setToken] = useState<string>();
  const previousToken = usePrevious(token);

  const logger = useLogger('PushNotificationProvider');

  useEffect(() => {
    if (token !== previousToken) {
      // @todo error handling
      setPushToken({ pushToken: token });
    }
  }, [token, previousToken, setPushToken]);

  useEffect(() => {

    if (isNative) {

      CorePushNotifications.addListener('registration', ({ value }: Token) => {
        setToken(value);
        logger.info('Registered token', JSON.stringify(token));
      });

      CorePushNotifications.addListener('registrationError', (error: any) => {
        logger.error(error);
      });

      CorePushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
        logger.info('Push received', notification);
      });

      CorePushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        logger.info('Action performed', notification.notification);
        if (data.detailsId) {
          // this.router.navigateByUrl(`/home/${data.detailsId}`);
        }
      });

      return () => {
        CorePushNotifications.removeAllListeners();
      };

    }

    return undefined;

  });

  useEffect(() => {
    if (user && isNative) {
      CorePushNotifications.requestPermissions().then((permission) => {
        if (permission.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          CorePushNotifications.register().then(console.log).catch(console.error);
        } else {
          // No permission for push granted
        }
      }).catch(logger.error);
    }

  }, [user]);

  return children;

};
