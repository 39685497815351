import React from 'react';
import { faBarcode } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { OrderControlContext } from 'modules/orders/index';
import { ChooseWorkstationControl } from 'modules/orders/components/ChooseWorkstationControl';

const labels = messages.orders.controls.printAllBarcodes;

export const PrintAllBarcodes = (props: OrderControlContext) => {

  const { print: { printBarcode } } = useApi();
  const { data: { id, orders }, context: { selectedOrder } } = props;

  const order = orders[selectedOrder || 0];

  const orderId = order?.id || id;

  return (
    <ChooseWorkstationControl
      icon={faBarcode}
      label={labels.label}
      notifications={labels.notifications}
      defaultSelected={order?.apid}
      aid={order?.doctor.aid}
      onDone={async (apid) => {
        await printBarcode({ id: orderId, workstation: apid });
      }}
    />
  );
};
