import React from 'react';
import { Feature } from 'interfaces/api';
import { FormItem, ModalControl } from 'components';
import { filter, sortBy, values } from 'lodash';
import messages from 'messages';
import { faStethoscope } from '@fortawesome/pro-regular-svg-icons';
import { DiagnosesSelect } from 'modules/orders/components/DiagnosesSelect/DiagnosesSelect';
import { Guard } from 'containers';
import { useOrdersConfig } from 'modules/orders/providers';

const labels = messages.orders.wizard.profiles;

type FormValue = { selectedDiagnoses: Record<string, string> };

type Props = {
  value: FormValue;
  onChange: (value: FormValue) => void;
  costUnit: string;
};

export const DiagnosesFormItem: React.FC<Props> = ({ value, onChange, costUnit }) => {

  const { diagnoses } = useOrdersConfig();

  return (
    <Guard feature={Feature.RequirementDiagnoses}>
      <FormItem label={values(value.selectedDiagnoses).join(',') || messages.general.noneSelected}>
        <ModalControl
          icon={faStethoscope}
          label={labels.selectedDiagnoses}
          button={{}}
          modal={{
            footer: null,
            children: ({ hide }) => (
              <DiagnosesSelect
                value={value.selectedDiagnoses}
                diagnoses={sortBy(filter(diagnoses, d => !d.costUnit || d.costUnit === costUnit), 'text')}
                nextLabel={messages.orders.diagnoses.save}
                next={(selectedDiagnoses) => {
                  onChange({ selectedDiagnoses });
                  hide();
                }}
                cancel={hide}
              />
            ),
          }}
        />
      </FormItem>
    </Guard>
  );

};
