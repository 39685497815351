import React from 'react';
import { Message } from 'interfaces';
import { isObject, isString, keys, map, omitBy, pickBy, values } from 'lodash';
import { Descriptions } from 'antd';
import { Translate } from 'providers';
import './JsonExplorer.less';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

type Props = {
  header?: Message;
  json: any;
};

export const JsonExplorer: React.FC<Props> = (props) => {

  const { header, json } = props;

  const objectEntries = pickBy(json, isObject);
  const valueEntries = omitBy(json, isObject);

  return (
    <div className={'json-explorer'}>
      {header && <h3><Translate message={header}/></h3>}
      {values(json).length === 0 && (<i>empty</i>)}
      {keys(objectEntries).length > 0 && (
        <JsonView
          src={json}
          collapsed={2}
          displaySize
          collapseStringMode="word"
          collapseStringsAfterLength={30}
        />
      )}
      {keys(objectEntries).length === 0 && (
        <Descriptions size={'small'} bordered column={1} layout={'horizontal'}>
          {map(valueEntries, (value, key) => (
            <Descriptions.Item label={key}>
              {isString(value) ? value : JSON.stringify(value)}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )}
    </div>
  );
};
