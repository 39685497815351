import React, { useEffect } from 'react';
import { useOfficeDoctorSelectors, useReloadWizardSettings } from 'modules/orders/providers';

type Props = {
  children: React.ReactNode;
};

export const OfficeDoctorProvider: React.FC<Props> = ({ children }) => {

  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const reloadWizardSettings = useReloadWizardSettings();

  useEffect(() => {
    if (officeDoctor?.aid) {
      reloadWizardSettings(officeDoctor?.aid).catch(console.error);
    }
  }, [officeDoctor?.aid]);

  return children;

};
