import React from 'react';
import { Button, ControlProps, Icon } from 'components';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useEnv } from 'providers/EnvProvider';
import { Translate } from 'providers';

type Props = ControlProps & {
  aid: number;
};

const labels = messages.orders.wizard.profiles;

export const ExportProfilesControl = (props: Props) => {

  const endpoint = useEnv.endpoint();
  const lid = useAuthStoreSelectors.lid();

  const { aid } = props;

  return (
    <Button
      className={'control'}
      onClick={e => window.open(endpoint + `/api/orders/profiles/${aid}/pdfexport?lid=${lid}`, '_blank')}
    >
      <Icon icon={faFilePdf}/>
      <Translate message={labels.pdfExport}/>
    </Button>
  );
};
