import React, { CSSProperties, useCallback, useMemo } from 'react';
import { createGlobalState } from 'react-use';
import { LogLevel } from 'interfaces/api';
import { getLogLevelColor, jsToCSS, splitEnumOptions } from 'utils/helpers';
import { getCssColor } from 'utils/dom';
import { filter } from 'lodash';
import { Color } from 'interfaces';

export const useLogLevel = createGlobalState<LogLevel>(import.meta.env.DEV ? LogLevel.Verbose : LogLevel.Error);

type LogFunction = (level: LogLevel, ...messages: any[]) => void;
type LogLevelFunction = (...messages: any[]) => void;

type LogLevelFunctions = {
  [L in LogLevel]: LogLevelFunction
};

export type Logger = {
  log: LogFunction;
  level: LogLevel;
  setLogLevel: (level: LogLevel) => void;
} & LogLevelFunctions;

export class MetaLogMessage {

  constructor(public label: string, public meta: any) {
  }

}

export class ColoredLogMessage {

  constructor(public msg: any, public color: Color) {
  }

}

export class StyledLogMessage {

  constructor(public msg: any, public style: CSSProperties) {
  }

}

const getLogMessageType = (msg: any) => {
  switch (typeof msg) {
    case 'bigint':
    case 'number':
    case 'boolean':
      return '%d';
    case 'string':
      return '%s';
    case 'object':
    case 'undefined':
    default:
      return '%o';
  }
};

const createConsoleString = (...messages: any[]) => {
  const items = messages.reduce((result, msg) => {

    if (msg instanceof ColoredLogMessage) {
      result[0].push('%c' + getLogMessageType(msg.msg));
      result.push('color: ' + getCssColor(msg.color));
      result.push(msg.msg);
    } else if (msg instanceof StyledLogMessage) {
      result[0].push('%c' + getLogMessageType(msg.msg));
      result.push(jsToCSS(msg.style));
      result.push(msg.msg);
    } else {
      result[0].push(getLogMessageType(msg));
      result.push(msg);
    }
    return result;
  }, [[]]);
  items[0] = items[0].join(' ');
  return items;

};

const logLevelNumbers = splitEnumOptions(LogLevel).reduce((result, currentValue, currentIndex) => ({
  ...result,
  [currentValue.value]: currentIndex,
}), {} as Record<LogLevel, number>);

export const useLogger = (name?: string): Logger => {

  const [level, setGlobalLogLevel] = useLogLevel();

  const minLevel = useMemo(() => logLevelNumbers[level], [level]);

  const setLogLevel = useCallback((level: LogLevel) => setGlobalLogLevel(level), [setGlobalLogLevel]);

  const log = useCallback<LogFunction>((level, ...messages) => {

    if (logLevelNumbers[level] > minLevel) {
      return;
    }

    const meta = messages.filter(m => m instanceof MetaLogMessage);

    const label = filter([
      new ColoredLogMessage(level.toUpperCase(), getLogLevelColor(level)),
      name ? new StyledLogMessage(`[${name}]`, { fontWeight: 'bolder' }) : null,
      ...messages.filter(m => !(m instanceof MetaLogMessage)),
    ]);

    console.groupCollapsed(...createConsoleString(...label));
    meta.forEach(m => console.log(...createConsoleString(new StyledLogMessage(m.label, { fontWeight: 'bolder' }), m.meta)));
    console.groupCollapsed('trace');
    console.trace();
    console.groupEnd();
    console.groupEnd();
  }, [minLevel]);

  const levelLog = useMemo(() => splitEnumOptions(LogLevel).reduce((result, level) => ({
    ...result,
    [level.value]: ((...args) => log(level.value, ...args)) as LogLevelFunction,
  }), {} as Record<LogLevel, LogLevelFunction>), [log]);

  return {
    log,
    level,
    setLogLevel,
    ...levelLog,
  };

};

export type InjectedLoggerProps = {
  logger: Logger;
};

type OwnProps<P extends InjectedLoggerProps> = Omit<P, keyof InjectedLoggerProps>;

export function injectLogger<P extends InjectedLoggerProps>(ComposedComponent: React.ComponentType<P>, name?: string): React.ComponentType<OwnProps<P>> {
  return (props: P) => {
    const logger = useLogger(name);
    return <ComposedComponent {...props} logger={logger}/>;
  };
}
