import { useCallback, useMemo } from 'react';
import { useApplyOrderDefaultValues } from 'modules/orders/providers/OrdersProvider/useApplyOrderDefaultValues';
import { useOrdersSelectors, useOrdersStore } from 'modules/orders/providers/OrdersProvider/useOrdersStore';
import { WriteableOrderProperties } from 'interfaces/api';

export const useSetOrders = () => {

  const selectedOrderIndex = useOrdersSelectors.selectedOrderIndex();
  const applyOrderDefaultValues = useApplyOrderDefaultValues();

  /**
   * Set order at specific or selected index
   */
  const setOrder = useCallback((cb: (prevOrder: WriteableOrderProperties) => WriteableOrderProperties, atIndex?: number) => {
    useOrdersStore.setState(({ orders }) => ({
      orders: orders.map((o, oIdx) => {
        const index = atIndex === undefined ? selectedOrderIndex || 0 : atIndex;
        return oIdx === index ? { ...o, ...applyOrderDefaultValues(cb(o)) } : o;
      }),
    }));
  }, [applyOrderDefaultValues, selectedOrderIndex]);

  /**
   * Set all orders or currently selected order
   */
  const setAllOrSelectedOrders = useCallback((cb: (prevOrder: WriteableOrderProperties) => WriteableOrderProperties) => {
    useOrdersStore.setState(({ orders }) => ({
      orders: orders.map((o, oIdx) => {
        return orders.length > 1 && selectedOrderIndex !== undefined && selectedOrderIndex !== oIdx ? o : { ...o, ...applyOrderDefaultValues(cb(o)) };
      }),
    }));
  }, [applyOrderDefaultValues, selectedOrderIndex]);

  /**
   * Set orders with callback
   */
  const setOrders = useCallback((cb: (prevOrders?: WriteableOrderProperties[]) => WriteableOrderProperties[]) => {
    useOrdersStore.setState(({ orders }) => ({ orders: cb(orders).map(o => applyOrderDefaultValues(o)) }));
  }, [applyOrderDefaultValues]);

  /**
   * Set orders with map callback
   */
  const setOrdersMapped = useCallback((cb: (order: WriteableOrderProperties, idx?: number, prevOrders?: WriteableOrderProperties[]) => Partial<WriteableOrderProperties>) => {
    useOrdersStore.setState(({ orders }) => ({ orders: orders.map((o, idx) => applyOrderDefaultValues(cb(o, idx, orders))) }));
  }, [applyOrderDefaultValues]);

  /**
   * Set order properties at index
   */
  const setOrderProperties = useCallback((properties: Partial<WriteableOrderProperties>, atIndex?: number) => {
    useOrdersStore.setState(({ orders }) => ({
      orders: orders.map((o, oIdx) => {
        o = applyOrderDefaultValues(o);
        const index = atIndex === undefined ? selectedOrderIndex || 0 : atIndex;
        return oIdx === index ? { ...o, ...properties } : o;
      }),
    }));
  }, [applyOrderDefaultValues, selectedOrderIndex]);

  /**
   * Set properties for all orders
   */
  const setAllOrdersProperties = useCallback((properties: Partial<WriteableOrderProperties>) => {
    useOrdersStore.setState(({ orders }) => ({
      orders: orders.map(o => ({
        ...applyOrderDefaultValues(o),
        ...properties,
      })),
    }));
  }, [applyOrderDefaultValues]);

  return useMemo(() => ({
    setOrder,
    setAllOrSelectedOrders,
    setOrders,
    setOrdersMapped,
    setOrderProperties,
    setAllOrdersProperties,
  }), [applyOrderDefaultValues, selectedOrderIndex]);

};
