import React from 'react';
import messages from 'messages';
import { FormItemRenderProps } from 'components/Form/Item';
import { OrdersPreferencesProperties } from 'interfaces/api';
import { useApi, useApiCall } from 'providers';
import { Loader } from 'components';

const labels = messages.admin.order.general.barcodeFactor;

export const BarcodeFactorForm: React.ComponentType<FormItemRenderProps<OrdersPreferencesProperties> & { manufacturer?: string }> = (props) => {

  const { DynamicFields, Input, Switch, manufacturer } = props;

  const { orderSettings: { listSamples } } = useApi();
  const loadSamples = useApiCall(async () => (await listSamples({ manufacturer, all: true })).results);

  if (loadSamples.loading) {
    return <Loader/>;
  }

  return (
    <>
      <Input
        type={'number'}
        property={'orderBarcodeFactorDefault'}
        label={labels.defaultFactor}
      />
      <Switch
        property={'orderBarcodeMaterialFactorShowOriginalCount'}
        label={labels.showOriginalCount.label}
        description={labels.showOriginalCount.description}
      />
      <DynamicFields
        property={'orderBarcodeMaterialFactor'}
        horizontal
        ignoreDefaultValue
        addLabel={labels.addSamples}
      >
        {({ Input, Select }) => (
          <>
            <Select
              property={'materialId'}
              showSearch
              floating
              label={labels.sample}
              popupMatchSelectWidth={false}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.children + '').toLowerCase().includes(input.toLowerCase())}
              options={loadSamples.data.map(sample => ({
                value: sample.id,
                children: sample.manufacturer + ' - ' + sample.name,
              }))}
            />
            <Input property={'factor'} label={labels.factor} floating/>
          </>
        )}
      </DynamicFields>
    </>
  );

};
