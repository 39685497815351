import React, { useState } from 'react';
import { useOrderRulesContext } from 'modules/orders/providers';
import { Control, HelgaContainer, HelgaImageType, Select } from 'components';
import messages from 'messages';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { BeforeAfterDialysis as BeforeAfterDialysisEnum } from 'interfaces/api';
import { splitEnumOptions } from 'utils/helpers';
import { useTranslate } from 'providers';

export const BeforeAfterDialysis = () => {

  const { cancel, loading, pending, setPending } = useOrderRulesContext();
  const translate = useTranslate();

  if (!pending) {
    return null;
  }

  const [beforeAfterDialysis, setBeforeAfterDialysis] = useState(pending.orders?.[0]?.beforeAfterDialysis);

  return (
    <HelgaContainer
      title={translate(messages.orders.additionalFields.beforeAfterDialysis)}
      image={HelgaImageType.SelectAbc}
      buttons={[(
        <Control
          label={messages.general.cancel}
          onClick={cancel}
          button={{}}
        />
      ), (
        <Control
          icon={faArrowRight}
          label={messages.general.next}

          onClick={() => setPending({
            ...pending,
            isUpsert: true,
            orders: pending.orders.map(o => ({ ...o, beforeAfterDialysis })),
          })}
          button={{
            loading,
            disabled: !beforeAfterDialysis,
            type: 'primary',
          }}
        />
      )]}
    >
      <div className={'content'}>
        <Select
          options={splitEnumOptions(BeforeAfterDialysisEnum, messages.general.beforeAfterDialysis)}
          onChange={setBeforeAfterDialysis}
        />
      </div>
    </HelgaContainer>
  );

};
