import React from 'react';
import { ListLayout, ListLayoutContext, useGuard } from 'containers';
import messages from 'messages';
import { Container, ListItem, Loader, Tab, Tabs } from 'components';
import styles from './styles.module.less';
import cx from 'classnames';
import { Feature, OrderPatientGroupProps, OrderPatientMetaProperties, OrderWizardLocalization, PatientGDT, Product } from 'interfaces/api';
import { useOfficeDoctorSelectors, useOrdersSelectors } from 'modules/orders/providers';
import { SortGrouping } from 'interfaces';
import { useApi, useFormatDate, useTranslate } from 'providers';
import { filter, sortBy, uniq } from 'lodash';

const PatientList = React.lazy(() => import('modules/admin/users/containers/Patients/index')) as any;

export type PatientsSelectProps = {
  onSelect: (selected: OrderPatientMetaProperties[]) => void;
  index?: number;
};

const labels = messages.orders.userSelect;

export const PatientsSelect = (props: PatientsSelectProps) => {

  const translate = useTranslate();
  const formatDate = useFormatDate();
  const { gdtPatient: { listGdtPatients, listGdtPatientGroups, readGdtPatient, readGdtPatients } } = useApi();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const { onSelect } = props;

  const orders = useOrdersSelectors.orders();
  const guard = useGuard();

  const tabs: Tab[] = [];

  const index = props.index ?? 0;
  const ordersWithoutIndex = orders.filter((a, idx) => idx !== index);

  guard({ product: Product.OB }, () => {
    tabs.push({
      title: labels.tabs.lis,
      children: (
        <Container className={cx(styles.list)}>
          <React.Suspense fallback={<Loader/>}>
            <PatientList
              condensed
              hideFilters
              noDetail
              excluded={uniq(filter(ordersWithoutIndex.map(o => o.patient?.pid > -1 ? o.patient.pid : null)))}
              onSelect={(item: ListItem) => onSelect([item.meta])}
              defaultFilters={{ aid: officeDoctor.aid }}
            />
          </React.Suspense>
        </Container>
      ),
    });
  });

  const transformPatientGDT = (patient: PatientGDT) => ({
    id: patient.apgid,
    title: patient.displayName,
    groupByValue: patient.updated_at,
    meta: patient,
    fields: [{
      value: patient.insuranceNumber || '-',
      label: translate(messages.admin.users.patient.insuranceNumber),
    }],
  });

  guard({ feature: Feature.GdtImport }, () => tabs.push({
    title: officeDoctor.localisation === OrderWizardLocalization.KIS ? labels.tabs.kis : labels.tabs.ais,
    children: (
      <Container className={cx(styles.list)}>
        <ListLayout
          context={{} as ListLayoutContext}
          list={{
            transformResponse: transformPatientGDT,
            transformRequest: ({ context }) => {
              const { filters } = context;
              return { ...filters, aid: officeDoctor.aid, excluded: uniq(filter(ordersWithoutIndex.map(o => o.patient?.apgid > -1 ? o.patient.apgid : null))) };
            },
            request: listGdtPatients,
            onSelect: async (item) => {
              const patient = await readGdtPatient({ apgid: parseInt(item.id + ''), aid: officeDoctor?.aid });
              onSelect([patient]);
            },
            groupBy: SortGrouping.DATE,
            className: styles.list,
            condensed: true,
          }}
          search={{}}
        />
      </Container>
    ),
  }));

  const getGroupId = (group: OrderPatientGroupProps) => group.patients.map(p => p.apgid).join(',');

  guard({ feature: Feature.PoolModule }, () => tabs.push({
    title: labels.tabs.groups,
    children: (
      <Container className={cx(styles.list)}>
        <ListLayout
          context={{} as ListLayoutContext}
          search={{}}
          list={{
            transformResponse: group => ({
              id: getGroupId(group),
              title: group.displayName,
              meta: group,
              groupByValue: formatDate(group.updated_at, { dateOnly: true }),
              fields: [{
                value: formatDate(group.updated_at, { dateOnly: true }),
                label: translate(messages.general.date),
              }],
            }),
            transformRequest: ({ context }) => {
              const { filters } = context;
              return { ...filters, aid: officeDoctor.aid };
            },
            request: listGdtPatientGroups,
            className: styles.list,
            condensed: true,
            groupBy: SortGrouping.DATE,
            onSelect: async (item: ListItem<OrderPatientGroupProps>) => {
              const patients = (await readGdtPatients({ apgids: item.meta.patients.map(p => p.apgid), aid: officeDoctor?.aid }));
              onSelect(wizardSettings?.preferences?.orderSortPoolPatientsByName ? sortBy(patients, ({ displayName }) => displayName) : patients);
            },
          }}
        />
      </Container>
    ),
  }));

  return (
    <Tabs tabs={tabs}/>
  );

};
