import React, { useMemo } from 'react';
import './InputNumber.less';
import { default as AntInputNumber, InputNumberProps as AntNumberProps } from 'antd/es/input-number';
import { Interval } from 'interfaces/api';
import { intervalToSeconds, secondsToInterval, splitEnumOptions } from 'utils/helpers';
import { Select } from 'components/Form/Select';
import messages from 'messages';

export type InputNumberProps = AntNumberProps & {
  intervalValue?: Interval;
  intervalOnChange?: (interval: Interval) => void;
};

export const InputNumber: React.FC<InputNumberProps> = (props) => {

  const { value, onChange, intervalValue, intervalOnChange, ...rest } = props;

  const intervalSelect = useMemo(() => intervalValue
    ? (
      <Select
        options={splitEnumOptions(Interval, messages.general.interval)}
        value={intervalValue}
        onChange={intervalOnChange}
        style={{ width: '105px' }}
      />
    )
    : undefined, [intervalValue, intervalOnChange]);

  return (
    <AntInputNumber
      {...rest}
      value={intervalValue && value ? secondsToInterval(parseInt(value + ''), intervalValue) : value}
      onChange={value => onChange(intervalValue ? intervalToSeconds(parseInt(value + ''), intervalValue) : value)}
      addonAfter={props.addonAfter || intervalSelect}
    />
  );
};
