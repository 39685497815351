import React, { useRef } from 'react';
import { Container, Form, FormProps, FormRenderProps, ModalControl, ModalControlBindings, ModalControlProps } from 'components';
import { ApiRequest, RequestResultComponentProps } from 'containers';
import messages from 'messages';
import { useRenderContent, WithContentOuterProps } from 'hocs';
import { Unpacked } from 'utils/helpers';

type OverwriteFormProps<P extends object, R> = Omit<FormProps<P, R>, keyof WithContentOuterProps>
  & WithContentOuterProps<FormRenderProps<Unpacked<P>> & Partial<RequestResultComponentProps<P>> & Partial<ModalControlBindings>>
  ;

export type ModalFormControlProps<P extends object, R> = ModalControlProps & {
  form: OverwriteFormProps<P, R>;
  keepOpen?: boolean;
};

export const ModalFormControl = <P extends object, R>(props: ModalFormControlProps<P, R>) => {

  const { form, keepOpen, ...modalControlProps } = props;
  const { modal, ...otherProps } = modalControlProps;

  const formSubmit = useRef<() => Promise<void>>(undefined);
  const [renderContent, formProps] = useRenderContent(form || {});

  const renderForm = (args: ModalControlBindings & Partial<RequestResultComponentProps<R>>) => {

    const { notifications } = otherProps;

    return (
      <Form
        {...formProps}
        {...notifications}
        suppressControls
        initialValue={args?.data ? args.data as any : form.initialValue}
        bindSubmit={(submit) => {
          formSubmit.current = submit;
        }}
        onSubmit={(values) => {
          form.onSubmit?.(values);
          args.setLoading(true);
          args.setError(null);
        }}
        onError={(values, error) => {
          if (form.onError) {
            form.onError(values, error);
          } else {
            args.setError(error);
          }
          args.setLoading(false);
        }}
        onSuccess={(values, response) => {
          form.onSuccess?.(values, response);
          args.setLoading(false);
          !keepOpen && args.hide();
        }}
        children={formArgs => (
          <Container grow shrink onKeyDown={e => e.stopPropagation()} onKeyUp={e => e.stopPropagation()}>
            {renderContent({ ...args, ...formArgs })}
          </Container>
        )}
      />
    );

  };

  const { notifications, ...controlProps } = otherProps;

  const apiRequest = (form.initialValue as ApiRequest<any>);

  return (
    <ModalControl
      {...controlProps}
      modal={{
        okText: modal && modal.okText || messages.general.save,
        keyboard: false,
        maskClosable: false,
        ...modal,
        onOk: () => formSubmit.current(),
        children: modalArgs => apiRequest?.request
          ? (
            <ApiRequest
              request={apiRequest?.request}
              children={requestArgs => renderForm({ ...modalArgs, ...requestArgs })}
            />
          )
          : renderForm(modalArgs),
      }}
    />
  );

};
