import React, { useState } from 'react';
import messages from 'messages';
import { filter, keys, omit } from 'lodash';
import { Control, HelgaContainer, HelgaImageType, Tooltip } from 'components';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { AggregatedDiagnosisProperties } from 'interfaces/api';
import { AvailableDiagnosis } from 'modules/orders/providers';
import { Message } from 'interfaces';

type DiagnosesSelectProps = {
  diagnoses: AvailableDiagnosis[];
  value: Record<string, string>;
  next?: (selectedDiagnoses: Record<string, string>) => void;
  cancel?: () => void;
  loading?: boolean;
  nextLabel?: Message;
};

export const DiagnosesSelect: React.FC<DiagnosesSelectProps> = ({ value, diagnoses, next, cancel, loading, nextLabel }) => {

  const [selectedDiagnoses, setSelectedDiagnoses] = useState(value);

  const toggleDiagnosis = (diagnosis: AggregatedDiagnosisProperties) => {
    setSelectedDiagnoses(selectedDiagnoses[diagnosis.id]
      ? omit(selectedDiagnoses, diagnosis.id)
      : { ...selectedDiagnoses, [diagnosis.id]: diagnosis.key });
  };

  return (
    <HelgaContainer
      title={messages.orders.requirementOptions.diagnosis.title}
      text={messages.orders.requirementOptions.diagnosis.text}
      image={HelgaImageType.MagnifierBacterium}
      buttons={filter([cancel && (
        <Control
          label={messages.general.cancel}
          onClick={cancel}
          button={{}}
          disabled={loading}
        />
      ), next && (
        <Control
          icon={faArrowRight}
          label={nextLabel || messages.general.next}
          onClick={() => next(selectedDiagnoses)}
          disabled={keys(selectedDiagnoses).length === 0}
          button={{
            loading,
            type: 'primary',
          }}
        />
      )])}
    >
      <ul className={'block-buttons'}>
        {(diagnoses || []).map(diagnosis => (
          <li key={diagnosis.id}>
            <Tooltip placement={'topRight'} title={diagnosis.requiredBy?.join(', ')}>
              <div>
                <Control
                  button={{ block: true }}
                  label={diagnosis.text}
                  onClick={() => toggleDiagnosis(diagnosis)}
                  className={cx({ 'button-selected': selectedDiagnoses[diagnosis.id] })}
                  alertBadge={diagnosis.requiredBy?.length > 0}
                />
              </div>
            </Tooltip>
          </li>
        ))}
      </ul>
    </HelgaContainer>
  );
};
