import React from 'react';
import { ApiRequest } from 'containers';
import { useApi } from 'providers';
import { Control, PopOvers } from 'components';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';

type Props = {
  id: number;
  reset: () => void;
};

export const CaseAutocomplete = (props: Props) => {

  const { cases: { readCase } } = useApi();

  const reset = (
    <Control
      label={messages.general.reset}
      icon={faTimesCircle}
      tooltip={{ placement: 'right' }}
      onClick={props.reset}
    />
  );

  return (
    <ApiRequest
      errorAsAlert
      request={() => readCase({ id: props.id })}
      children={({ data }) => {
        return <PopOvers.Case case={data} reset={reset}/>;
      }}
    />
  );
};
