import React, { useMemo } from 'react';
import { useApi, useApiCall } from 'providers';
import { ListLayoutSideBarSection } from 'containers/ListLayout/SideBar/SideBar';
import { Checkbox, Loader } from 'components';
import { chain, last, map } from 'lodash';
import { ListLayoutArgs } from 'containers/ListLayout/ListLayout';
import { CasesListContext } from 'modules/cases';
import { toggleArrayItem } from 'utils/helpers';

export const DoctorHierarchySection: React.FC<ListLayoutArgs<CasesListContext>> = (props) => {

  const { context: { aids }, bindings: { setContext } } = props;

  const { doctorHierarchy: { getDoctorHierarchyTree } } = useApi();
  const tree = useApiCall(getDoctorHierarchyTree);

  const groups = useMemo(() => {
    return tree.data?.length > 1
      ? chain(tree.data.filter(({ path }) => path.length > 1))
        .groupBy(({ path }) => path.slice(0, -1).map(p => p.name).join(' > '))
        .toPairs()
        .sortBy(0)
        .fromPairs()
        .value()
      : {};
  }, [tree.data]);

  if (tree.loading) {
    return <Loader/>;
  }

  return (
    <>
      {map(groups, ((items, title) => (
        <ListLayoutSideBarSection key={title} title={title}>
          <ul>
            {items.map(({ path }, idx) => {
              const { aid, name } = last(path);
              return (
                <li key={idx}>
                  <Checkbox
                    checked={aids?.includes(aid)}
                    onChange={() => setContext({
                      aids: toggleArrayItem(aids || [], aid),
                    })}
                  >
                    {name}
                  </Checkbox>
                </li>
              );
            })}
          </ul>
        </ListLayoutSideBarSection>
      )))}
    </>
  );
};
