import { DependencyList, useEffect, useState } from 'react';
import { ResponseType } from 'containers';

type ApiLoading<T> = {
  loading: true;
  data?: undefined;
  error?: undefined;
};

type ApiError<T> = {
  loading: false;
  error: Error;
  data?: undefined;
};

type ApiResult<T> = {
  data: T;
  loading: false;
  error?: undefined;
};

type ApiCallState<T> =
  | ApiLoading<T>
  | ApiError<T>
  | ApiResult<T>
  ;

type UseApiResult<T> = ApiCallState<T> & {
  reload: () => void;
  update: (data: T) => void;
};

export const useApiCall = <R, T = ResponseType<R>>(fn: () => Promise<R>, deps?: DependencyList): UseApiResult<T> => {

  const [state, setState] = useState<ApiCallState<T>>({ loading: true });

  const load = () => {
    fn().then(((data) => {
      setState({ data, loading: false } as any);
    })).catch((error: Error) => {
      setState({ error, loading: false });
    });
  };

  useEffect(load, deps || []);

  const update = (data: T) => {
    setState({ data, loading: false } as any);
  };

  return { ...state, update, reload: load };

};
