import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs as AntdTabs } from 'antd';
import { Badge, BadgeProps, Container, Icon } from 'components';
import './Tabs.less';
import { useLocation } from 'react-router';
import * as pathToRegexp from 'path-to-regexp';
import queryString from 'query-string';
import { I18nMessage } from 'interfaces';
import { TabsProps } from 'antd/es/tabs';
import { Translate } from 'providers';
import { withContent, WithContentOuterProps } from 'hocs';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { filter, kebabCase } from 'lodash';
import { useGuard } from 'containers';
import { useNavigate, useParams } from 'react-router-dom';

export type Tab = {
  title: I18nMessage | string;
  props?: any;
  baseUrl?: string;
  badge?: BadgeProps;
} & WithContentOuterProps<object>;

const TabComponent = withContent((props: any) => {
  return props.renderContent({});
});

type ComponentProps = {
  tabs: Tab[];
  withLocation?: boolean;
  destroyInactiveTabPane?: boolean;
  className?: any;
};

type Props = ComponentProps & TabsProps;

const getTabKey = (tab: Tab) => typeof tab.title === 'string' ? kebabCase(tab.title) : kebabCase(tab.title.defaultMessage);

export const Tabs = (props: Props) => {

  const { defaultActiveKey, tabs, withLocation, ...tabsProps } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const guard = useGuard();

  const keys = useMemo(() => tabs.map(getTabKey), [tabs]);

  const getDefaultKey = useCallback(() => {
    return defaultActiveKey || tabs.length > 0 ? keys[0] : null;
  }, [defaultActiveKey, tabs]);

  const getKeyFromLocation = useCallback(() => {

    const parsed = queryString.parse(location.search).tab;
    const tab = parsed ? parsed.toString() : undefined;
    return keys.indexOf(tab) === 0 ? undefined : tab;

  }, [location, tabs]);

  const [current, setCurrent] = useState<string>(withLocation ? getKeyFromLocation() : getDefaultKey());

  useEffect(() => withLocation && setCurrent(getKeyFromLocation() || getDefaultKey()), [location]);

  const renderTitle = useCallback((tab: Tab) => (
    <span className={'tab-with-badge'}>
      <Translate message={tab.title}/>
      {tab.badge && <Badge {...tab.badge} className={'tab-badge'}/>}
    </span>
  ), []);

  const onChange = useCallback((key: string) => {
    if (withLocation) {

      const tab = keys.indexOf(key) === 0 ? undefined : key;

      const toPath = pathToRegexp.compile(location.pathname);
      const pathname = toPath({ ...params, tab });

      navigate({ pathname, search: queryString.stringify({ tab }) });

    } else {
      setCurrent(key);
    }
  }, [withLocation, params, navigate]);

  const antdTabsProps = useMemo(() => Object.assign({
    onChange,
    defaultActiveKey: current,
    activeKey: current,
  }, tabsProps || {}), [onChange, tabsProps, current]);

  const tabItems = useMemo(() => filter(tabs.map(tab => guard(tab.guard || {}, () => ({
    label: renderTitle(tab),
    key: getTabKey(tab),
    children: <TabComponent {...tab}/>,
  })))), [tabs, guard]);

  return (

    <Container grow shrink className={props.className}>

      <AntdTabs
        {...antdTabsProps}
        items={tabItems}
        type={'card'}
        moreIcon={<Icon icon={faArrowDown}/>}
        destroyInactiveTabPane={props.destroyInactiveTabPane}
      />

    </Container>

  );

};
