import { Messages } from 'interfaces';
import { WorkstationGroup } from './interfaces.ts';

export default Messages({
  status: {
    [WorkstationGroup.Disconnected]: 'Disconnected',
    [WorkstationGroup.Manual]: 'Alias',
    [WorkstationGroup.Connected]: 'Connected',
  },
});
