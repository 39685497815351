export default {
  subheader: '',
  bannerText: 'Save the way to your laboratory.<br/>Find your results online',
  request_account: 'Request account',
  quickScan: {
    content: '<h3>Covid-19</h3>',
    description: 'Press the barcode button to find your test results.',
  },
  faceIdRequest: {
    description: 'Would you like to use biometric authentication in addition?',
    notice: 'If you want to activate the biometric authentication later, use the "Profile & Settings" menu.',
    useBiometrics: 'Use biometrics',
    postponeUseBiometrics: 'No, activate later',
  },
  identifier: 'Identifier',
  backToLogin: 'Back to login',
  deviceNotActivated: {
    title: 'Device not activated',
    content: 'Your device is not activated yet. Your laboratory has been informed and will let you know when you can try to login again.',
  },
  welcome: 'Welcome back',
  description: 'Login with username',
  descriptionWithQuickAccess: 'Or login with username',
  infoModal: {
    title: 'How can I login here?',
    content: 'After successfully registering with your doctor or laboratory, you will receive your online-results access data. You can use this to log into your account and view your laboratory results. If you have received a QR code, you can alternatively view your results via the quick results access. If you have any questions, please contact our hotline.',
    backToLogin: 'Back to login',
  },
  password: 'Password',
  submit: 'Login',
  biometrics: 'Use biometrics',
  loginWithCredentials: 'Login with credentials',
  forgot_password: 'Forgot password?',
  info: {
    reset_password: 'You can now login with your newly set password.',
    sent_reset_email: 'Your password has been reset. You will receive an e-mail with further instructions.',
  },
  expired: 'You have been logged out automatically due to security reasons.',
  additionalContent: '',
  toc: {
    title: 'Terms and conditions',
    accept: 'Accept',
  },
  autoLoginError: {
    title: 'Biometric login not possible.',
    content: 'Please login using your credentials.',
  },
  twoFactor: {
    title: 'Two factor authentication',
    header: 'Enter the authenticator password',
    description: 'If you need any assistance, please contact your laboratory',
    submit: 'Submit password',
  },
};
