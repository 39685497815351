import React from 'react';
import { Alert, Button, Container, Panel } from 'components';
import { withContent, WithContentInnerProps, WithContentOuterProps } from 'hocs';
import { default as AntForm } from 'antd/es/form';
import { InjectedLoggerProps, injectLogger, Translate, useIntlStore } from 'providers';
import { InjectedAntAppProps, withAntApp } from 'hocs/withAntApp';
import './Form.less';
import { FormItemRenderProps, Items, TItems } from 'components/Form/Item';
import { ApiRequest, RequestResultComponentProps, ResponseType } from 'containers';
import { arrayify, Unpacked } from 'utils/helpers';
import { BaseError } from 'make-error';
import { Message } from 'interfaces';
import { cloneDeep, filter, get, isEqual, set } from 'lodash';
import cx from 'classnames';
import messages from 'messages';

export type FormProps<P extends object, R> = {
  request?: (values: P) => R;
  bindSubmit?: (submit: () => Promise<void>) => void;
  onSubmit?: (values: P) => void;
  onSuccess?: (values: P, response: ResponseType<R>) => void;
  onChange?: (values: P) => void;
  onError?: (values: P, errors: BaseError) => any;
  defaultValues?: Promise<any>;
  okButtonMessage?: Message;
  initialValue?: P | ApiRequest<Promise<P>>;
  enableAutoComplete?: boolean;
  disabled?: boolean;
  suppressControls?: boolean;
  suppressNotifications?: boolean;
  showSaveSuccessNotification?: boolean;
  resetOnSuccess?: boolean;
  withUpload?: boolean;
  className?: string;
  scrollY?: boolean;
  noShrink?: boolean;
  required?: string[];
  error?: any;
  resetOnError?: boolean | ((values: P) => P);
  notifications?: {
    success?: Message;
    error?: Message;
  };
} & WithContentOuterProps<FormRenderProps<Unpacked<P>> & Partial<RequestResultComponentProps<P>>>;

export type FormRenderProps<P> = {
  submit: (temporaryValues?: Partial<P>) => any;
  loading: boolean;
  isDirty: boolean;
  error: BaseError;
  reset?: (value?: P) => void;
} & FormItemRenderProps<P & RequestResultComponentProps<P>>;

type Props<P extends object, R> = FormProps<P, R> & InjectedLoggerProps & InjectedAntAppProps & WithContentInnerProps<FormRenderProps<P>>;

type State<P extends object> = Readonly<{
  value?: P;
  initialValue?: P;
  defaultValues?: P;
  loading?: boolean;
  error?: any;
}>;

class FormClass<P extends object, R> extends React.PureComponent<Props<P, R>, State<P>> {

  Items: TItems<P>;

  constructor(props: Props<P, R>) {
    super(props);

    const initialValue = props.initialValue && (props.initialValue as ApiRequest<any>).request ? undefined : props.initialValue as P || {} as P;

    this.state = {
      initialValue,
      value: initialValue,
    };

    this.Items = Items({
      // @ts-expect-error todo
      getValue: property => this.state.value?.[property],
      getId: property => property,
      onChange: (property, value) => this.onChange({ [property]: value } as any),
      getDisabled: () => this.state.loading || props.disabled,
      getRequired: property => this.getRequired(property),
      getDefaultValue: property => this.getDefaultValue(property),
      getError: (property) => {
        const error = this.props.error || this.state.error;
        if (error?.name === 'InputError') {
          const obj = {};
          arrayify(error.message).forEach((e) => {
            const path = filter([e.instancePath, e.params?.missingProperty]).join('/');
            set(obj, path?.replace(/^\//, '').split('/').join('.'), e.message);
          });
          // @ts-expect-error todo
          return obj[property];
        }
        return undefined;
      },
    });

  }

  componentDidMount() {
    const { bindSubmit } = this.props;
    bindSubmit?.(this.onSubmit);
  }

  translate() {
    return useIntlStore.getState().translate;
  }

  showSuccess = () => {
    const { notifications, showSaveSuccessNotification } = this.props;
    if (notifications?.success) {
      this.props.message.success(useIntlStore.getState().translate(notifications.success));
    } else if (showSaveSuccessNotification) {
      this.props.message.success(useIntlStore.getState().translate(messages.general.saveSuccess));
    }
  };

  showError = (e: Error) => {
    this.props.message.error(useIntlStore.getState().translate(messages.errors.occurred));
  };

  onSubmit = async (temporaryValues: Partial<P> = {}) => {

    const { request, onSubmit, onSuccess, onError, suppressNotifications, resetOnSuccess, resetOnError, initialValue } = this.props;

    const value = { ...this.state.value, ...temporaryValues };

    onSubmit?.(value);

    this.setState({ loading: true, error: null });

    try {

      const result = await request(value);
      const nextValue = resetOnSuccess ? initialValue as any : this.state.value;
      this.setState({ loading: false, initialValue: nextValue, value: nextValue });

      onSuccess?.(value, result as any);

      !suppressNotifications && this.showSuccess();

    } catch (error) {

      this.props.logger.error(error);
      onError && await onError(value, error);

      if (resetOnError) {
        this.reset(resetOnError === true ? undefined : resetOnError(value));
      }

      this.setState({ error, loading: false });
      !suppressNotifications && this.showError(error);
    }

  };

  onChange = (value: Partial<P>) => {
    const { onChange } = this.props;
    this.setState({ value: { ...this.state.value, ...value } }, () => onChange?.(this.state.value));
  };

  getRequired = (property: any) => {
    return this.props.required?.includes(property);
  };

  reset = (value?: P) => this.setState({ value: value || this.state.initialValue, initialValue: value || this.state.initialValue, error: null });

  getDefaultValue = (path: string) => {
    return get(this.state.defaultValues, path);
  };

  render() {

    const { disabled, suppressControls, withUpload, className, request, scrollY } = this.props;
    const { error, loading, value, initialValue } = this.state;

    // autocomplete should be disabled by default
    const autoComplete = this.props.enableAutoComplete ? undefined : 'off';

    const formProps = {
      autoComplete,
    };

    const isInitialValue = isEqual(value, initialValue);

    const htmlSubmit = () => {
      request !== undefined && this.onSubmit();
    };

    const renderForm = (data?: any) => (
      <Container grow shrink={!this.props.noShrink} className={cx('form-container', className)}>
        <AntForm onFinish={htmlSubmit} {...formProps} encType={withUpload ? 'multipart/form-data' : undefined}>

          <Container grow shrink scrollY={scrollY}>
            {this.props.renderContent({
              value,
              ...data,
              loading,
              error,
              ...this.Items,
              disabled: loading || disabled,
              isDirty: !isInitialValue,
              onChange: this.onChange,
              submit: this.onSubmit,
              reset: this.reset,
              getRequired: this.getRequired,
              getDefaultValue: this.getDefaultValue,
            })}
          </Container>

          {!suppressControls && (
            <Panel
              hidden={isInitialValue}
              controls={(
                <>
                  <Button disabled={isInitialValue || loading} onClick={() => this.setState({ value: initialValue, error: null })}>
                    <Translate message={messages.general.reset}/>
                  </Button>

                  <Button loading={loading} disabled={isInitialValue} htmlType={'submit'} type={'primary'}>
                    <Translate message={this.props.okButtonMessage || messages.general.save}/>
                  </Button>
                </>
              )}
            >
              {error && (
                <Alert showIcon error={error} className={'form-error'} hideDescription={error.name === 'InputError'}/>
              )}
            </Panel>
          )}

        </AntForm>
      </Container>
    );

    const apiRequest = (this.props.initialValue as ApiRequest<any>);

    if (apiRequest?.request) {
      return (
        <ApiRequest
          {...apiRequest}
          staticLoader
          onLoaded={initialValue => new Promise(async (resolve, reject) => {
            if (apiRequest.onLoaded) {
              apiRequest.onLoaded(initialValue);
            }

            let defaultValues: P;
            if (this.props.defaultValues) {
              defaultValues = await Promise.resolve(this.props.defaultValues);
            }

            this.setState({ initialValue, defaultValues, value: cloneDeep(initialValue) }, () => resolve(undefined));
          })}
          children={renderForm}
        />
      );
    }

    return renderForm();

  }

}

const FormComponent = injectLogger(withAntApp(withContent(FormClass)) as any);

export const Form = <P extends object, R>(props: FormProps<P, R>) => {
  return <FormComponent {...props} />;
};
