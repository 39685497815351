import React from 'react';
import { ControlProps } from 'components';
import './ButtonGroup.less';

export interface ButtonGroupProps {
  children: React.ReactElement<ControlProps>[];
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children }) => {
  return (
    <div className={'button-group'}>
      {children}
    </div>
  );
};
