import React from 'react';
import { useApi } from 'providers';
import { ListNotificationsDto, Notification, NotificationStatus } from 'interfaces/api';
import { Container, Control, Header, List, ListItem } from 'components';
import { convertToListItem, useNotificationLocation } from 'modules/notifications/utils.tsx';
import { Dismiss } from 'modules/notifications/components/Controls/Dismiss.tsx';
import messages from 'messages';
import { faCheckDouble, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Read } from 'modules/notifications/components/Controls/Read.tsx';
import NotificationSettings from 'modules/notifications/components/Settings/NotificationSettings.tsx';

type NotificationListProps = {
  data: ListNotificationsDto;
  load: (offset?: number) => void;
  toggle: (nextValue?: boolean) => void;
};

export const NotificationList: React.FC<NotificationListProps> = (props) => {

  const { notifications: { dismissNotification, readAllNotifications, readNotification } } = useApi();

  const { data, load } = props;

  const notificationLocation = useNotificationLocation();

  const onItemClick = async (item: ListItem<Notification>, reload: any) => {
    if (item.meta.status === NotificationStatus.Unread) {
      await readNotification({ id: item.meta.id });
    }
    reload();
    notificationLocation(item.meta);
    props.toggle(false);
  };

  return (
    <>
      <Header
        title={messages.general.topBar.notifications}
        maxVisibleControls={3}
        controls={[
          (
            <NotificationSettings/>
          ),
          (
            <Control
              key={'dismiss'}
              onClick={async () => {
                await readAllNotifications();
              }}
              icon={faCheckDouble}
              label={messages.notifications.dismissAll}
            />
          ),
          (
            <Control
              key={'close'}
              onClick={() => props.toggle()}
              icon={faTimes}
              label={messages.general.close}
            />
          ),
        ]}
      />
      <Container scrollY grow shrink className={'notification-list-wrapper'}>
        <List
          items={data.results.map(v => convertToListItem(v))}
          hideCaretOnSelect={true}
          className={'notification-list-container'}
          onItemClick={item => onItemClick(item, load)}
          getControls={(item) => {
            return [item.meta.status === NotificationStatus.Unread
              ? (
                <Read
                  key={item.id}
                  readNotification={async () => {
                    await readNotification({ id: item.id as number });
                  }}
                />
              )
              : undefined, (
              <Dismiss
                key={item.id}
                dismissNotification={async () => {
                  await dismissNotification({ id: item.id as number });
                }}
              />
            )];
          }}
        />
      </Container>
    </>
  );
};
