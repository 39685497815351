import { createContext, useContext } from 'react';
import { useStore } from 'zustand';
import { ListLayoutBindingsState, ListLayoutBindingsStore } from 'containers';
import { StoreProductsListLayoutContext } from './interfaces';

export const StoreProductsListLayoutBindingsContext = createContext<ListLayoutBindingsStore<StoreProductsListLayoutContext> | null>(null);

export function useStoreProductsListLayoutBindingsContext<T>(selector: (state: ListLayoutBindingsState<StoreProductsListLayoutContext>) => T): T {
  const store = useContext(StoreProductsListLayoutBindingsContext);
  if (!store) throw new Error('Missing StoreProductsListLayoutBindingsContext.Provider in the tree');
  return useStore(store, selector);
}
