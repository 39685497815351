import React from 'react';
import { Message } from 'interfaces';
import { withSuspense } from 'hocs';

export type FileChooserProps = {
  label?: Message | React.ReactNode;
  value?: File;
  src?: string;
  onChange?: (file: File) => void;
  className?: string;
  disabled?: boolean;
  isImage?: boolean;
  clearable?: boolean;
};

export const FileChooser = withSuspense(React.lazy(() => import('./FileChooser')));
