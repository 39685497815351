import { Feature, OrderPatientGDTSharedProperties } from 'interfaces/api';
import { useCallback } from 'react';
import { find } from 'lodash';
import { useGuard } from 'containers';
import { useOfficeDoctorSelectors } from 'modules/orders/providers/OfficeDoctorProvider';

export const useMapSwitzerlandInsuranceValues = () => {

  const guard = useGuard();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const switzerlandInsuranceMappingFeature = guard({ feature: Feature.SwitzerlandInsuranceMapping }, () => true);

  return useCallback((data: OrderPatientGDTSharedProperties): OrderPatientGDTSharedProperties => {

    if (!switzerlandInsuranceMappingFeature) {
      return data;
    }

    if (data.globalLocationNumber?.length) {
      const code = find(wizardSettings?.che_bag_to_glns, { gln: data.globalLocationNumber })?.bag_code;
      const bag = find(wizardSettings?.che_bags, { code });
      data.bag = bag?.abbreviation || code;
      data.insuranceName = find(wizardSettings?.che_bags, { code: data.bag })?.name;
    } else if (data.bag?.length) {
      data.insuranceName = find(wizardSettings?.che_bags, { code: data.bag })?.name;
      data.globalLocationNumber = find(wizardSettings?.che_bag_to_glns, { bag_code: data.bag })?.gln;
    } else if (data.insuranceName?.length) {
      const bag = find(wizardSettings?.che_bags, { name: data.insuranceName });
      data.bag = bag?.abbreviation || bag?.code;
      data.globalLocationNumber = find(wizardSettings?.che_bag_to_glns, { bag_code: bag?.code })?.gln;
    }

    return data;

  }, [switzerlandInsuranceMappingFeature, wizardSettings]);

};
