import { useApi } from 'providers';
import { useOrdersSelectors } from 'modules/orders/providers/OrdersProvider/useOrdersStore';
import { OrderPatientType, Product } from 'interfaces/api';
import { useEffect } from 'react';
import { useCurrentOrder } from 'modules/orders/providers/OrdersProvider/useCurrentOrder';
import { useGuard } from 'containers';

export const useLoadPatientReports = () => {

  const orders = useOrdersSelectors.orders();
  const selectedOrderIndex = useOrdersSelectors.selectedOrderIndex();

  const guard = useGuard();
  const { gdtPatient: { getPatientReports } } = useApi();

  const setReports = useOrdersSelectors.setPatientReports();
  const resetReports = useOrdersSelectors.resetReports();

  const patient = useCurrentOrder()?.patient;

  const loadReports = () => {
    if (selectedOrderIndex !== undefined || orders.length === 1 && guard({ product: Product.OB }, () => true)) {
      if (patient?.pid > 0) {
        getPatientReports({ id: patient.pid, type: OrderPatientType.LIS }).then(setReports).catch(() => resetReports());
      } else if (patient.apgid > 0) {
        getPatientReports({ id: patient.apgid, type: OrderPatientType.AIS }).then(setReports).catch(() => resetReports());
      } else {
        resetReports();
      }
    } else {
      resetReports();
    }
  };

  useEffect(loadReports, [selectedOrderIndex, patient?.apgid, patient?.pid]);

};
