import React from 'react';
import { AggregatedProfileType, AggregatedRequirementFlags, OrderWizardRequirement, RequirementType } from 'interfaces/api';
import styles from './styles.module.less';
import { getRequirementReportFlag, useBasketContext, useRequirementFilteredForms } from 'modules/orders/containers/OrderWizard/providers';
import { Header, HeaderProps, Icon } from 'components';
import cx from 'classnames';
import { faFileAlt, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useOfficeDoctorSelectors } from 'modules/orders/providers';
import { filter, find } from 'lodash';
import { isProfile, isRequirement, useGetRequirementPriceBadge } from 'modules/orders/containers/OrderWizard/utils';
import { getRequirementShortName } from 'modules/orders/utils';
import messages from 'messages';
import { Translate } from 'providers';
import { useRequirementHeaderControls } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/ControlsMenu';
import { useEnv } from 'providers/EnvProvider';
import { Color } from 'interfaces';
import { secondsToInterval } from 'utils/helpers.ts';

export const PopOverHeader = (props: {
  requirement: OrderWizardRequirement;
  formId?: number;
  profileId?: number;
  profileType?: AggregatedProfileType;
  basket?: boolean;
  showControls?: boolean;
}) => {

  const { requirement, basket, profileId, profileType, formId } = props;

  const getFlag = getRequirementReportFlag();
  const filterForms = useRequirementFilteredForms();
  const getRequirementPriceBadge = useGetRequirementPriceBadge();

  const lid = useAuthStoreSelectors.lid();
  const endpoint = useEnv.endpoint();

  const { forms, preferences: { orderWizardWrapPopoverHeader, orderWizardUnwrapListTitle } } = useOfficeDoctorSelectors.wizardSettings();

  const isNormalRequirement = isRequirement(requirement);

  const images = isNormalRequirement
    ? filter((requirement.materials || []).map((material) => {
      return `${endpoint}/api/orders/samples/${material.sampleId}/image/small?lid=${lid}`;
    }))
    : undefined;

  const availableForms = isNormalRequirement
    ? filter(filterForms({ ...requirement, formId: requirement.formId || formId }).map(id => find(forms, { id })))
    : [];

  const formTitle = availableForms.length === 1
    ? (
      <span className={styles.formSubtitle}>
        <Icon icon={faFileAlt}/>
        <Translate message={messages.orders.wizard.formCostUnitFormat} values={{ name: availableForms[0]?.name, costUnit: availableForms[0]?.costUnit }}/>
      </span>
    )
    : undefined;

  const durationSubtitle = requirement.examinationDuration
    ? (
      <Translate message={messages.general.intervalPlurals[requirement.examinationDurationInterval]}
        values={{ time: secondsToInterval(requirement.examinationDuration, requirement.examinationDurationInterval) }}/>
    )
    : undefined;

  const { profileRequirementsPartialInBasket, isInBasket } = useBasketContext();

  const faded = isProfile(requirement) ? profileRequirementsPartialInBasket(requirement) : isInBasket(requirement);
  const controls = useRequirementHeaderControls()(requirement, profileId, profileType);

  const isCancelled = isNormalRequirement && requirement.cancelled_at;

  const headerProps: HeaderProps = {
    images,
    controls: props.showControls ? controls : null,
    flag: isNormalRequirement ? getFlag(requirement) : undefined,
    title: <>{requirement.longName}{isCancelled ? <Icon icon={faTimesCircle} color={Color.Red} className={'margin-left-1'}/> : null}</>,
    subtitle: <>{getRequirementShortName(requirement)}{formTitle}{isNormalRequirement ? getRequirementPriceBadge(requirement) : null} {durationSubtitle && ','} {durationSubtitle || null} {}</>,
    wrapTitle: orderWizardWrapPopoverHeader,
    maxVisibleControls: controls.length,
    className: cx(styles.header, {
      [styles.isProfile]: isProfile(requirement),
      [styles.mibiItem]: isRequirement(requirement) && requirement.entityType === RequirementType.Microbiological,
      [styles.faded]: (basket && !faded) || (!basket && faded) || isCancelled,
      [styles.isGlobalProfile]: isProfile(requirement) && requirement.profileType === AggregatedProfileType.Global,
      [styles.isSuperRequirement]: requirement.flags?.includes(AggregatedRequirementFlags.IsSuperRequirement),
      [styles.unwrapHeader]: orderWizardUnwrapListTitle,
    }),
  };

  return <Header {...headerProps}/>;

};
