import React from 'react';
import { Control } from 'components';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';

type Props = {
  dismissNotification: () => any;
};

export const Dismiss = (props: Props) => (
  <Control
    icon={faTrash}
    onClick={props.dismissNotification}
    label={messages.notifications.dismiss}
  />
);
