import React, { useCallback, useState } from 'react';
import constate from 'constate';
import { OrdersConfig } from 'interfaces/api';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useApi } from 'providers';
import { useAsync } from 'react-use';

type ConfigContextInitialValue = {
  config: OrdersConfig;
};

const configContext = (initialValue: ConfigContextInitialValue) => {

  const [config, setInternalConfig] = useState<OrdersConfig>(initialValue.config);
  const setConfig = useCallback((newConfig: Partial<OrdersConfig>) => setInternalConfig(prev => ({ ...prev, ...newConfig })), []);

  return { config, setConfig };

};

const [ConfigInternalProvider, useOrdersConfig, useSetOrdersConfig] = constate(
  configContext,
  values => values.config,
  values => values.setConfig,
);

export {
  useOrdersConfig,
  useSetOrdersConfig,
};

export const OrdersConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const lid = useAuthStoreSelectors.lid();

  const { orders: { getOrdersConfig } } = useApi();

  const config = useAsync(getOrdersConfig, [lid]);

  if (config.loading) {
    return null;
  }

  if (config.error) {
    throw config.error;
  }

  return (
    <ConfigInternalProvider config={config.value}>
      {children}
    </ConfigInternalProvider>
  );
};
