import { getToken, useApi, useBiometricsStoreSelectors, useIntlStoreSelectors } from 'providers';
import { useErrorModal } from 'components';
import { useCallback } from 'react';
import messages from 'messages';
import { useAuthStoreSelectors } from 'modules/auth/store';

export const useAutoLogin = () => {

  const user = useAuthStoreSelectors.user();
  const setUser = useAuthStoreSelectors.setUser();
  const setLoadingAutoLogin = useAuthStoreSelectors.setLoadingAutoLogin();

  const showBiometrics = useBiometricsStoreSelectors.show();
  const disableBiometrics = useBiometricsStoreSelectors.disable();

  const biometricsEnabled = useBiometricsStoreSelectors.enabled();

  const translate = useIntlStoreSelectors.translate();

  const { authentication: { exchangeToken } } = useApi();

  const errorModal = useErrorModal();

  return useCallback(async () => {

    if (!user) {

      const deviceToken = await getToken();

      if (deviceToken && biometricsEnabled) {
        await showBiometrics({});
        setLoadingAutoLogin(true);
        try {
          setUser(await exchangeToken({ token: deviceToken }));
        } catch (e) {
          await disableBiometrics();
          errorModal({
            title: translate(messages.auth.login.autoLoginError.title),
            content: translate(messages.auth.login.autoLoginError.content),
          });
        }
        setLoadingAutoLogin(false);
      }
    }
  }, [showBiometrics, disableBiometrics, biometricsEnabled, exchangeToken, setLoadingAutoLogin, user]);
};
