import React from 'react';
import { App } from 'antd';
import type { useAppProps } from 'antd/es/app/context';

export type InjectedAntAppProps = useAppProps;

type OwnProps<P extends InjectedAntAppProps> = Omit<P, keyof InjectedAntAppProps>;

export function withAntApp<P extends useAppProps>(ComposedComponent: React.ComponentType<P>): React.ComponentType<OwnProps<P>> {
  return (props: P & InjectedAntAppProps) => {
    const app = App.useApp();
    return <ComposedComponent {...props} {...app}/>;
  };
}
