import { useOrderWizardParametersSelectors } from 'modules/orders/containers/OrderWizard/providers';
import { useLogger } from 'providers';
import { WriteableOrderProperties } from 'interfaces/api';
import { cloneDeep, pick } from 'lodash';
import { globalSettingKeys, initialOrder, useApplyOrderDefaultValues, useOrdersStore } from './';

export const useResetOrders = () => {

  const applyOrderDefaultValues = useApplyOrderDefaultValues();
  const resetParameters = useOrderWizardParametersSelectors.reset();

  const logger = useLogger('OrdersProvider.useResetOrders');

  return (newOrders?: WriteableOrderProperties[]) => {

    newOrders = cloneDeep(newOrders || [initialOrder]);
    logger.debug('Resetting orders to ' + (newOrders ? 'new orders' : 'initialOrder'), newOrders || [initialOrder]);

    resetParameters();

    useOrdersStore.setState({
      selectedOrderIndex: undefined,
      globalSettings: pick(newOrders[0], globalSettingKeys),
      logs: [],
      initialProfileName: undefined,
      latestSelectedFormId: newOrders?.[0]?.requirements?.[0]?.formId,
      orders: newOrders.map(o => ({
        ...applyOrderDefaultValues(o),
        scheduled_at: o.scheduled_at,
        apid: o.apid,
      })),
    });
  };

};
