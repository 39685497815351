import { useCallback } from 'react';
import { ComposedOrderRulesProperties, WriteableOrderProperties } from 'interfaces/api';
import { isNull, omitBy } from 'lodash';
import { useOfficeDoctorSelectors, useOrderRulesContext, useOrdersListLayoutBindingsContext, useOrdersSelectors, useReloadWizardSettings } from 'modules/orders/providers';
import { useOnWizardDone } from 'modules/orders/utils';
import { useNavigate } from 'react-router-dom';

export type SaveOrExecuteOptions = {
  execute?: boolean;
  prePrint?: boolean;
  scheduled_at?: string;
  sample_in_lab?: boolean;
  sampleDate?: string;
  sampleTime?: string;
};

export const useSaveOrExecuteOrders = () => {

  const navigate = useNavigate();

  const orders = useOrdersSelectors.orders();
  const logs = useOrdersSelectors.logs();

  const globalSettings = useOrdersSelectors.globalSettings();

  const reloadWizardSettings = useReloadWizardSettings();
  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const { setPending: setPendingRules } = useOrderRulesContext();

  const onWizardDone = useOnWizardDone();
  const setListContext = useOrdersListLayoutBindingsContext(s => s.setListContext);

  const onSaveDone = useCallback((response: ComposedOrderRulesProperties, resolve: any) => {
    const completed = () => {
      onWizardDone(response.order, resolve);
      reloadWizardSettings(officeDoctor.aid);
      navigate({ pathname: '/oa/' + response.order.id });
    };
    if (setListContext) {
      setListContext?.({ filters: { query: '' } }, false, completed);
    } else {
      completed();
    }
  }, [onWizardDone, history, setListContext, reloadWizardSettings, officeDoctor]);

  return useCallback((options: SaveOrExecuteOptions = {}, writeableOrders?: WriteableOrderProperties[]) => {
    return new Promise((resolve, reject) => {
      const { execute, prePrint, sample_in_lab } = options;
      const scheduled_at = options.scheduled_at || globalSettings.scheduled_at;
      const ordersData = (writeableOrders || orders).map((order) => {
        return {
          ...omitBy({ ...order, scheduled_at, sample_in_lab }, isNull),
          patient: {
            ...order.patient,
            sampleDate: options.sampleDate || order.patient?.sampleDate,
            sampleTime: options.sampleTime || order.patient?.sampleTime,
          },
        };
      });
      setPendingRules({
        reject,
        prePrint,
        execute,
        logs,
        isUpsert: true,
        resolve: result => onSaveDone(result, resolve),
        aid: officeDoctor?.aid,
        orders: ordersData,
      });
    });
  }, [setPendingRules, onSaveDone, officeDoctor, logs, orders]);

};
