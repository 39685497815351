import React from 'react';
import { Container, Control, List } from 'components';
import { EmptyBasket } from 'modules/orders/containers/OrderWizard/components';
import { useLabstoreSelectors, useRemoveStoreProduct } from 'modules/store/store/useLabstoreStore';
import { filter } from 'lodash';
import { useProductImageSrc } from 'modules/store/utils';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { useStoreOrdersListLayoutBindingsContext } from 'modules/store/containers/StoreOrdersListLayout/useListLayoutBindingsContext';

export const StoreBasket = () => {

  const basket = useLabstoreSelectors.basket();
  const getProductImage = useProductImageSrc();

  const { storeOrders: { sendCurrentUserOrder } } = useApi();

  const close = useLabstoreSelectors.close();

  const removeStoreProduct = useRemoveStoreProduct();

  const reloadList = useStoreOrdersListLayoutBindingsContext?.(s => s.reloadList);

  return (
    <Container grow shrink className={'store-basket-container'}>
      {basket.length === 0 && <EmptyBasket/>}
      {basket.length > 0 && (
        <Container grow shrink>
          <Container grow shrink>
            <Container shrink scrollY style={{ height: 'auto' }}>

              <List
                items={basket.map(item => ({
                  id: item.productId,
                  title: item.product.name,
                  badge: item.amount,
                  images: filter([getProductImage(item.product, true)]),
                  subtitle: item.product.manufacturer?.name,
                  meta: item,
                }))}
                onItemClick={listItem => removeStoreProduct(listItem.meta.product)}
              />
            </Container>

          </Container>

          <ul className={'bottom-buttons'}>
            <li>
              <Control
                icon={faPaperPlane}
                label={messages.store.submitOrder}
                button={{
                  block: true,
                  type: 'primary',
                }}
                notifications={{
                  success: messages.store.submitteOrderSuccess,
                  error: false,
                }}
                confirm={{
                  title: messages.store.submitConfirm.title,
                  content: messages.store.submitConfirm.content,
                }}
                onClick={async () => {
                  await sendCurrentUserOrder();
                  close();
                  reloadList?.();
                }}
              />
            </li>
          </ul>

        </Container>
      )}
    </Container>
  );
};
