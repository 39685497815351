import React, { useCallback } from 'react';
import { Container, SideBarItem } from 'components';
import styles from './SideBar.module.less';
import cx from 'classnames';
import { Breakpoint } from 'interfaces';
import { Toggle } from 'hooks';
import { isInstanceOfSubRoute, SubRoute } from 'containers';

export type SideBarProps = {
  items?: (SubRoute | React.ReactNode)[];
  topContent?: React.ReactNode;
  children?: React.ReactNode;
  toggle?: Toggle;
  className?: any;
  inverted?: boolean;
  breakpoint?: Breakpoint;
  isRight?: boolean;
};

export const SideBar: React.FC<SideBarProps> = (props) => {

  const { items, topContent, children, className, inverted, breakpoint, isRight } = props;

  const [toggled, toggle] = props.toggle || [undefined, undefined];

  const containerClasses = cx(styles.container, 'sidebar', className, {
    [styles.isToggled]: toggled,
    [styles.isRight]: isRight,
    [styles.inverted]: inverted,
  }, breakpoint && styles[breakpoint]);

  const onClick = useCallback(() => toggled && toggle(), [toggled, toggle]);

  return (
    <Container className={containerClasses}>

      {topContent}

      {items?.length > 0 && (
        <Container grow reset>
          <Container scrollY>
            <ul className={styles.nav}>
              {items.map((item, index) => {
                if (isInstanceOfSubRoute(item) && !item.navHidden) {
                  return (
                    <SideBarItem
                      key={index}
                      onClick={onClick}
                      {...item}
                    />
                  );
                }

                return React.isValidElement(item) ? item : null;

              })}
            </ul>
          </Container>
        </Container>
      )}

      {children}

    </Container>
  );
};
