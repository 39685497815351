import React, { useCallback, useMemo } from 'react';
import { ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback } from 'containers';
import messages from 'messages';
import { ReportListContext } from 'modules/reports/index';
import { FilterProfileProperties, GeneralReportFilterType, ReportCharacteristic, ReportStatus } from 'interfaces/api';
import { Filter, Filters } from 'components';
import { HexColor } from 'interfaces';
import { ReportStatusExtendedFilter } from 'modules/reports/filters';
import { filter } from 'lodash';

export const ReportQuickStatusFilter: any = {
  preliminary: { status: ReportStatus.Preliminary, characteristic: undefined, pathological: undefined, reportType: undefined },
  partial: { status: ReportStatus.Partial, characteristic: undefined, pathological: undefined, reportType: GeneralReportFilterType.Chemical },
  finalNonPatho: { status: ReportStatus.Final, characteristic: ReportCharacteristic.NotPathological, pathological: false, reportType: GeneralReportFilterType.Chemical },
  finalPatho: { status: ReportStatus.Final, characteristic: undefined, pathological: true, reportType: GeneralReportFilterType.Chemical },
  finalBak: { status: ReportStatus.Final, characteristic: undefined, pathological: undefined, reportType: GeneralReportFilterType.Microbiological },
};

const filterLabels = messages.reports.filters;

const Component = (props: ListLayoutArgs<ReportListContext>) => {

  const { context, bindings: { setContext } } = props;

  const filters: Filter[] = useMemo(() => filter([
    ReportStatusExtendedFilter,
    context.laboratoryGroupFilters?.length > 0 && {
      label: filterLabels.laboratoryGroup,
      filter: laboratoryGroup => ({ laboratoryGroup }),
      items: context.laboratoryGroupFilters.map(f => ({ value: f.group, label: f.name || f.group, flag: (f.color ? new HexColor(f.color) : null) })),
    } as Filter,
  ]), [context.laboratoryGroupFilters]);

  const onFilter = useCallback((value: FilterProfileProperties) => {
    setContext({ filters: { ...context.filters, ...value }, filterProfile: undefined });
  }, [setContext, context]);

  return (
    <Filters
      filters={filters}
      onFilter={onFilter}
      filterValues={context.filters}
    />
  );
};

export const FiltersSection: ListLayoutSideBarHiddenSectionCallback<ReportListContext> = ({ context, bindings: { setContext } }) => ({
  component: Component,
});
