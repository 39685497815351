import { useMemo } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';

export const useMandatoryDialysis = () => {

  const { getErrorsByName } = useOrderRulesContext();
  const hasMissingDialysisError = useMemo(() => !!getErrorsByName('OrderRulesDialysisMandatoryError')?.length, [getErrorsByName]);

  return {
    hasMissingDialysisError,
  };

};
