import React, { useCallback } from 'react';
import { Control } from 'components';
import { faUnlock } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { ReportControlContext } from 'modules/reports/index';

const labels = messages.reports.controls.unlockReport;

export const Unlock: React.FC<ReportControlContext> = (props) => {

  const { reports: { unlockReport } } = useApi();
  const { data: { bid }, bindings: { updateItem, setListView } } = props;

  const onClick = useCallback(async () => {
    await unlockReport({ bid });
    updateItem(bid);
    setListView();
  }, [bid, updateItem, setListView, unlockReport]);

  return (
    <Control
      icon={faUnlock}
      label={labels.label}
      confirm={labels.confirm}
      notifications={labels.notifications}
      onClick={onClick}
    />
  );

};
