import React from 'react';
import { LaboratoryGroup } from 'interfaces/api';
import { faEnvelope, faFax, faGlobe, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { Card, Icon } from 'components';

interface Props {
  laboratoryGroup: LaboratoryGroup;
}

export default React.memo((props: Props) => {
  const { name, email, telefon, fax, website, adresse, stadt, plz } = props.laboratoryGroup;

  return (
    <Card className={'info-popover'} title={name}>
      {adresse}
      <br/>
      {plz ? plz + ' ' : ''}{stadt}
      <br/>
      {telefon && (
        <span>
          <Icon icon={faPhone}/>
          {telefon}
          <br/>
        </span>
      )}
      {fax && (
        <span>
          <Icon icon={faFax}/>
          {fax}
          <br/>
        </span>
      )}
      {email && (
        <a href={'mailto:' + email}>
          <Icon icon={faEnvelope}/>
          {email}
          <br/>
        </a>
      )}
      {website && (
        <a href={website} target={'_blank'} rel="noreferrer">
          <Icon icon={faGlobe}/>
          {website}
          <br/>
        </a>
      )}
    </Card>
  );
});
