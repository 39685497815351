import React, { useMemo } from 'react';
import { default as AntDrawer, DrawerProps as AntDrawerProps } from 'antd/es/drawer';
import './Drawer.less';
import { Message } from 'interfaces';
import { useTranslate } from 'providers';

type OverwriteDrawerProps = {
  title?: Message | React.ReactNode;
  className?: string;
};

export type DrawerProps = Omit<AntDrawerProps, keyof OverwriteDrawerProps> & OverwriteDrawerProps;

export const Drawer: React.FC<DrawerProps> = (props) => {

  const { title, className, ...cardProps } = props;

  const translate = useTranslate();

  const translatedTitle = useMemo(() => translate(title), [title]);

  return (
    <AntDrawer
      {...cardProps}
      rootClassName={className}
      title={translatedTitle}
    />
  );
};
