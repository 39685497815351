import React, { useCallback } from 'react';
import { ListLayoutArgs } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { useApi } from 'providers';
import { Control } from 'components';
import { faTruck } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';

const labels = messages.orders.transportation.execute;

export const ExecuteTransportation = (props: ListLayoutArgs<OrdersListContext>) => {

  const { context: { selected } } = props;
  const { transportationList: { createTransportation } } = useApi();

  const onClick = useCallback(async () => {
    const transportationHistoryId = (await createTransportation({ order_ids: selected.map(s => s.id as number) })).tid;
    props.bindings.setContext({ transportationHistoryId, transportationHistoryKey: props.context.transportationHistoryKey + 1, selected: [] });
  }, [selected]);

  return (
    <Control
      icon={faTruck}
      label={labels.label}
      button={{
        type: 'primary',
        disabled: selected.length === 0,
      }}
      onClick={onClick}
      confirm={labels.confirm}
      notifications={labels.notifications}
    />
  );

};
