import React, { useCallback } from 'react';
import { ListLayoutArgs } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import messages from 'messages';
import { ModalControl } from 'components';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { Embed } from 'containers/Embed';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useApi } from 'providers';

type Props = ListLayoutArgs<OrdersListContext>;

export const ExportTransportation = React.memo((props: Props) => {

  const { context: { transportationHistoryId, data } } = props;

  const lid = useAuthStoreSelectors.lid();
  const { transportationList: { exportTransportation } } = useApi();

  const embedded = useCallback(() => {
    return (<Embed file={exportTransportation({ id: transportationHistoryId })}/>);
  }, [transportationHistoryId, lid]);

  return (
    <ModalControl
      icon={faFilePdf}
      label={messages.orders.transportation.export}
      button={{
        disabled: !data || data.length === 0,
      }}
      modal={{
        children: embedded,
        footer: null,
        fullScreen: true,
      }}
    />
  );

});
