import { MetricsInterval } from 'interfaces/api';

export default {
  info: {
    header: 'Server Info',
    env: 'Environment',
    cache: 'Cache',
    metrics: 'Metrics',
    intervals: {
      [MetricsInterval.Live]: 'Live',
      [MetricsInterval.Hour]: '1 Hour',
      [MetricsInterval.Day]: '24 Hours',
      [MetricsInterval.Week]: '7 Days',
      [MetricsInterval.Month]: '30 Days',
    },
  },
  logs: {
    header: 'Logs',
  },
  jobs: {
    header: 'Jobs',
    run: {
      header: 'Run Jobs',
      system: {
        title: 'Run Backend Jobs',
        clearCache: {
          title: 'Clear System Cache',
        },
        resetMetrics: {
          title: 'Reset Server Metrics',
        },
        clearQueue: {
          title: 'Clear Notification Queue',
        },
      },
      elastic: {
        title: 'ElasticSearch',
        requirements: 'Import Requirements',
        patients: 'Import Patients',
        doctors: 'Import Doctors',
        doctorTopRequirements: 'Top Doctors Requirements',
      },
      runNow: 'Run now',
      notifications: {
        success: 'Job completed',
      },
    },
    dashboard: {
      header: 'Dashboard',
    },
  },

};
