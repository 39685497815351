import { useEffect, useMemo } from 'react';
import { OrderRuleActionType, OrderRulesShowTextActionOptions } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';
import { App } from 'antd';

export const useShowTextActions = () => {

  const { message } = App.useApp();
  const { getActionsByType } = useOrderRulesContext();

  // get show actions
  const showText: OrderRulesShowTextActionOptions[] = useMemo(
    () => getActionsByType(OrderRuleActionType.ShowText) || [],
    [getActionsByType],
  );

  useEffect(() => {
    showText.forEach(options => message.open({ type: options.type || 'info', content: options.text }));
  }, [showText]);

  return {
    showText,
  };

};
