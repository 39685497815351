import React, { useEffect, useMemo, useState } from 'react';
import { KbvCovidFormProperties, KbvCovidLocation, KbvCovidLocationFacility, OrderPatientMetaProperties, PatQrDataVersion, WriteableOrderProperties } from 'interfaces/api';
import messages from 'messages';
import { ScanPreview, Translate, useApi } from 'providers';
import { Container } from 'components';
import styles from './styles.module.less';
import { Color } from 'interfaces';
import { useOfficeDoctorSelectors } from 'modules/orders/providers';

export type PatientsScannerProps = {
  onPatientScanned: (patientQrData: WriteableOrderProperties) => void;
};

export const PatientScanner = (props: PatientsScannerProps) => {

  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const { onPatientScanned } = props;
  const { requirements: { readRequirement } } = useApi();

  const [patientQrData, setPatientQrData] = useState<WriteableOrderProperties>(undefined);

  const onQrScanned = async (result: string) => {
    const tags = result?.split(/\t|\\t|#t/);
    let houseNumber = '';
    let covid = undefined as KbvCovidFormProperties;
    let covidCostUnit = undefined as string;
    let covidInsurance = undefined as string;
    let idcardNumber = undefined as string;
    let oeCertificateId = undefined as string;
    let oeRequirementId = undefined as string;

    if (tags.length > 10) {
      const codeVersion = parseInt(tags[11]);
      if (codeVersion === PatQrDataVersion.CovidV1) {
        covid = {
          testNumber: parseInt(tags[12]),
          testReason: parseInt(tags[13]),
          riskCharacteristicsLocation: tags[14] as KbvCovidLocation,
          riskCharacteristicsLocationType: tags[15] as KbvCovidLocationFacility,
          agreed: (parseInt(tags[16]) !== 0),
          legalOrderBasis: parseInt(tags[17]),
          legalReason: parseInt(tags[18]),
          kvSpecial: tags[19],
          healthServiceZipCode: tags[20],
          healthService: tags[21],
        };

        covidCostUnit = tags[22];
        covidInsurance = tags[23];
        idcardNumber = tags[25];
        oeCertificateId = tags[26];
        oeRequirementId = tags[27];
      } else if (codeVersion === PatQrDataVersion.CovidHnrV2) {
        houseNumber = tags[12];

        covid = {
          testNumber: parseInt(tags[13]),
          testReason: parseInt(tags[14]),
          riskCharacteristicsLocation: tags[15] as KbvCovidLocation,
          riskCharacteristicsLocationType: tags[16] as KbvCovidLocationFacility,
          agreed: (parseInt(tags[17]) !== 0),
          legalOrderBasis: parseInt(tags[18]),
          legalReason: parseInt(tags[19]),
          kvSpecial: tags[20],
          healthServiceZipCode: tags[21],
          healthService: tags[22],
        };

        covidCostUnit = tags[23];
        covidInsurance = tags[24];
        idcardNumber = tags[26];
        oeCertificateId = tags[27];
        oeRequirementId = tags[28];
      }
    }

    const patientQrData = {} as WriteableOrderProperties;

    const patient = {
      houseNumber,
      firstName: tags[0],
      lastName: tags[1],
      email: tags[2],
      birthday: tags[3],
      phone: tags[4],
      address: tags[5],
      zip: tags[6],
      city: tags[7],
      gender: parseInt(tags[8]),
      insuranceNumber: tags[9],
    } as OrderPatientMetaProperties;

    if (covidCostUnit === '0') {
      patient.invoiceTo = 'Patient';
    }

    if (covidInsurance) {
      if (covidInsurance === '0') {
        patient.costUnit = 'Privat';
      } else if (covidInsurance === '1') {
        patient.costUnit = 'Kasse';
      }
    }

    if (idcardNumber) {
      patient.idcardNumber = idcardNumber;
      patientQrData.freeText = idcardNumber;
    }

    if (oeCertificateId && oeRequirementId) {
      const requirement = await readRequirement({ aid: officeDoctor.aid, orderFormId: parseInt(oeCertificateId), id: parseInt(oeRequirementId) });
      patientQrData.requirements = [requirement];
    }

    patientQrData.patient = patient;
    patientQrData.covid = covid;
    setPatientQrData(patientQrData);
  };

  useEffect(() => {
    if (patientQrData) {
      onPatientScanned && onPatientScanned(patientQrData);
    }
  }, [patientQrData]);

  const scanner = useMemo(() => <ScanPreview className={styles.preview} width={300} height={226} onScanned={onQrScanned} loaderColor={Color.White}/>, []);

  return (
    <Container grow shrink className={styles.previewContainer}>
      <>
        <Container grow shrink>
          <Container grow shrink className={styles.helga}/>
          <h1><Translate message={messages.orders.patients.scan}/></h1>
        </Container>
        {scanner}
      </>
    </Container>
  );
};
