import React from 'react';
import './Footer.less';
import cx from 'classnames';
import { useAuthUser } from 'modules/auth/providers';
import { useLinks } from 'containers/App/useLinks';
import { Copyright } from 'containers/App/Copyright/Copyright';

export const Footer: React.FC = () => {

  const user = useAuthUser();
  const additionalLinks = useLinks();

  return (
    <footer hidden={user ? additionalLinks.length < 1 : false} className={cx('footer', { 'footer-inverted': !user })}>
      <ul>
        {!user ? <Copyright className={'footer-copyright'}/> : ''}
        {additionalLinks}
      </ul>
    </footer>
  );

};
