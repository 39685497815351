/**
 * ATTENTION!!!
 *
 * DO NOT MODIFY THIS FILE BECAUSE IT WAS GENERATED AUTOMATICALLY,
 * Instead, run 'yarn api' to regenerate this file.
 */

import makeError from 'make-error';

export const InputError = makeError('InputError');
export const InvalidCredentialsError = makeError('InvalidCredentialsError');
export const TocNotAcceptedError = makeError('TocNotAcceptedError');
export const NotAuthenticatedError = makeError('NotAuthenticatedError');
export const ForbiddenError = makeError('ForbiddenError');
export const NotFoundError = makeError('NotFoundError');
export const TokenBlacklistedError = makeError('TokenBlacklistedError');
export const TokenExpiredError = makeError('TokenExpiredError');
export const TokenInvalidError = makeError('TokenInvalidError');
export const UnexpectedUserStatusError = makeError('UnexpectedUserStatusError');
export const UserEmailMissingError = makeError('UserEmailMissingError');
export const TenantError = makeError('TenantError');
export const ReportInProcessError = makeError('ReportInProcessError');
export const ReportLockedError = makeError('ReportLockedError');
export const ReportInProcessAllowNotificationError = makeError('ReportInProcessAllowNotificationError');
export const ReportInProcessAlreadyRegisteredError = makeError('ReportInProcessAlreadyRegisteredError');
export const ReportGuidTooManyDevicesRegistered = makeError('ReportGuidTooManyDevicesRegistered');
export const ReportGuidTooManyGuidsRegistered = makeError('ReportGuidTooManyGuidsRegistered');
export const ReportGuidWrongKeyError = makeError('ReportGuidWrongKeyError');
export const TooManyRequestsError = makeError('TooManyRequestsError');
export const ConflictError = makeError('ConflictError');
export const UnexpectedOrderStatusError = makeError('UnexpectedOrderStatusError');
export const DuplicateEntryError = makeError('DuplicateEntryError');
