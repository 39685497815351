import React, { useState } from 'react';
import { Control } from 'components';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarActive } from '@fortawesome/pro-solid-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { ReportControlContext } from 'modules/reports/index';

export const Pin = (props: ReportControlContext) => {

  const { data: { bid, is_pinned } } = props;
  const { reports: { unpinReport, pinReport } } = useApi();

  const [pinned, setPinned] = useState(is_pinned);

  return (
    <Control
      icon={pinned ? faStarActive : faStar}
      tooltip
      label={messages.reports.controls.togglePin.label[pinned ? 'unpin' : 'pin']}
      onClick={async () => {
        const request = pinned ? unpinReport : pinReport;
        await request({ bid });
        props.bindings.updateItem(bid, { ...props.data, is_pinned: !pinned });
        setPinned(!pinned);
      }}
    />
  );

};
