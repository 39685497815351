import { flatten, map, uniq } from 'lodash';
import { ComposedOrderProperties } from 'interfaces/api';
import { useCallback } from 'react';
import { useOrdersSelectors } from 'modules/orders/providers/OrdersProvider/useOrdersStore';
import { useSetOfficeDoctor } from 'modules/orders/providers/OfficeDoctorProvider';
import { useResetOrders } from 'modules/orders/providers/OrdersProvider/useResetOrders';
import { useApi } from 'providers';

export const useStartRealReRequest = () => {

  const setOfficeDoctor = useSetOfficeDoctor();
  const resetOrders = useResetOrders();

  const setImmutableRequirementIds = useOrdersSelectors.setImmutableRequirementIds();

  const setWizardVisible = useOrdersSelectors.setWizardVisible();

  const { realReRequest: { startRealReRequest } } = useApi();

  return useCallback(async ({ doctor, orders }: ComposedOrderProperties, orderIds: number[]) => {

    const { aid } = doctor;
    const { lanr, bsnr } = orders[0];

    await setOfficeDoctor({ aid, lanr, bsnr });

    const groupedOrders = await startRealReRequest({ orderIds });
    resetOrders(groupedOrders);

    setImmutableRequirementIds(uniq(map(flatten(map(groupedOrders, no => no.requirements)), r => r.id)));

    setWizardVisible(true);

  }, [setOfficeDoctor, resetOrders, setImmutableRequirementIds, setWizardVisible, startRealReRequest]);

};
