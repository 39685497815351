import React from 'react';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { OrderControlContext } from 'modules/orders/index';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';
import { Order } from 'interfaces/api';

const labels = messages.orders.controls.cancel;

export const CancelOrder = (props: OrderControlContext) => {

  const { orders: { cancelOrders } } = useApi();
  const { data: { hasMissingAggregation }, bindings: { reloadList, setListView } } = props;

  return (
    <ChooseOrdersControl
      orders={props.data.orders}
      disabledCheck={o => !o.isCancelable}
      icon={faTimesCircle}
      button={{
        danger: true,
        block: true,
      }}
      disabled={hasMissingAggregation}
      label={labels.label}
      confirm={labels.confirm}
      notifications={labels.notifications}
      onDone={async (orders: Order[]) => {
        await cancelOrders({ orderIds: orders.map(o => o.id) });
        setListView();
        reloadList();
      }}
    />
  );
};
