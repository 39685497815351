import React from 'react';
import { BadgeIconProps, IconProps, ListItem } from 'components';
import { MessageThread, MessageThreadType, User } from 'interfaces/api';
import { FormatDate, Translate } from 'providers';
import { MessageListContext } from 'modules/messages/index';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import messages from 'modules/messages/messages';
import { Color, Message } from 'interfaces';
import { faEnvelope, faFileMedicalAlt, faNewspaper, faShoppingCart, faTag, faTruck } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { join } from 'lodash';

export const convertToListItem = (thread: MessageThread, context: MessageListContext): ListItem => {
  const { uid } = context;
  const msg = thread.lastMessage;
  const lastMessageIsUnreadByCurrentUser = msg && msg.sender_id !== uid && !msg.readBy.includes(uid);

  const icon = msg && msg.sender_avatar ? null : faUser;

  return {
    icon,
    images: msg?.sender_avatar ? [`data:image/png;base64,${msg.sender_avatar}`] : null,
    icons: thread.starred ? [{ icon: faStar, color: Color.Blue }] : undefined,
    id: thread.id,
    title: <Translate message={getChatTitle(thread, context.uid)}/>,
    subtitle: msg && <FormatDate date={msg.created_at}/>,
    highlighted: lastMessageIsUnreadByCurrentUser,
    highlightedDot: lastMessageIsUnreadByCurrentUser,
    groupByValue: thread.updated_at || msg && msg.created_at,
    badgeIcon: getBadgeIcon(thread.type),
    fields: [
      {
        label: thread.subject,
        value: msg && msg.text,
        wide: true,
      },
    ],
  };
};

export const getChatPartners = (thread: MessageThread, myUid: number): User[] => {
  return thread.users.filter((usr) => {
    return usr.xuser_id !== myUid;
  });
};

export const getChatTitle = (thread: MessageThread, myUid: number): string | Message => {
  if ([MessageThreadType.News].includes(thread.type)) {
    // @ts-expect-error todo
    return messages.specialMessageTitle[thread.type];
  }
  return join(getChatPartners(thread, myUid).map(user => user.displayName), ', ');
};

const getBadgeIcon = (type: MessageThreadType): BadgeIconProps => {
  return MessageThreadAggregations[type] || { color: Color.LightGray, icon: faEnvelope };
};

export type MessageThreadTypeConfig = {
  color: Color;
  icon: IconProps;
};

export const MessageThreadAggregations: Record<MessageThreadType, MessageThreadTypeConfig> = {
  [MessageThreadType.General]: {
    color: Color.Blue,
    icon: faEnvelope,
  },
  [MessageThreadType.Report]: {
    color: Color.Turquoise,
    icon: faFileMedicalAlt,
  },
  [MessageThreadType.Order]: {
    color: Color.SkyBlue,
    icon: faTag,
  },
  [MessageThreadType.Store]: {
    color: Color.Yellow,
    icon: faShoppingCart,
  },
  [MessageThreadType.CourierService]: {
    color: Color.Salmon,
    icon: faTruck,
  },
  [MessageThreadType.News]: {
    color: Color.Beige,
    icon: faNewspaper,
  },
};
