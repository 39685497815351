import React, { useCallback, useMemo } from 'react';
import messages from 'messages';
import { Control, TooltipProps } from 'components';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { useSetOrders } from 'modules/orders/providers';

const labels = messages.orders.controls.clearBasket;

export const ClearBasket = () => {

  const { setAllOrdersProperties } = useSetOrders();

  const clearBasket = useCallback(() => {
    setAllOrdersProperties({ requirements: [] });
  }, [setAllOrdersProperties]);

  const tooltip: Partial<TooltipProps> = useMemo(() => ({ placement: 'left' }), []);

  return (
    <Control
      icon={faTrashAlt}
      label={labels.label}
      onClick={clearBasket}
      tooltip={tooltip}
    />
  );
};
