import React, { useMemo } from 'react';
import AntBadge, { BadgeProps as BaseBadgeProps } from 'antd/es/badge';
import './Badge.less';
import { Color } from 'interfaces';
import { getCssColor } from 'utils/dom';

export type BadgeProps = Omit<BaseBadgeProps, 'color' | 'className'> & {
  color?: Color;
  className?: any;
};

export const Badge: React.FC<BadgeProps> = (props) => {

  const { color, className, dot, ...badgeProps } = props;

  const hasNumber = props.count || (props.count === 0 && props.showZero);

  const cssColor = useMemo(() => getCssColor(color || Color.Gray), [color]);
  const style = useMemo(() => ({
    background: (color && hasNumber) || dot ? cssColor : 'transparent',
    display: !hasNumber && !dot ? 'none' : undefined,
  }), [hasNumber, cssColor]);

  return (
    <AntBadge
      {...badgeProps}
      className={className}
      color={!hasNumber ? cssColor : undefined}
      style={style}
    />
  );

};
