import React, { useMemo } from 'react';
import styles from './UserDropdown.module.less';
import cx from 'classnames';
import { Profile } from 'containers/App/TopBar/Profile/Profile';
import LanguageSwitch from 'containers/App/LanguageSwitch/LanguageSwitch';
import { Translate } from 'providers';
import { Avatar, Icon, Popover } from 'components';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useLogout } from 'modules/auth/hooks';
import messages from 'messages';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { UserTypeIcon } from 'utils/helpers';
import { find } from 'lodash';
import { useEnv } from 'providers/EnvProvider';

export const UserDropdown = () => {

  const user = useAuthStoreSelectors.user();
  const avatar = useAuthStoreSelectors.avatar();
  const lid = useAuthStoreSelectors.lid();
  const setLid = useAuthStoreSelectors.setLid();

  const logout = useLogout();
  const isWeb = useEnv.isWeb();
  const HIDE_PROFILE_LABORATORY = useEnv.HIDE_PROFILE_LABORATORY();

  const currentLab = useMemo(() => find(user?.labors, { lid }), [lid]);

  const content = useMemo(() => (
    <ul className={styles.userDropdown}>
      <li className={cx(styles.profile, { 'border-bottom': user.labors.length > 1 })}>
        <span>{user.kennung}</span>
        <Profile/>
      </li>

      {user.labors.length > 1 && user.labors.map(lab => (
        <li key={lab.lid} className={cx('no-border', { [styles.activeLabor]: lab.lid === lid })}>
          <a onClick={() => setLid(lab.lid)}>{lab.displayName}</a>
        </li>
      ))}

      <li className={cx({ 'border-top': user.labors.length > 1 })}>
        <LanguageSwitch modal={{ title: messages.general.topBar.changeLanguage }}/>
      </li>

      <li className={cx({ 'border-top': !isWeb && user.labors.length > 1 })}>
        <a onClick={logout}>
          <Translate message={messages.general.topBar.logout}/>
        </a>
      </li>
    </ul>
  ), [user]);

  const control = useMemo(() => (
    <a className={cx(styles.user, 'user-dropdown-link')}>
      <span className={styles.userData}>
        <span className={`${styles.userName} is-hidden-mobile user-dropdown-name`}>
          {currentLab && user.displayName === currentLab.displayName ? user.kennung : user.displayName}
        </span>
        {!HIDE_PROFILE_LABORATORY && (
          <span className={`${styles.userLabor} is-hidden-mobile user-dropdown-labor`}>
            {currentLab?.displayName}
          </span>
        )}
      </span>
      <Avatar file={avatar} size={'3rem'} icon={UserTypeIcon[user.type]}/>
      <span className={cx(styles.caret, 'user-dropdown-caret')}>
        <Icon icon={faChevronDown}/>
      </span>
    </a>
  ), [user, avatar]);

  return (
    <Popover
      content={content}
      placement={'bottomRight'}
      zIndex={999}
      trigger={'click'}
    >
      {control}
    </Popover>
  );

};
