import login from './containers/Login/messages';
import forgot from './containers/ForgotPassword/messages';
import reset from './containers/ResetPassword/messages';

export default {
  login,
  forgot,
  reset,
  verifySms: {
    title: 'Sms Verification',
    submit: 'Submit code',
    description: 'An sms with a verification code has been sent to your stored phone number',
    code: 'Code',
    resend: 'Resend code',
    resent: 'Sms has been resent',
  },
  verifyBirthday: {
    title: 'Birthday Verification',
    description: 'Please enter your birthday to verify your identity',
    submit: 'Submit',
  },
  notPermittedModal: {
    title: 'Not permitted',
    content: 'You don´t have sufficient permissions for this action.',
  },
  enterPassword: {
    title: 'Please enter your password',
    description: 'For security reasons please enter your password to use this functionality.',
    wrongPassword: {
      title: 'Wrong password',
      content: 'The password you have entered is not correct!',
    },
  },
};
