import { useEffect } from 'react';
import { useConfig } from 'providers';
import { UserAccessibilityFontSize } from 'interfaces/api';
import { usePrevious } from 'react-use';

export const useAccessibility = () => {

  const { preferences } = useConfig();

  const fontSizes = {
    [UserAccessibilityFontSize.Small]: '10px',
    [UserAccessibilityFontSize.Normal]: '12px',
    [UserAccessibilityFontSize.Large]: '14px',
  };

  useEffect(() => {
    if (preferences?.fontSize) {
      document.documentElement.style.setProperty('--font-size', fontSizes[preferences?.fontSize]);
    }
  }, [preferences?.fontSize]);

  useEffect(() => {
    if (preferences?.contrast) {
      document.body.classList.add(`contrast-${preferences?.contrast}`);
    }
  }, [preferences?.contrast]);

  const previousContrast = usePrevious(preferences?.contrast);

  useEffect(() => {
    if (previousContrast && previousContrast !== preferences?.contrast) {
      document.body.classList.remove(`contrast-${previousContrast}`);
    }
  }, [previousContrast, preferences?.contrast]);

};
