import React from 'react';
import { StoreProduct } from 'interfaces/api';
import { Container, InputNumber } from 'components';
import { Translate } from 'providers';
import messages from 'messages';
import cx from 'classnames';

type Props = {
  value?: number;
  onChange: (value: number) => void;
  product: StoreProduct;
  className?: string;
};

export const SelectProductAmount: React.FC<Props> = (props) => {

  const { value, onChange, product: { unitAmount, unitType } } = props;

  return (
    <Container horizontal className={cx('store-product-amount-select', props.className)}>
      <InputNumber width={'20px'} value={value} onChange={value => value ? onChange(parseInt(value + '')) : 1}/>
      <span>
        x&nbsp;{unitAmount}&nbsp;
        <Translate message={messages.admin.store.products.fields.unitTypes[unitType]}/>
      </span>
    </Container>
  );
};
