import React, { useCallback, useMemo, useState } from 'react';
import { default as AntSelect, DefaultOptionType, SelectProps as AntSelectProps } from 'antd/es/select';
import './CountrySelect.less';
import cx from 'classnames';
import { Icon } from 'components/Icon';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useAsync } from 'react-use';
import { useCountrySelectPromise } from 'components/Form/CountrySelect/useCountrySelect';
import { sortBy } from 'lodash';
import { Control } from 'components/Control';

type OverwriteProps = {
  preferred?: (string | number)[];
};

export type CountrySelectProps = Omit<AntSelectProps, keyof OverwriteProps | 'options'> & OverwriteProps;

export const CountrySelect: React.FC<CountrySelectProps> = (props) => {

  const [searchValue, setSearchValue] = useState<string>('');

  const { value, onChange, preferred = ['DE', 'AT', 'CH'], ...rest } = props;

  const options = useAsync(async () => {
    const loaded = await useCountrySelectPromise;

    const defaultCountries = ['AT', 'DE', 'CH'];

    return [
      ...['DE', 'AT', 'CH'].map((c): DefaultOptionType => {
        return { value: c, label: loaded[c] };
      }),
      { label: '-', value: '-', disabled: true },
      ...sortBy(Object.keys(loaded).filter(c => !defaultCountries.includes(c)).map((c): DefaultOptionType => {
        return { value: c, label: loaded[c] };
      }), c => c.label),
    ];
  });

  const handleSelect = useCallback((value: string, option: DefaultOptionType) => {
    if (!value) {
      props.onChange(null, null);
    } else {
      let selectedValue = value;
      if (value.length > 5) {
        selectedValue = value.substring(0, 5);
        option.label = selectedValue;
        option.value = selectedValue;
      }
      onChange(selectedValue, option);
      setSearchValue('');
    }
  }, []);

  const filterOption = useCallback((inputValue: string, option: DefaultOptionType) => {
    return option.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option.label.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }, []);

  const clearIcon = useMemo(() => (
    <Control onClick={() => props.onChange(null, null)}>
      <Icon icon={faTimes}/>
    </Control>

  ), []);

  const handleSearch = useCallback((searchValue: string) => {
    if (value) {
      onChange('', null);
    }
    setSearchValue(searchValue);
  }, [value, onChange]);

  return (
    <AntSelect
      allowClear={{ clearIcon }}
      className={cx('country-select', props.className)}
      popupClassName={'country-select-popover'}
      options={options.value}
      onChange={handleSelect}
      onSearch={handleSearch}
      value={value}
      filterOption={filterOption}
      searchValue={searchValue.substring(0, 5)}
      loading={options.loading}
      {...rest}
    />
  );
};
