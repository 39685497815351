import React from 'react';
import { Report } from 'interfaces/api';
import { FormatDate, Translate } from 'providers';
import messages from 'messages';
import { Card } from 'components/Card';

type Props = {
  report: Report;
};

export default React.memo((props: Props) => {

  const {
    status,
    tnr,
    exttnr,
    created_at,
    imported_at,
    registered_at,
    validated_at,
    sampled_at,
    material,
    validatingDoctor,
    diagnosis,
    accountingType,
    caseNumber,
  } = props.report;

  return (
    <Card className={'info-popover'} title={<Translate message={(messages.reports.status as any)[status]}/>}>
      <span>
        <b><Translate message={messages.reports.details.orderNumber}/></b>&nbsp;
        {tnr}
      </span>
      {exttnr && (
        <span>
          <b><Translate message={messages.reports.details.laboratoryNumber}/></b>&nbsp;
          {exttnr}
        </span>
      )}
      {caseNumber && (
        <span>
          <b><Translate message={messages.orders.additionalFields.caseNumber}/></b>&nbsp;
          {caseNumber}
        </span>
      )}
      <span>
        <b><Translate message={messages.reports.sort.importDate}/></b> <FormatDate date={imported_at}/>
      </span>
      <span>
        <b><Translate message={messages.reports.sort.reportDate}/></b> <FormatDate date={created_at}/>
      </span>
      {registered_at && (
        <span>
          <b><Translate message={messages.reports.details.registeredAt}/></b>&nbsp;
          <FormatDate date={registered_at} options={{ showInvalid: true, dateOnly: registered_at?.length === 8 }}/>
        </span>
      )}
      {validated_at && (
        <span>
          <b><Translate message={messages.reports.details.validatedAt}/></b>&nbsp;
          <FormatDate date={validated_at} options={{ showInvalid: true, dateOnly: validated_at?.length === 8 }}/>
        </span>
      )}
      {sampled_at && (
        <span>
          <b><Translate message={messages.reports.details.sampledAt}/></b>&nbsp;
          <FormatDate date={sampled_at} options={{ showInvalid: true, dateOnly: sampled_at?.length === 8 }}/>
        </span>
      )}
      {material && (
        <span>
          <b><Translate message={messages.reports.details.material}/></b>&nbsp;
          {material}
        </span>
      )}
      {accountingType && (
        <span>
          <b><Translate message={messages.reports.details.accountingType}/></b>&nbsp;
          {accountingType}
        </span>
      )}
      <span>
        <b><Translate message={messages.reports.details.validatingDoctor}/></b>&nbsp;
        {validatingDoctor}
      </span>
      {diagnosis && (
        <span>
          <b><Translate message={messages.general.diagnosis}/></b>&nbsp;
          {diagnosis}
        </span>
      )}
    </Card>
  );

});
