import React, { CSSProperties, useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { EChartsType, getInstanceByDom, init } from 'echarts/core';
import { LineChart, PieChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { GridComponent, LegendComponent, MarkAreaComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import type { EChartsOption, SetOptionOpts } from 'echarts';

// Register the required components
echarts.use([
  BarChart,
  LineChart,
  PieChart,
  CanvasRenderer,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  TitleComponent,
  MarkAreaComponent,
]);

type Props = {
  options: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
};

export const Chart: React.FC<Props> = (props) => {

  const { options, settings, style, loading } = props;

  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    let chart: EChartsType | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }

    window.addEventListener('resize', resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, []);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(options, settings);
    }
  }, [options, settings]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      loading ? chart?.showLoading() : chart?.hideLoading();
    }
  }, [loading]);

  return <div ref={chartRef} style={{ width: '100%', height: '100px', ...style }}/>;
};
