import { useGuard } from 'containers';
import { MetaLogMessage, useLogger } from 'providers';
import { Feature, OrderChangeAction } from 'interfaces/api';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useOrdersSelectors } from './useOrdersStore';

export const useAppendLog = () => {

  const guard = useGuard();
  const logger = useLogger('OrdersProvider');

  const globalSettings = useOrdersSelectors.globalSettings();

  const logs = useOrdersSelectors.logs();
  const setLogs = useOrdersSelectors.setLogs();

  const featureEnabled = guard({ feature: Feature.OrderChangeLog }, () => true);

  return useCallback((action: OrderChangeAction, data?: any, info?: string) => {
    logger.debug(action, new MetaLogMessage('data', data));
    if (featureEnabled) {
      setLogs([...(logs || []), { action, data, info, created_at: dayjs().toISOString() }]);
    }
  }, [logger, logs, featureEnabled, globalSettings]);

};
