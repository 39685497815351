import React, { useCallback, useMemo } from 'react';
import { SortGrouping } from 'interfaces';
import { Translate, useConfig, useIntlStoreSelectors } from 'providers';
import { List, ListItem } from 'components/List';
import { Feature, Order, OrderSample, OrderStatus, Sample } from 'interfaces/api';
import { useAuthStoreSelectors } from 'modules/auth/store';
import * as Controls from '../Controls';
import cx from 'classnames';
import messages from 'messages';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Container, Header, Icon } from 'components';
import { useGuard } from 'containers';
import './SampleList.less';
import { filter, find, flatMap, map, reduce } from 'lodash';
import { useEnv } from 'providers/EnvProvider';
import styles from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/styles.module.less';
import { faClipboardMedical, faFileCheck } from '@fortawesome/pro-light-svg-icons';
import { getRequirementId } from 'modules/orders/utils';
import { useRequirementSubtitle } from 'modules/orders/containers/OrderWizard/utils';
import { getRequirementReportFlag } from 'modules/orders/containers/OrderWizard/providers';

type Props = {
  order?: Order;
  className?: any;
  reload?: () => void;
  noBadge?: boolean;
};

export const SampleList = (props: Props) => {

  const { order, reload, noBadge } = props;

  const guard = useGuard();
  const lid = useAuthStoreSelectors.lid();

  const endpoint = useEnv.endpoint();
  const translate = useIntlStoreSelectors.translate();

  const { preferences: { orderBarcodeMaterialFactorShowOriginalCount, ordersDynamicProbeTypeStaticReprint } } = useConfig();
  const getFlag = getRequirementReportFlag();

  const createSubtitle = useRequirementSubtitle();

  const dynamicProbeTypeFeature = guard({ feature: Feature.DynamicProbeType }, () => true);

  const getControls = useCallback((item: ListItem) => {
    const sample = find(order?.samples, { sampleType: item.meta?.sampleType });
    const sampleType = (sample as Sample)?.sampleType;
    const dynamicSampleType = (sample as OrderSample)?.dynamic && dynamicProbeTypeFeature;
    const hasDynamicMaterials = flatMap(order.requirements, r => r.dynamicMaterials)?.length;

    const isExecuted = order?.id && [OrderStatus.Queued, OrderStatus.Executed, OrderStatus.PrePrint].includes(order.status);

    return ([
      (isExecuted && (!dynamicSampleType || hasDynamicMaterials || ordersDynamicProbeTypeStaticReprint)) && (
        <Controls.PrintBarcode key={item.id} aid={order.doctor.aid} orderId={order.id} apid={order.apid} barcodeNumber={order.tnr} probeType={sampleType}/>
      ),
      (isExecuted && (dynamicSampleType && !hasDynamicMaterials && !ordersDynamicProbeTypeStaticReprint)) && (
        <Controls.ExpendExtraDynamicBarcode
          key={`${item.id}_x`}
          aid={order.doctor.aid}
          orderId={order.id}
          apid={order.apid}
          barcodeNumber={order.tnr}
          probeType={sampleType}
          reload={reload}
        />
      ),
    ]);
  }, [order?.id]);

  const getImage = useCallback((orderSample: OrderSample, large?: boolean) => {
    return `${endpoint}/api/orders/samples/${orderSample.sample_id}/image/${large ? 'large' : 'small'}?lid=${lid}`;
  }, [lid]);

  const getSubtitle = useCallback((orderSample: OrderSample) => (
    <span><Translate message={messages.orders.sampleType}/> {orderSample.sampleType}</span>
  ), []);

  const items = useCallback((reprinted: boolean) => {
    const getCount = (orderSample: OrderSample) => orderBarcodeMaterialFactorShowOriginalCount ? orderSample.originalCount : orderSample.count;
    return filter(map(order?.samples, orderSample => !!orderSample.reprinted === reprinted
      ? ({
        id: orderSample.sample_id + ':' + orderSample.sampleType,
        title: orderSample.name,
        subtitle: getSubtitle(orderSample),
        badge: getCount(orderSample) > 0 && !noBadge ? getCount(orderSample) : undefined,
        images: [getImage(orderSample)],
        groupByValue: translate(messages.orders.samples),
        className: cx({ ['sample-list-item-reprinted']: orderSample.reprinted }),
        meta: orderSample,
      })
      : undefined));
  }, [order?.samples]);

  const hasReprintedItems = useMemo(() => {
    return reduce(order?.samples, (acc, s) => acc || !!s.reprinted, false);
  }, [order?.samples]);

  const getPopover = useCallback((item: ListItem) => {

    const orderSample = find(order?.samples, { sample_id: item.meta?.sample_id, sampleType: item.meta?.sampleType }) as OrderSample;

    if (!orderSample) {
      return undefined;
    }

    const requirements = filter(orderSample.requirements.map(key => find(order.requirements, (r) => {
      if (key.includes('-')) {
        return r.entityKey === key.split('-')[0];
      } else {
        return r.entityId === parseInt(key);
      }
    })));

    return {
      placement: 'left' as TooltipPlacement,
      destroyTooltipOnHide: true,
      content: (
        <Container grow shrink className={'sample-list-popover'}>

          <Header
            title={orderSample.name}
            subtitle={getSubtitle(orderSample)}
            images={[getImage(orderSample)]}
          />

          <Container grow shrink scrollY>

            <Container grow shrink>

              {orderSample.comment && (
                <div className={cx(styles.requirementInfo, 'requirement-info')}>
                  <Icon icon={faFileCheck}/>
                  <div dangerouslySetInnerHTML={{ __html: orderSample.comment }}/>
                </div>
              )}

              {orderSample.notice && (
                <div className={cx(styles.requirementInfo, 'requirement-info')}>
                  <Icon icon={faClipboardMedical}/>
                  <div dangerouslySetInnerHTML={{ __html: orderSample.notice }}/>
                </div>
              )}
            </Container>

            <Container className={'sample-list-popover-image'} center>
              <img src={getImage(orderSample, true)}/>
            </Container>

            {requirements.length > 0 && (
              <List
                items={requirements.map(r => ({
                  id: getRequirementId(r),
                  title: r.longName,
                  subtitle: createSubtitle(r),
                  flag: getFlag(r),
                }))}
                groupBy={SortGrouping.STRING}
                className={cx('oa-wizard-list')}
              />
            )}

          </Container>

        </Container>
      ),
    };
  }, [order]);

  if (!order?.samples || order?.samples.length === 0) {
    return null;
  }

  return (
    <>
      <List
        getControls={getControls}
        groupBy={SortGrouping.STRING}
        className={cx('sample-list', props.className)}
        items={items(false)}
        getPopover={getPopover}
      />
      {hasReprintedItems && (
        <List
          groupHeader={() => ({ title: translate(messages.orders.reprintedSamples) })}
          groupBy={SortGrouping.STRING}
          className={cx('sample-list', props.className)}
          items={items(true)}
        />
      )}
    </>
  );

};
