import React from 'react';
import messages from 'messages';
import { startCase } from 'lodash';
import { Button, Container, HelgaImage, HelgaImageType } from 'components';
import { Translate } from 'providers';

export type ErrorBoundaryHelgaProps = {
  error: Error;
};

export const ErrorBoundaryHelga: React.FC<ErrorBoundaryHelgaProps> = ({ error }) => {

  // @ts-expect-error todo
  const message = messages.errors[error.name] || startCase(error.name);

  const title = message?.title || message;
  const description = message?.description;

  return (
    <Container grow center className={'error-boundary'}>
      <HelgaImage type={HelgaImageType.Coffee}/>
      <h1><Translate message={title}/></h1>
      {description && <p><Translate message={description}/></p>}
      <Button type={'primary'} onClick={() => window.location.reload()}>
        <Translate message={messages.general.reloadPage}/>
      </Button>
    </Container>
  );

};
