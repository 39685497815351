import React from 'react';
import { Badge } from '../Badge';
import { Icon, IconProps } from '../Icon';
import { Loader } from '../Loader';
import { Color } from 'interfaces';
import './Avatar.less';
import cx from 'classnames';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { useAsync } from 'react-use';

type BaseAvatarProps = {
  badge?: number;
  className?: any;
  size: string;
  icon?: IconProps;
  children?: any;
};

export type AvatarFile = BaseAvatarProps & {
  file: File;
};

export type AvatarRequest = BaseAvatarProps & {
  request: () => Promise<File>;
};

export type AvatarSource = BaseAvatarProps & {
  source: string;
};

export type AvatarProps = BaseAvatarProps | AvatarFile | AvatarRequest | AvatarSource;

export const Avatar: React.FC<AvatarProps> = (props) => {

  const { badge, children, className, icon, size } = props;

  const { file } = props as AvatarFile;
  const { request } = props as AvatarRequest;
  const { source } = props as AvatarSource;

  const { value, loading, error } = useAsync(async () => {

    if (!file && !request && !source) {
      return undefined;
    }

    if (source) {
      return source;
    }

    if (file) {
      return URL.createObjectURL(file);
    }

    return new Promise<string>(async (resolve, reject) => {

      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          return resolve(reader.result.toString());
        }
        reject();
      };

      reader.onerror = () => {
        reject(reader.error);
        reader.abort();
      };

      try {
        const response = await request();
        if (response.size > 0) {
          reader.readAsDataURL(response);
        } else {
          reject();
        }
      } catch (e) {
        reject(e);
      }

    });

  }, [file, request, source]);

  return (

    <div className={cx('avatar-wrapper', className)} style={{ height: size, width: size }}>
      <div className={'avatar-container'}>
        {loading && <Loader small/>}
        {(error || !value) && <Icon {...(icon || faBan)}/>}
        {value && <img src={value} alt={'Avatar'}/>}
        <>{children}</>
      </div>
      {badge && <Badge count={badge} color={Color.Red} className={'avatar-badge'}/>}
    </div>

  );

};
