import React from 'react';
import { ApiRequest } from 'containers';
import { useApi } from 'providers';
import { PopOvers } from 'components';

type Props = {
  id: number;
  reset: React.ReactNode;
};

export const DoctorAutocomplete = (props: Props) => {

  const { doctors: { readDoctor } } = useApi();

  return (
    <ApiRequest
      errorAsAlert
      request={() => readDoctor({ aid: props.id })}
      children={({ data }) => {
        return <PopOvers.Doctor doctor={data} reset={props.reset}/>;
      }}
    />
  );
};
