import { createStore } from 'zustand';
import { MutableRefObject, useRef } from 'react';
import { ListLayoutArgs, ListLayoutContext, ListLayoutContextSetter, ListLayoutSetRefCallback, ListLayoutUpdater } from 'containers/index';

export interface ListLayoutBindingsStoreProps<C extends ListLayoutContext> {
  listRef?: MutableRefObject<ListLayoutArgs<C>>;
}

export interface ListLayoutBindingsState<C extends ListLayoutContext> extends ListLayoutBindingsStoreProps<C> {
  setListContext: ListLayoutContextSetter<C>;
  getListContext: () => C;
  updateItem: ListLayoutUpdater;
  reloadList: () => void;
  reloadDetail: () => void;
  setRef: ListLayoutSetRefCallback<C>;
}

export type ListLayoutBindingsStore<C> = ReturnType<typeof createListLayoutBindingsStore<C>>;

export const createListLayoutBindingsStore = <C extends ListLayoutContext>(initProps?: Partial<ListLayoutBindingsStoreProps<C>>) => {

  const listRef = useRef<ListLayoutArgs<C>>();

  return createStore<ListLayoutBindingsState<C>>()((set, get) => ({
    listRef,
    ...initProps,
    setListContext: (context: C, reset?: boolean, callback?: () => void) => get().listRef?.current?.bindings?.setContext(context, reset, callback),
    updateItem: (id: number | string, data?: any) => get().listRef?.current?.bindings?.updateItem(id, data),
    reloadList: () => get().listRef?.current?.bindings?.reloadList(),
    reloadDetail: () => get().listRef?.current?.bindings?.reloadDetail(),
    getListContext: () => get().listRef?.current?.context,
    setRef: args => get().listRef.current = args,
  }));
};
