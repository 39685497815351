import React from 'react';
import { ButtonProps as AntButtonProps, default as AntButton } from 'antd/es/button';
import './Button.less';

export type ButtonProps = AntButtonProps;

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <AntButton {...props} className={props.className}/>
  );
};
