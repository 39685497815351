import { Administrator, Doctor, Laboratory, LaboratoryUser, Patient, Product, UserType } from 'interfaces/api';
import React from 'react';
import { withSuspense } from 'hocs';

export type SelectedUser = Patient | Doctor | Laboratory | LaboratoryUser | Administrator;

export type SelectedUserFromUserType<T> =
  T extends UserType.ADM ? Administrator :
    T extends UserType.PAT ? Patient :
      T extends UserType.ARZ ? Doctor :
        T extends UserType.LAB ? Laboratory :
          T extends UserType.LAU ? LaboratoryUser : undefined;

export type UserSelectProps<T extends UserType, P extends boolean> = {
  type: T;
  value?: P extends true ? SelectedUserFromUserType<T>[] : SelectedUserFromUserType<T>;
  onChange?: (selected: P extends true ? SelectedUserFromUserType<T>[] : SelectedUserFromUserType<T>) => void;
  className?: string;
  disabled?: boolean;
  compact?: boolean;
  multi?: P;
  excluded?: number[];
  product?: Product;
  noBorder?: boolean;
  filters?: any;
  standalone?: boolean;
  publicOnly?: boolean;
  checkboxes?: boolean;
};

export const UserSelect = withSuspense(React.lazy(() => import('./UserSelect')));
