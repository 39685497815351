import React from 'react';
import { OrderWizardRequirement } from 'interfaces/api';
import cx from 'classnames';
import styles from './styles.module.less';
import { Container, Icon, Tag } from 'components';
import { faStethoscope } from '@fortawesome/pro-light-svg-icons';
import { useCurrentOrder, useToggleDiagnosis } from 'modules/orders/providers';

type DiagnosesProps = {
  requirement: OrderWizardRequirement;
  basket?: boolean;
};

export const Diagnoses: React.FC<DiagnosesProps> = ({ requirement, basket }) => {

  const currentOrder = useCurrentOrder();
  const toggleDiagnosis = useToggleDiagnosis();

  const diagnoses = requirement.diagnoses?.filter(d => d.costUnit === requirement.form?.costUnit);

  if (diagnoses?.length === 0) {
    return null;
  }

  return (
    <Container padding className={styles.forms}>

      <div>

        {diagnoses.map((diagnosis) => {

          return (
            <Tag
              key={diagnosis.id}
              className={cx(styles.diagnosis, {
                [styles.active]: !!currentOrder?.selectedDiagnoses[diagnosis.id],
                [styles.disabledLink]: !basket,
              })}
            >
              <a onClick={() => toggleDiagnosis(diagnosis)}>
                <Icon icon={faStethoscope}/>
                {diagnosis.text}
              </a>
            </Tag>
          );
        })}

      </div>

    </Container>
  );

};
