import { CaseEventType, CaseListMode, CaseStatus } from 'interfaces/api';

export default {
  listHeading: 'Case List',

  status: {
    [CaseStatus.Initial]: 'Initial',
    [CaseStatus.Active]: 'Active',
    [CaseStatus.Closed]: 'Closed',
  },

  eventType: {
    [CaseEventType.A01]: 'Admit/visit notification',
    [CaseEventType.A02]: 'Transfer a patient',
    [CaseEventType.A03]: 'Discharge/end visit',
    [CaseEventType.A04]: 'Register a patient',
    [CaseEventType.A05]: 'Pre-admit a patient',
    [CaseEventType.A06]: 'Change an outpatient to an inpatient',
    [CaseEventType.A07]: 'Change an inpatient to an outpatient',
    [CaseEventType.A08]: 'Update patient information',
  },

  eventLog: 'Event log',

  filters: {
    status: 'Status',
    eventType: 'Event (ADT)',
  },

  controls: {
    reports: 'Go to reports',
    orders: 'Go to orders',
    newOrder: 'New order',
    toggleOrderListOpened: 'Toggle list opened',
  },

  openInOb: 'Show in Online Reports',

  lastUpdate: 'Last update',
  lastEvent: 'Last event',
  lastEventAt: 'Last event at {date}',

  latestReports: 'Latest reports',
  latestOrders: 'Latest orders',

  releasePlannedAt: 'Planned release',
  releasedAt: 'Released at',

  mode: {
    title: 'Mode Selection',
    home: 'All Cases',
  },
  modes: {
    [CaseListMode.Alphabetical]: 'Alphabetical',
    [CaseListMode.Rooms]: 'Rooms',
  },

  noRoom: 'No room assigned',
  noCase: 'No case',

};
