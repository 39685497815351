import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { Control } from 'components';
import React, { useCallback, useMemo } from 'react';
import messages from 'messages';
import { useOrdersSelectors } from 'modules/orders/providers';
import { useApi } from 'providers';
import { useOnWizardDone } from 'modules/orders/utils';

export const CancelOrderControl = () => {

  const orders = useOrdersSelectors.orders();
  const onWizardDone = useOnWizardDone();

  const { orders: { cancelOrders } } = useApi();

  const cancel = useCallback(() => new Promise((resolve, reject) => {
    cancelOrders({ orderIds: orders.map(o => o.id) })
      .then(response => onWizardDone(response, resolve))
      .catch(reject);
  }), [cancelOrders, onWizardDone]);

  const button = useMemo(() => ({
    danger: true,
    block: true,
  }), []);

  return (
    <Control
      icon={faTimesCircle}
      label={messages.orders.controls.cancel.label}
      onClick={cancel}
      button={button}
    />
  );

};
