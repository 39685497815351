import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'modules/auth/store';
import { isLegacyLoginToken, LegacyLoginAid, LegacyLoginToken } from 'modules/auth/utils';
import queryString from 'query-string';
import { arrayify } from 'utils/helpers';
import { omit } from 'lodash';
import { ExchangeFileType } from 'interfaces/api';
import { ExchangeLocationState } from 'modules/exchange';
import { ReportLocationState } from 'modules/reports';
import { useApi } from 'providers';

export const useLegacyRoutes = () => {

  const { legacy: isLegacy, resolveLegacy, rejectLegacy, setUser, setLegacy } = useAuthStore.getState();

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname, search } = location;

  const { authentication: { exchangeToken, createLegacyToken } } = useApi();

  const checkLegacy = async () => {

    const legacyLogin = async (data: LegacyLoginAid | LegacyLoginToken) => {

      setLegacy(true);

      const user = await (() => {
        if (isLegacyLoginToken(data)) {
          return exchangeToken(data);
        } else {
          return createLegacyToken(data);
        }
      })();

      setUser(user, true);

    };

    if (!isLegacy) {

      const params = queryString.parse(search, { arrayFormat: 'bracket' });

      if (params.token) {
        // exchange token
        const state: any = {};
        try {
          await legacyLogin({ token: arrayify(params.token)[0] });
          resolveLegacy();
          navigate({ pathname, search: queryString.stringify(omit(params, 'token')) }, { state, replace: true });
        } catch (e) {
          rejectLegacy(e);
        }

      } else if (pathname.startsWith('/index')) {

        if (params.ID && params.key && params.url) {

          try {
            await legacyLogin({ aid: arrayify(params.ID)[0], key: arrayify(params.key)[0] });
          } catch (e) {
            rejectLegacy(e);
          }

          try {

            const el = document.createElement('a');
            el.href = 'http://localhost/' + decodeURIComponent((params.url as string).replace(/\+/g, ' '));

            const { func, cache, ...legacyParams } = queryString.parse(el.search);

            if (el.pathname === '/onlineauftrag/index.php') {

              if (func === 'neuerpoolauftrag' || func === 'neuerauftrag') {
                navigate('/oa?showWizard=1&' + queryString.stringify({ ...omit(legacyParams, 'url'), gdt2oa: params.url, aid: params.ID }), { replace: true });
              }

            } else if (el.pathname === '/labexchange/index.php') {

              const locationState: ExchangeLocationState = {};

              if (func === 'listFiles') {
                locationState.fileType = ExchangeFileType.LDT;
              } else if (func === 'listPDFFiles') {
                locationState.fileType = ExchangeFileType.PDF;
              } else if (func === 'listHL7Files') {
                locationState.fileType = ExchangeFileType.HL7;
              } else if (func === 'sonstigefiles') {
                locationState.fileType = ExchangeFileType.OTHER;
              }

              navigate('/le', { state: locationState, replace: true });

            } else if (el.pathname === '/onlinebefunde/index.php') {

              const locationState: ReportLocationState = {};

              if (func === 'patienten') {
                locationState.patients = { pid: parseInt(legacyParams.pid as string) };
              }

              navigate('/ob', { state: locationState, replace: true });

            }
          } catch (e) {
            resolveLegacy();
            navigate('/', { replace: true });
          }

        } else {
          console.warn('Route not found', pathname);
          resolveLegacy();
          navigate('/', { replace: true });
        }

      } else if (pathname.startsWith('/onlinebefunde/launcher.php')) {

        if (params.loadaisimport && params.id && params.key) {

          const loadAisImport = params.loadaisimport as string;
          const loadAisFirstname = params.aisfirstname as string;
          const loadAisLastname = params.aislastname as string;
          const loadAisBirthdate = params.aisbirthdate as string;
          const loadAisShowReport = (params.handle === 'befund');

          await legacyLogin({ aid: arrayify(params.id)[0], key: arrayify(params.key)[0] });

          try {
            if (loadAisImport === '1') {
              const locationState: ReportLocationState = {
                loadAisImport: { loadAisFirstname, loadAisLastname, loadAisBirthdate, loadAisShowReport },
              };

              navigate('/ob', { state: locationState, replace: true });
            }
          } catch (e) {
            resolveLegacy();
          }
        }

      }
    }

  };

  useEffect(() => {
    checkLegacy();
  }, [location.pathname]);

};
