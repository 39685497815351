import React, { useMemo } from 'react';
import { ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { Container, Filter, Filters } from 'components';
import { CompletionListOrderBy, OrderListMode } from 'interfaces/api';
import messages from 'messages';
import { splitEnumOptions } from 'utils/helpers';

const Component = React.memo((props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;

  const completionFilters: Filter[] = useMemo(() => [{
    label: messages.general.sort.label,
    filter: value => ({ completionOrder: parseInt(value) }),
    disallowClear: true,
    items: splitEnumOptions(CompletionListOrderBy, messages.orders.filters.completionOrder),
  }], []);

  return (
    <Container className={'margin-top-1'}>
      <Filters
        filters={completionFilters}
        onFilter={value => setContext({ filters: { ...context.filters, ...value } })}
        filterValues={context.filters}
      />
    </Container>
  );
});

export const CompletionSection: ListLayoutSideBarHiddenSectionCallback<OrdersListContext> = args => ({
  active: args.context.mode === OrderListMode.Completion,
  component: Component,
});
