import React from 'react';
import messages from 'messages';
import { useApi } from 'providers';
import { ReportControlContext } from 'modules/reports/index';
import { Control } from 'components';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';

export const SetUnread = (props: ReportControlContext) => {

  const { reports: { resetReportViewCount } } = useApi();

  const { data: report, bindings: { updateItem } } = props;
  const labels = messages.reports.controls.setUnread;

  return (
    <Control
      icon={faEyeSlash}
      label={labels.label}
      onClick={async () => {
        await resetReportViewCount({ bid: report.bid });
        await updateItem(report.bid, { ...report, is_unread: true });
      }}
    />
  );

};
