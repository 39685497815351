import { useEffect } from 'react';
import { useOfficeDoctorSelectors } from 'modules/orders/providers/OfficeDoctorProvider';
import { useCurrentOrder } from 'modules/orders/providers/OrdersProvider/useCurrentOrder';
import { useSetOrders } from 'modules/orders/providers/OrdersProvider/useSetOrders';
import { useApi } from 'providers';
import { OrderChangeAction, OrderWizardLocalization } from 'interfaces/api';
import { useAppendLog } from 'modules/orders/providers/OrdersProvider/useAppendLog';
import { mapCostUnit } from 'modules/orders/utils';

export const useCostUnitEffects = () => {

  const currentOrder = useCurrentOrder();
  const appendLog = useAppendLog();

  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const { setOrderProperties } = useSetOrders();

  /**
   * Log cost unit change
   */
  useEffect(() => {
    if (currentOrder?.costUnit) {
      appendLog(OrderChangeAction.CostUnitChanged, { costUnit: currentOrder?.costUnit });
    }
  }, [currentOrder?.costUnit]);

  const setSingleCostUnit = () => {
    // set cost unit if only one is available
    if (wizardSettings?.costUnits?.length === 1 && currentOrder && currentOrder.costUnit === undefined) {
      setOrderProperties({ costUnit: wizardSettings?.costUnits[0] });
    }
  };

  useEffect(setSingleCostUnit, [wizardSettings?.costUnits]);

  const { orderWizardAustriaSettings: { getAustriaHvCode } } = useApi();

  const setCostUnitFromPatientHvCode = () => {
    // set cost unit from patient hv code
    const patientHvCode = currentOrder?.patient?.hvcode;
    if (patientHvCode && officeDoctor?.localisation === OrderWizardLocalization.AUT && wizardSettings?.costUnits) {
      getAustriaHvCode({ code: patientHvCode }).then(({ costUnit }) => {
        setOrderProperties({ costUnit });
        appendLog(OrderChangeAction.SetCostUnitFromPatientHvCode, { costUnit });
      });
    }
  };

  useEffect(setCostUnitFromPatientHvCode, [currentOrder?.patient?.hvcode, officeDoctor?.localisation, wizardSettings?.costUnits]);

  const setCostUnitFromPatient = () => {
    if (officeDoctor?.localisation && officeDoctor?.localisation !== OrderWizardLocalization.AUT) {
      // set cost unit from patient
      if (currentOrder && currentOrder.costUnit === undefined) {
        const patientCostUnit = currentOrder.patient?.costUnit;
        if (patientCostUnit?.length > 0) {
          const newCostUnit = mapCostUnit(patientCostUnit);
          if (wizardSettings?.costUnits.includes(newCostUnit)) {
            setOrderProperties({ costUnit: newCostUnit });
            appendLog(OrderChangeAction.SetCostUnitFromPatient, { costUnit: newCostUnit });
            return;
          }
        }
      }
    }
  };

  useEffect(setCostUnitFromPatient, [wizardSettings?.costUnits, currentOrder?.patient, officeDoctor?.localisation]);

};
