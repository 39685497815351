import React from 'react';
import { Container, ControlProps, ModalFormControl, Tabs } from 'components';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { FormLayout } from 'containers';
import { GroupForm } from 'interfaces/api';
import { AnyFunction } from 'utils/helpers';
import { flatMap, get, map, reduce, set, values } from 'lodash';

type ItemGroups = Record<string, {
  name: string;
  groupForms: [{
    id: number;
    aid: number;
    name: string;
    costUnit: string;
    order: number;
  }];
}>;

type Props = ControlProps & {
  aid: number;
  groupForms: GroupForm[];
  onSuccess?: AnyFunction;
};

const labels = messages.orders.wizard.groupForms;

export const SortGroupFormsControl = (props: Props) => {

  const { aid, groupForms, onSuccess, ...controlProps } = props;
  const { orderSettings: { updateGroupFormsOrder } } = useApi();

  const itemGroups = reduce(groupForms.sort((g1, g2) => g1.order < g2.order ? -1 : g2.order < g1.order ? 1 : 0),
    (acc, gf, index) => {
      if (!get(acc, gf.costUnit)) {
        set(acc, gf.costUnit, { name: gf.costUnit, groupForms: [] });
      }

      acc[gf.costUnit].groupForms.push({
        aid,
        id: gf.id,
        name: gf.name,
        costUnit: gf.costUnit,
        order: index,
      });

      return acc;
    }, {} as ItemGroups);

  return (
    <ModalFormControl
      label={labels.sort}
      icon={faList}
      {...controlProps}
      modal={{
        narrow: true,
        okText: messages.general.save,
        title: labels.sort,
      }}
      form={{
        onSuccess,
        initialValue: {
          aid,
          itemGroups,
        },
        request: (data) => {
          const groupFormOrders = flatMap(values(data.itemGroups), g => map(g.groupForms, gf => ({ aid, groupFormId: gf.id, order: gf.order })));
          updateGroupFormsOrder({ aid, groupFormOrders });
        },
        children: ({ DynamicFields, value, onChange }) => (
          <Tabs
            tabs={map(values(itemGroups), g => ({
              title: g.name,
              children: (
                <Container scrollY>
                  <FormLayout embedded>
                    <DynamicFields
                      horizontal
                      sortable
                      hideAdd
                      itemReadOnly={() => true}
                      value={g.groupForms}
                      onChange={(v) => {
                        const groupForms = map(v.slice(), (gf, index) => ({ ...gf, order: index }));
                        const data = { ...value };
                        set(data, `itemGroups.${g.name}.groupForms`, groupForms);
                        onChange(data);
                      }}
                    >
                      {({ Plaintext }) => {
                        return (
                          <>
                            <Plaintext property={'name'}/>
                          </>
                        );
                      }}
                    </DynamicFields>
                  </FormLayout>
                </Container>
              ),
            }))}
          />
        ),
      }}
    />
  );
};
