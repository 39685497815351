export default {
  label: 'Manufacturers',
  fields: {
    name: 'Name',
    link: 'Link',
    isActive: 'Active',
  },
  controls: {
    add: {
      label: 'Add manufacturer',
    },
    edit: {
      label: 'Edit manufacturer',
    },
    delete: {
      label: 'Delete manufacturer',
      confirm: 'Confirm delete',
    },
  },
};
