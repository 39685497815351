import React, { useEffect, useRef } from 'react';
import { getParentScrollContainer } from 'utils/dom';
import { throttle } from 'lodash';

interface Props {
  onNext?: () => void;
  children: React.ReactNode;
}

export const InfiniteScroll = (props: Props) => {

  const container = useRef(null);

  useEffect(() => {
    const scrollContainer = getParentScrollContainer(container.current);

    const handleScroll = throttle(() => {
      if (scrollContainer && scrollContainer.offsetHeight + scrollContainer.scrollTop > container.current.offsetHeight - scrollContainer.offsetHeight / 2) {
        props.onNext?.();
      }
    }, 100);

    scrollContainer.addEventListener('scroll', handleScroll, false);

    handleScroll();

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll, false);
    };
  }, []);

  return (
    <div ref={container}>
      {props.children}
    </div>
  );

};
