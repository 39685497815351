import React from 'react';
import { FormItemRenderProps } from 'components/Form/Item';
import { OrderAnimalProperties } from 'interfaces/api';
import { Container } from 'components';
import messages from 'messages';

export const AnimalAdditionalInfoForm: React.ComponentType<FormItemRenderProps<OrderAnimalProperties>> = (props) => {

  const { Input } = props;

  const animalLabels = messages.general.animal;
  const personLabels = messages.general.person;

  return (
    <Container grid={'49%'}>
      <Input property={'ownerName'} label={animalLabels.owner.name} maxLength={150} floating/>
      <Input property={'ownerAmendment'} label={animalLabels.owner.amendment} maxLength={50} floating/>
      <Input property={'ownerStreet'} label={personLabels.street} maxLength={50} floating/>
      <Input property={'ownerHouseNumber'} label={personLabels.houseNumber} maxLength={10} floating className={'oa-patient-house-number'}/>
      <Input property={'ownerZipCode'} label={personLabels.zip} maxLength={10} floating/>
      <Input property={'ownerCity'} label={personLabels.city} maxLength={50} floating/>
    </Container>
  );

};
