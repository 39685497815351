import React from 'react';
import { useSize } from 'react-use';
import { getCssVariableValue } from 'utils/dom';
import Masonry from 'react-masonry-css';
import { ListColumnType } from 'components/List/List';

export type ListWrapColumnObserverProps = {
  children: React.ReactNode;
  columnType: ListColumnType;
  columns?: number;
};

export const ListColumnObserver: React.FC<ListWrapColumnObserverProps> = ({ columnType, columns, children }) => {

  const [sized] = useSize(
    ({ width }) => {

      const columnWidth = parseInt(getCssVariableValue('--list-column-grid-width').replace('px', ''));
      const columnBroaderWidth = parseInt(getCssVariableValue('--list-column-grid-broader-width').replace('px', ''));

      const calculateColumns = (broader?: boolean) => columns || Math.floor((width - 12) / ((broader ? columnBroaderWidth : columnWidth) + 18));

      if ([ListColumnType.Masonry, ListColumnType.MasonryBroader].includes(columnType)) {
        return (
          <div>
            <Masonry
              breakpointCols={calculateColumns(columnType === ListColumnType.MasonryBroader)}
              className={'list-masonry-wrapper'}
              columnClassName={'list-masonry-column'}
            >
              {children}
            </Masonry>
          </div>
        );
      }

      if ([ListColumnType.Vertical, ListColumnType.VerticalBroader].includes(columnType)) {
        return (
          <div
            className={'list-columns-vertical-wrapper'}
            style={{ columnCount: calculateColumns(columnType === ListColumnType.VerticalBroader) }}
          >
            {children}
          </div>
        );
      }

      return (
        <>{children}</>
      );

    },
  );

  return sized;

};
