import React from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Color } from 'interfaces';
import { faExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Icon, IconProps } from 'components/Icon/Icon';

export const AlertIcon: React.FC<Partial<IconProps>> = (props) => {
  return (
    <Icon
      {...props}
      icon={[
        { icon: faCircle, color: Color.Alert },
        { icon: faExclamation, color: Color.White, transform: 'shrink-6' },
      ]}
    />
  );
};
