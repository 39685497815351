import React from 'react';
import { ApiDefinition } from 'interfaces/api';
import { useApi } from './ApiProvider';

export type InjectedApiProps = {
  api: ApiDefinition;
};

type OwnProps<P extends InjectedApiProps> = Omit<P, keyof InjectedApiProps>;

export function injectApi<P extends InjectedApiProps>(ComposedComponent: React.ComponentType<P>): React.ComponentType<OwnProps<P>> {
  return (props: P & InjectedApiProps) => {
    const api = useApi();
    return <ComposedComponent {...props} api={api}/>;
  };
}
