import { RequirementAggregationType } from 'interfaces/api';
import { IconProps } from 'components';
import { GuardConfig } from 'containers';
import { Color, CssColor } from 'interfaces';

export enum MissingRequirementOption {
  Form,
  Analyses,
  LeftRight,
  Intraoperative,
  Origin,
  Localization,
  DynamicMaterials,
  RequirementFreeText,
  RequirementFreeTextDate,
}

export type OrderAggregationConfig = {
  color: Color | CssColor | string;
  icon: IconProps;
  type: RequirementAggregationType;
  guard?: GuardConfig;
  className?: string;
};
