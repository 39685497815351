import React from 'react';
import { faBarcode } from '@fortawesome/pro-light-svg-icons';
import { useApi } from 'providers';
import { ChooseWorkstationControl } from 'modules/orders/components/ChooseWorkstationControl';
import messages from 'messages';

type Props = {
  orderId: number;
  aid: number;
  apid?: number;
  barcodeNumber: string;
  probeType: string;
};

const labels = messages.orders.controls.printBarcode;

export const PrintBarcode = (props: Props) => {

  const { print: { printBarcode } } = useApi();
  const { orderId, barcodeNumber, probeType } = props;

  return (
    <ChooseWorkstationControl
      icon={faBarcode}
      label={labels.label}
      notifications={labels.notifications}
      aid={props.aid}
      defaultSelected={props.apid}
      onDone={async (apid) => {
        await printBarcode({ barcodeNumber, probeType, id: orderId, workstation: apid });
      }}
    />
  );
};
