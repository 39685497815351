export default {
  pages: {
    reports: 'Examinations',
    requirements: 'Requirements',
  },
  controls: {
    add: {
      label: 'Add Hint',
      notifications: {
        success: 'Hint added successfully',
      },
    },
    edit: {
      label: 'Edit Hint',
      notifications: {
        success: 'Hint updated successfully',
      },
    },
    delete: {
      label: 'Delete Hint',
      notifications: {
        success: 'Hint deleted successfully',
      },
      confirm: {
        title: 'Do you really want to delete this hint?',
        content: 'The deleted hint can not be restored.',
      },
      form: {
        labels: {
          delete: 'Delete',
        },
      },
    },
  },
  reports: {
    labels: {
      key: 'Key',
      articleName: 'Titel',
      articleRef: 'Reference',
      displayContent: 'Content to display',
      mainContent: 'Main content',
      doctorCustomContent: 'Doctor content',
      patientCustomContent: 'Patient content',
    },
  },
  requirements: {},
};
