import React, { useCallback, useEffect, useMemo } from 'react';
import { OrderChangeAction, OrderStatus, WriteableOrderProperties } from 'interfaces/api';
import { getLastUsedWorkstation, setLastUsedWorkstation, useApi, useTranslate } from 'providers';
import {
  useAppendLog,
  useApplyOrderDefaultValues,
  useCurrentOrder,
  useMapSwitzerlandInsuranceValues,
  useOfficeDoctorSelectors,
  useOrdersSelectors,
  useOrdersStore,
  useSetOrders,
} from 'modules/orders/providers/index';
import { OrderWizardParametersPropsState, useOrderWizardParametersSelectors, useShallowParameters } from 'modules/orders/containers/OrderWizard/providers';
import { find, isNil, keys, omit } from 'lodash';
import { useLogger } from 'providers/LoggerProvider/LoggerProvider';
import { useAsync, usePrevious } from 'react-use';
import { diff } from 'deep-object-diff';
import messages from 'messages';
import { useCostUnitEffects, useLoadPatientReports, useLoadSamePatientOrders, useSetStartGroupForm } from 'modules/orders/providers/OrdersProvider/effects';

export const OrdersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const orders = useOrdersSelectors.orders();

  const globalSettings = useOrdersSelectors.globalSettings();
  const setGlobalSettings = useOrdersSelectors.setGlobalSettings();

  const selectedOrderIndex = useOrdersSelectors.selectedOrderIndex();
  const selectOrderAtIndex = useOrdersSelectors.selectOrderAtIndex();

  const currentOrder = useCurrentOrder();
  const appendLog = useAppendLog();

  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const logger = useLogger('OrdersProvider');

  const translate = useTranslate();

  useCostUnitEffects();
  useLoadPatientReports();
  useLoadSamePatientOrders();
  useSetStartGroupForm();

  const { setOrdersMapped, setOrderProperties } = useSetOrders();

  const groupFormId = useOrderWizardParametersSelectors.groupFormId();

  const {
    orderWizardShowWorkstationSelect,
    orderWizardRememberLastWorkstation,
    orderWizardRememberLastDeviceWorkstation,
    orderWizardLastWorkstationId,
  } = wizardSettings?.preferences || {};

  const lastUserWorkstation = useMemo(
    () => orderWizardShowWorkstationSelect && orderWizardRememberLastWorkstation ? orderWizardLastWorkstationId : undefined,
    [wizardSettings?.preferences],
  );

  const lastDeviceWorkstation = useAsync(async () => {
    const id = orderWizardShowWorkstationSelect && orderWizardRememberLastDeviceWorkstation ? await getLastUsedWorkstation() : undefined;
    return id && parseInt(id);
  }, [wizardSettings?.preferences]);

  const lastWorkstationId = useMemo(() => lastDeviceWorkstation.value || lastUserWorkstation, [lastDeviceWorkstation, lastUserWorkstation]);

  useEffect(() => {
    // set last used workstation id
    if (!globalSettings?.apid && lastWorkstationId > 0) {
      setGlobalSettings({ ...globalSettings, apid: lastWorkstationId });
      appendLog(OrderChangeAction.SetLastUsedWorkstation, { apid: lastWorkstationId });
    }
  }, [globalSettings?.apid, lastWorkstationId, orderWizardRememberLastDeviceWorkstation]);

  useEffect(() => {
    if (orderWizardRememberLastDeviceWorkstation && globalSettings?.apid && globalSettings?.apid !== lastDeviceWorkstation.value) {
      setLastUsedWorkstation(globalSettings?.apid);
    }
  }, [globalSettings?.apid, lastDeviceWorkstation.value]);

  const { orderWizardFillSampleDateFromScheduleDate } = wizardSettings?.preferences || {};

  useEffect(() => {
    if (globalSettings.scheduled_at && orderWizardFillSampleDateFromScheduleDate) {
      setOrdersMapped(o => ({ ...o, patient: { ...o.patient, sampleDate: globalSettings.scheduled_at } }));
    }
  }, [globalSettings.scheduled_at, orderWizardFillSampleDateFromScheduleDate]);

  const { orders: { cancelEditOrders } } = useApi();
  const handleTabClosing = () => {
    const editingOrders = orders.filter(o => o.status === OrderStatus.Processing);
    if (editingOrders.length > 0) {
      cancelEditOrders({ poolIds: editingOrders.map(o => o.poolId) });
    }
  };

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  });

  // reset selected order if last was deleted
  useEffect(() => {
    if (selectedOrderIndex > orders?.length - 1) {
      selectOrderAtIndex(undefined);
    }
  }, [selectedOrderIndex, orders.length]);

  const mapSwitzerlandInsuranceValues = useMapSwitzerlandInsuranceValues();

  useEffect(() => {
    if (currentOrder?.patient) {
      setOrderProperties({ patient: mapSwitzerlandInsuranceValues(currentOrder?.patient) });
    }
  }, [mapSwitzerlandInsuranceValues]);

  // set insurance name from patient
  const setInsuranceNameFromPatient = () => {
    if (!currentOrder?.insuranceName && currentOrder?.patient?.insuranceName) {
      const insuranceName = currentOrder?.patient?.insuranceName;
      setOrderProperties({ insuranceName });
      appendLog(OrderChangeAction.SetInsuranceNameFromPatient, { insuranceName });
    }
  };

  const setPool = useOrderWizardParametersSelectors.setPool();

  // set pool parameter
  useEffect(() => {
    setPool(orders.length > 1);
  }, [orders.length]);

  useEffect(setInsuranceNameFromPatient, [currentOrder?.patient?.insuranceName]);

  const applyOrderDefaultValues = useApplyOrderDefaultValues();

  const setOrdersDefaultValues = useCallback(() => {
    useOrdersStore.setState(({ orders }) => ({ orders: orders.map(o => applyOrderDefaultValues(o)) }));
  }, [applyOrderDefaultValues]);

  useEffect(() => {
    setOrdersDefaultValues();
  }, [wizardSettings]);

  /**
   * Global settings changed
   */
  const previousGlobalSettings = usePrevious(globalSettings);
  const diffGlobalSettings = diff(previousGlobalSettings, globalSettings);
  useEffect(() => {
    if (keys(diffGlobalSettings).length > 0) {
      appendLog(OrderChangeAction.GlobalSettingsChanged, diffGlobalSettings);
      logger.debug('Global Settings', globalSettings);
      setOrdersDefaultValues();
    }
  }, [diffGlobalSettings]);

  /**
   * Office Doctor changed
   */
  const previousOfficeDoctor = usePrevious(officeDoctor);
  const diffOfficeDoctor = diff(previousOfficeDoctor, officeDoctor);
  useEffect(() => {
    if (keys(diffOfficeDoctor).length > 0) {
      appendLog(OrderChangeAction.OfficeDoctorChanged, diffOfficeDoctor);
      setOrdersDefaultValues();
    }
  }, [diffOfficeDoctor]);

  /**
   * Parameters changed
   */
  const paramsState = useShallowParameters();
  const previousParameters = usePrevious(paramsState);
  const diffParameters: Partial<OrderWizardParametersPropsState> = diff(previousParameters, paramsState);
  useEffect(() => {
    if (keys(diffParameters).length > 0) {
      if (diffParameters.groupFormId) {
        appendLog(OrderChangeAction.GroupForm, find(wizardSettings?.groupForms, { id: groupFormId }));
      } else if (!isNil(diffParameters.groupBy)) {
        appendLog(OrderChangeAction.GroupBy, { name: translate(messages.orders.requirementAggregations.types[diffParameters.groupBy]) });
      } else {
        appendLog(OrderChangeAction.ParametersChanged, diffParameters);
      }
    }
  }, [diffParameters]);

  /**
   * Orders changed
   */
  const omitOrderProps = (orders: WriteableOrderProperties[]) => orders?.map(o => omit(o, ['requirements']));
  const previousOrders = usePrevious(orders);
  const diffOrders = diff(omitOrderProps(previousOrders), omitOrderProps(orders));
  useEffect(() => {
    if (keys(diffOrders).length > 0) {
      logger.debug('Orders', orders);
      appendLog(OrderChangeAction.OrderChanged, diffOrders);
    }
  }, [diffOrders]);

  return children;

};
