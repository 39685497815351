import React from 'react';
import { default as BaseTag, TagProps as BaseTagProps } from 'antd/es/tag';
import './Tag.less';
import { getCssColor } from 'utils/dom';
import { Color, CssColor } from 'interfaces';

export type TagProps = Omit<BaseTagProps, 'color'> & {
  color?: Color | CssColor | string;
};

export const Tag: React.FC<TagProps> = (props) => {

  const { color, ...baseProps } = props;

  return (
    <BaseTag
      {...baseProps}
      color={props.color ? getCssColor(props.color) : undefined}
    >
      {props.children}
    </BaseTag>
  );
};
