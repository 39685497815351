import React from 'react';
import { Order } from 'interfaces/api';
import { FormatDate, Translate } from 'providers';
import messages from 'messages';
import { Card } from 'components/Card';

type Props = {
  order: Order;
};

export default React.memo((props: Props) => {

  const {
    status,
    tnr,
    created_at,
    executed_at,
    hospitalStation,
  } = props.order;

  const {
    exttnr,
    caseNumber,
    patientIdentification,
    hospitalBedNumber,
    hospitalRoomNumber,
    hospitalProfession,
  } = props.order.patient;

  return (
    <Card className={'info-popover'} title={<Translate message={(messages.orders.status as any)[status]}/>}>
      <span>
        <b><Translate message={messages.reports.details.orderNumber}/></b>&nbsp;
        {tnr}
      </span>
      {exttnr && (
        <span>
          <b><Translate message={messages.reports.details.laboratoryNumber}/></b>&nbsp;
          {exttnr}
        </span>
      )}
      {created_at && (
        <span>
          <b><Translate message={messages.orders.created_at}/></b> <FormatDate date={created_at}/>
        </span>
      )}
      {executed_at && (
        <span>
          <b><Translate message={messages.orders.executed_at}/></b> <FormatDate date={executed_at}/>
        </span>
      )}
      {caseNumber && (
        <span>
          <b><Translate message={messages.orders.additionalFields.caseNumber}/></b>&nbsp;
          {caseNumber}
        </span>
      )}
      {patientIdentification && (
        <span>
          <b><Translate message={messages.orders.additionalFields.patientIdentification}/></b>&nbsp;
          {patientIdentification}
        </span>
      )}
      {hospitalStation && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalStation}/></b>&nbsp;
          {hospitalStation}
        </span>
      )}
      {hospitalProfession && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalProfession}/></b>&nbsp;
          {hospitalProfession}
        </span>
      )}
      {hospitalRoomNumber && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalRoomNumber}/></b>&nbsp;
          {hospitalRoomNumber}
        </span>
      )}
      {hospitalBedNumber && (
        <span>
          <b><Translate message={messages.orders.additionalFields.hospitalBedNumber}/></b>&nbsp;
          {hospitalBedNumber}
        </span>
      )}
    </Card>
  );

});
