import React, { useMemo } from 'react';
import { ListLayoutArgs, ListLayoutSideBarHiddenSectionCallback } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { Container, Filter, Filters } from 'components';
import { BookmarkedMode, OrderListMode } from 'interfaces/api';
import messages from 'messages';

const labels = messages.orders.filters;

const Component = React.memo((props: ListLayoutArgs<OrdersListContext>) => {

  const { context, bindings: { setContext } } = props;

  const filters = useMemo(() => {
    const filters: Filter[] = [{
      label: labels.bookmarkedModes.label,
      filter: value => ({
        bookmarkedMode: parseInt(value),
        ...(
          parseInt(value) !== BookmarkedMode.Custom
            ? { scheduledFrom: undefined, scheduledTo: undefined }
            : {}
        ),
      }),
      disallowClear: true,
      items: [
        BookmarkedMode.Today,
        BookmarkedMode.Coming,
        BookmarkedMode.Old,
        BookmarkedMode.Custom,
      ].map(d => ({
        label: labels.bookmarkedModes[d],
        value: d,
      })),
    }];

    if (context.filters.bookmarkedMode === BookmarkedMode.Custom) {
      filters.push(...[{
        label: labels.bookmarkedModes.scheduledFrom,
        filter: (value: string) => ({ scheduledFrom: value }),
        isDate: true,
      }, {
        label: labels.bookmarkedModes.scheduledTo,
        filter: (value: string) => ({ scheduledTo: value }),
        isDate: true,
      }]);
    }

    return filters;

  }, [context.filters.bookmarkedMode]);

  return (
    <Container className={'margin-top-1'}>
      <Filters
        filters={filters}
        onFilter={value => setContext({ filters: { ...context.filters, ...value } })}
        filterValues={context.filters}
      />
    </Container>
  );
});

export const BookmarkedSection: ListLayoutSideBarHiddenSectionCallback<OrdersListContext> = args => ({
  active: args.context.mode === OrderListMode.Bookmarked,
  component: Component,
});
