export default {

  exchange: {
    labGroups: 'Laboratory groups',
    fileTypes: 'File Types',
    history: 'Export History',
  },

  orders: {
    topRequest: 'Top Requests',
    orderDevelopment: 'Order Development',
    topUsers: 'Top Users',
  },

  general: {
    addBtn: 'New Order',
    greetings: {
      goodMorning: 'Good morning',
      goodDay: 'Good day',
      goodAfternoon: 'Good afternoon',
      goodEvening: 'Good evening',
    },
    showAll: 'Show all',
  },

  overviewWidget: {
    reports: {
      title: 'Reports',
      subtitle: 'unread',
    },
    orders: {
      title: 'Orders',
      subtitle: 'Not completed',
    },
  },

  messagesWidget: {
    title: 'Messages',
  },

  notificationsWidget: {
    title: 'Notifications',
  },

  reportsWidget: {
    title: 'Reports',
  },

  ordersWidget: {
    title: 'Orders flagged or pre-printed',
  },

  graphWidget: {
    title: 'Latest 100 reports',
    reportGroups: 'Report groups',
    reportTypes: {
      title: 'Report Types',
      labels: {
        normal: 'Normal',
        patho: 'Pathological',
        extreme: 'Extreme',
      },
    },
    patientGroups: 'Patient Demographics',
  },

};
