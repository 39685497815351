import React from 'react';
import { Container } from 'components';
import './ToolBar.less';
import cx from 'classnames';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: any;
};

export const ToolBar = (props: Props) => {

  const { children, className } = props;

  return (
    <Container horizontal className={cx('toolbar', className)}>
      {children}
    </Container>
  );

};
