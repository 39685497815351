import React, { useMemo, useState } from 'react';
import { Icon, Modal, Popover } from 'components';
import cx from 'classnames';
import './LanguageSwitch.less';
import { Message } from 'interfaces';
import { Translate, useApi, useIntlStoreSelectors } from 'providers';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useReloadUserDetails } from 'modules/auth/hooks';
import messages from 'messages';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { map } from 'lodash';
import { useEnv } from 'providers/EnvProvider';

export type Props = {
  isListElement?: boolean;
  className?: string;
  modal?: {
    title: Message;
  };
};

export default (props: Props) => {

  const [showModal, setShowModal] = useState<boolean>(false);

  const locales = useEnv.locales();

  const currentLocale = useIntlStoreSelectors.locale();
  const setLocale = useIntlStoreSelectors.setLocale();

  const user = useAuthStoreSelectors.user();
  const reloadUserDetails = useReloadUserDetails();

  const { globals: { updateConfig } } = useApi();

  const { modal } = props;

  const switchLanguage = async (locale: any) => {
    setLocale(locale);
    if (user) {
      await updateConfig({ locale });
      await reloadUserDetails();
    }
    if (modal) {
      setShowModal(false);
    }
  };

  const content = useMemo(() => (
    <ul className={'language-switch-list'}>
      {map(locales, locale => (
        <li key={locale} className={cx({ 'is-active': currentLocale === locale })}>
          <a onClick={() => switchLanguage(locale)}>
            <Translate message={messages.general.i18n.locales[locale]}/>
          </a>
        </li>
      ))}
    </ul>
  ), [currentLocale]);

  if (modal) {
    return (
      <>
        <a onClick={() => setShowModal(true)}>
          <Translate message={modal.title}/>
        </a>

        <span onClick={e => e.stopPropagation()}>
          <Modal
            narrow
            title={modal.title}
            open={showModal}
            onCancel={() => setShowModal(false)}
            destroyOnClose={true}
            className={'language-switch-modal'}
            footer={null}
          >
            {content}
          </Modal>
        </span>

      </>
    );
  }

  const label = useMemo(() => (
    <span className={'language-switch-label'}>
      <Translate message={messages.general.i18n.locales[currentLocale]}/>
      <span className={'language-switch-caret'}>
        <Icon icon={faChevronDown}/>
      </span>
    </span>
  ), []);

  return (
    <Popover content={content} className={cx(props.className, 'auth-language-switch')} placement={'bottomRight'}>
      {label}
    </Popover>
  );

};
