import React from 'react';
import { useMandatoryDiagnoses, useOrderRulesContext } from 'modules/orders/providers';
import { DiagnosesSelect } from 'modules/orders/components/DiagnosesSelect';
import messages from 'messages';

export const Diagnoses = () => {

  const { cancel, loading, pending, setPending } = useOrderRulesContext();
  const { diagnosisErrors, getAvailableDiagnoses } = useMandatoryDiagnoses();

  if (!diagnosisErrors) {
    return null;
  }

  const currentIndex = diagnosisErrors[0].index;
  const diagnoses = getAvailableDiagnoses(pending.orders[currentIndex]);

  return (
    <DiagnosesSelect
      loading={loading}
      diagnoses={diagnoses}
      value={pending.orders[currentIndex].selectedDiagnoses}
      cancel={cancel}
      next={(selectedDiagnoses) => {
        const orders = pending.orders.map((o, idx) => ({
          ...o,
          selectedDiagnoses: idx === currentIndex ? selectedDiagnoses : o.selectedDiagnoses,
          selectedDiagnosesChecked: idx === currentIndex ? true : o.selectedDiagnosesChecked,
        }));
        setPending({ ...pending, orders, isUpsert: true });
      }}
      nextLabel={messages.orders.diagnoses.save}
    />
  );

};
