import React from 'react';
import { Container } from 'components';
import './Panel.less';
import cx from 'classnames';
import { useRenderContent, WithContentOuterProps } from 'hocs';
import { CSSTransition } from 'react-transition-group';

export type PanelProps = {
  controls?: React.ReactNode;
  className?: any;
  fixed?: boolean;
  hidden?: boolean;
} & WithContentOuterProps;

export const Panel: React.FC<PanelProps> = (props) => {

  const [renderContent, rest] = useRenderContent(props);

  const { controls, className, hidden, fixed } = rest;

  const containerClasses = cx('panel', className, { fixed });

  return (
    <CSSTransition in={!hidden} timeout={200} classNames={'reveal-bottom'} unmountOnExit>
      <Container className={containerClasses} horizontal>

        <Container grow className={'panel-content'}>
          {renderContent()}
        </Container>

        <Container horizontal className={'panel-controls'}>
          {controls}
        </Container>

      </Container>
    </CSSTransition>
  );
};
