import React from 'react';
import { ApiRequest } from 'containers';
import { useApi } from 'providers';
import { SelectProps } from 'components';

type Props = {
  selectRenderer: (props: Partial<SelectProps<any>>) => React.ReactNode;
};

export const VkatSelect: React.FC<Props> = (props) => {

  const { orderWizardAustriaSettings: { getAustriaInsuranceCategories } } = useApi();

  return (
    <ApiRequest
      errorAsAlert
      request={() => getAustriaInsuranceCategories()}
      children={({ data }) => props.selectRenderer({
        options: data.map(vcat => ({ label: vcat.title, value: vcat.code })),
      })}
    />
  );

};
