import { useMemo } from 'react';
import { useOrdersSelectors } from './';

export const useCurrentOrder = () => {

  const orders = useOrdersSelectors.orders();
  const selectedOrderIndex = useOrdersSelectors.selectedOrderIndex();

  const atIndex = (orders.length === 1 || selectedOrderIndex !== undefined) ? selectedOrderIndex || 0 : undefined;
  return useMemo(() => atIndex !== undefined ? orders[atIndex] : undefined, [orders, atIndex]);
};
