import React from 'react';
import { ReportListContext } from 'modules/reports/index';
import { Checkbox, Filter } from 'components';
import { Translate } from 'providers';
import { GuardFunction, ListLayoutArgs, ListLayoutSideBarSectionCallback, useGuard } from 'containers';
import messages from 'messages';
import { Feature, ReadStatusType, UserType } from 'interfaces/api';
import { filter } from 'lodash';

const quickFilterLabels = messages.reports.quickFilters;

export const getQuickFilters = (guard: GuardFunction): Filter[] => {
  return filter([
    {
      label: quickFilterLabels.pathological,
      filter: () => ({ pathological: true }),
    },
    guard({ permitted: [UserType.ARZ, UserType.LAB, UserType.PAT] }, () => ({
      label: quickFilterLabels.unread,
      filter: () => ({ unread: ReadStatusType.Unread }),
    })),
    guard({ feature: Feature.CorrectReport }, () => ({
      label: quickFilterLabels.corrected,
      filter: () => ({ corrected: true }),
    })),
    {
      label: quickFilterLabels.pinned,
      filter: () => ({ pinned: true }),
    },
    {
      label: quickFilterLabels.today,
      filter: () => ({ today: true }),
    },
  ]);
};

const Component = (props: ListLayoutArgs<ReportListContext>) => {

  const { context, bindings } = props;

  const guard = useGuard();

  return (
    <ul>
      {filter(getQuickFilters(guard)).map((filter, index) => (
        <li key={index}>
          <Checkbox
            checked={index === context.quickFilter}
            onChange={() => bindings.setContext({ quickFilter: context.quickFilter === index ? undefined : index, filterProfile: undefined, filters: {} })}
          >
            <Translate message={filter.label}/>
          </Checkbox>
        </li>
      ))}
    </ul>
  );
};

export const QuickFiltersSection: ListLayoutSideBarSectionCallback<ReportListContext> = () => ({
  title: messages.general.filter.quick,
  component: Component,
});
