import React, { useMemo } from 'react';
import { Container, Icon, Search, SearchProps, SelectedAutocomplete, ToolBar } from 'components';
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons';
import { Translate } from 'providers';
import { AutocompleteTag } from 'containers/ListLayout/SideBar/SideBar';
import { ListLayoutArgs, ListLayoutContext } from 'containers/ListLayout/ListLayout';
import { Message } from 'interfaces';

type Props<Context> = ListLayoutArgs<Context> & {
  label: Message;
  search?: Partial<SearchProps>;
  onSearch: (value: any, reset: () => void) => void;
  onAutocomplete?: (selected: SelectedAutocomplete) => void;
  hasSideBar?: boolean;
  children?: React.ReactNode;
};

export const ListLayoutToolBar = <Context extends ListLayoutContext>(props: Props<Context>) => {

  const { children, label } = props;

  const search = useMemo(() => ({
    onSearch: props.onSearch,
    onAutocomplete: props.onAutocomplete,
    ...props.search,
  }), [props.search]);

  return (
    <ToolBar>
      <Container className={'toolbar-left'}>

        {label && (
          <h1 onClick={props.hasSideBar ? props.bindings.toggleSideBar : undefined}>
            <Icon icon={faSlidersH} className={'sidebar-toggle is-hidden-desktop-xl-up'}/>
            <Translate message={label}/>
          </h1>
        )}

        {!props.hasSideBar && props.search && (
          props.context.autocomplete
            ? (
              <AutocompleteTag context={props.context} bindings={props.bindings}/>
            )
            : (
              <Search {...search} />
            ))}

      </Container>

      {children && (
        <Container horizontal className={'toolbar-right'}>
          {children}
        </Container>
      )}
    </ToolBar>
  );

};
