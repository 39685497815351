import React, { useCallback, useMemo } from 'react';
import { ReportValue } from 'interfaces/api';
import { Chart, Container } from 'components';
import dayjs from 'dayjs';
import './Chart.less';
import cx from 'classnames';
import { Color } from 'interfaces';
import { getCssColor, getCssVariableValue } from 'utils/dom';
import { filter, flatMap, max, min, uniqBy } from 'lodash';
import { EChartsOption } from 'echarts';

interface Props {
  values: ReportValue[][];
  titles: string[];
  selected?: string;
  className?: string;
  compact?: boolean;
  weightedMode?: boolean;
}

export const ChartColors: Color[] = [
  Color.Blue,
  Color.Green,
  Color.Red,
  Color.Yellow,
  Color.Turquoise,
  Color.Magenta,
  Color.Purple,
  Color.Brown,
  Color.Beige,
];

export const getChartColorForIndex = (index: number) => ChartColors[index % ChartColors.length];

interface OuterBand {
  top: number;
  bottom: number;
}

interface OuterBands {
  min?: OuterBand;
  max?: OuterBand;
}

export const ReportValueChart: React.FC<Props> = (props) => {

  const { values, titles, className, weightedMode } = props;

  const categories: dayjs.Dayjs[] = useMemo(() => uniqBy(flatMap(values, report => report.map(result => dayjs(result.date))), d => d.valueOf()), [values]);

  const getCategoryIndex = useCallback((value: ReportValue) => {
    return categories.map(category => category.valueOf()).indexOf(dayjs(value.date).valueOf());
  }, [categories]);

  const outerBands: OuterBands = useMemo(() => {

    const result: OuterBands = {};

    if (values.length === 1) {

      const minimum = min(values[0].map(value => parseFloat(value.result)));
      const maximum = max(values[0].map(value => parseFloat(value.result)));

      const minVon = min(values[0].map(value => parseFloat(value.reference.from)));
      const maxBis = max(values[0].map(value => parseFloat(value.reference.to)));

      if (!isNaN(minVon)) {
        result.min = {
          bottom: Math.floor(minimum - max([maximum - minimum, maximum - minVon])),
          top: parseFloat(values[0][0].reference.from),
        };
      }

      if (!isNaN(maxBis)) {
        result.max = {
          top: Math.ceil(maximum + max([maximum - minimum, maxBis - minimum])),
          bottom: parseFloat(values[0][0].reference.to),
        };
      }
    }

    return result;

  }, [values]);

  const createSeries = (values: ReportValue[], name: string) => {

    const getValueColor = (value: ReportValue) => {

      const from = parseFloat(value.reference.from);
      const to = parseFloat(value.reference.to);
      const result = parseFloat(value.result);

      if (!isNaN(from) && result < from) {
        return Color.Red;
      }

      if (!isNaN(to) && result > to) {
        return Color.Red;
      }

      return Color.Green;
    };

    const data = values.filter(value => !isNaN(parseFloat(value.result))).map((value, index) => ({
      value: [
        weightedMode ? dayjs(value.date).valueOf() : getCategoryIndex(value),
        parseFloat(value.result),
      ],
      itemStyle: {
        // color: getCssColor(getValueColor(value)),
        borderWidth: 3,
        borderColor: getCssColor(getValueColor(value)),
      },
    }));

    return {
      data,
      name,
      showInLegend: false,
      lineWidth: 1,
      type: 'line',
      symbolSize: 20,
      lineStyle: {
        width: 6,
        shadowColor: 'rgba(255, 255, 255, 0.8)',
        shadowBlur: 6,
      },
      tooltip: {
        valueFormatter: (value: any) => `${value.toFixed(2)} ${values[0].unit}`,
      },
      markArea: {
        itemStyle: {
          color: getCssColor(Color.Red),
          // opacity: 0.9,
        },
        data: filter([
          outerBands?.min && [{ yAxis: outerBands?.min.bottom }, { yAxis: outerBands?.min.top }],
          outerBands?.max && [{ yAxis: outerBands?.max.bottom }, { yAxis: outerBands?.max.top }],
        ]),
      },
    };
  };

  const options = useMemo(() => ({
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      right: '1rem',
      left: 30,
      top: 0,
      bottom: 30,
      containLabel: true,
      borderColor: getCssVariableValue('--background-color-dark'),
    },
    xAxis: {
      type: weightedMode ? 'time' : 'category' as any,
      data: weightedMode
        ? null
        : categories.map(c => c.format('L LT')),
      min: weightedMode ? null : 0,
      max: weightedMode ? null : categories.length - 1,
      boundaryGap: ['5%', '5%'],
      axisTick: {
        alignWithLabel: !weightedMode,
      },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontFamily: getCssVariableValue('--font-family'),
        fontSize: '13px',
        lineHeight: 22,
      },
    },
    yAxis: {
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false,
        fontFamily: getCssVariableValue('--font-family'),
        fontSize: '14px',
      },
      splitNumber: 10,
      min: outerBands?.min?.bottom,
      max: outerBands?.max?.top,
    },
    series: values.map((v, i) => createSeries(v, titles[i])),
    color: ChartColors.map(getCssColor),
  } as EChartsOption), [values, categories, weightedMode]);

  return (
    <Container grow className={cx('report-chart', className)}>
      <Chart options={options} style={{ width: '100%', height: '100%' }}/>
    </Container>
  );

};

export default ReportValueChart;
