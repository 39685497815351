import React from 'react';
import { useApi } from 'providers';
import { Container, Drawer } from 'components';
import './styles.less';
import { ApiRequest } from 'containers';
import { Toggle } from 'hooks';
import { NotificationList } from 'modules/notifications/components/Lists';
import { NotificationsStore } from 'modules/notifications/providers/Notifications.tsx';

type Props = {
  toggle: Toggle;
};

export default (props: Props) => {

  const { notifications: { listNotifications } } = useApi();

  const [toggled, toggle] = props.toggle;

  return (
    <Drawer
      placement={'right'}
      closable={false}
      onClose={() => toggle(false)}
      open={toggled}
      getContainer={false}
      className={'notification-drawer'}
      width={427}
      rootStyle={{ position: 'absolute' }}
    >
      <ApiRequest
        request={() => listNotifications()}
        bindReload={loadList => NotificationsStore.setState({ loadList })}
        children={({ data, load }) => (
          <Container grow>
            <NotificationList load={load} toggle={toggle} data={data}/>
          </Container>
        )}
      />
    </Drawer>
  );
};
