import React from 'react';
import messages from 'messages';
import { FormItemRenderProps } from 'components/Form/Item';
import { OrdersPreferencesProperties } from 'interfaces/api';

const labels = messages.admin.order.general;

export const BurgerMenuPrioritySwitchesForm: React.ComponentType<FormItemRenderProps<Required<OrdersPreferencesProperties>>> = (props) => {

  const { Switch } = props;

  return (
    <>

      <Switch
        property={'orderWizardShowUrgentSwitch'}
        label={labels.urgentSwitch.label}
        description={labels.urgentSwitch.description}
      />

      <Switch
        property={'orderWizardShowEmergencySwitch'}
        label={labels.emergencySwitch.label}
        description={labels.emergencySwitch.description}
      />

      <Switch
        property={'orderWizardInfectiousSwitch'}
        label={labels.infectiousSwitch.label}
        description={labels.infectiousSwitch.description}
      />

      <Switch
        property={'orderWizardPregnancySwitch'}
        label={labels.pregnancySwitch.label}
        description={labels.pregnancySwitch.description}
      />

      <Switch
        property={'orderWizardCheckupSwitch'}
        label={labels.checkupSwitch.label}
        description={labels.checkupSwitch.description}
      />

      <Switch
        property={'orderWizardShowAccidentSwitch'}
        label={labels.accidentSwitch.label}
        description={labels.accidentSwitch.description}
      />

    </>
  );

};
