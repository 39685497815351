import { useCallback } from 'react';
import { OrderRuleActionType, OrderRuleConditionType } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';

export const useIncompatibleRequirements = () => {

  const { getConditionsByActionType } = useOrderRulesContext();

  return useCallback((shortName: string) => {
    return getConditionsByActionType(OrderRuleConditionType.Requirement, OrderRuleActionType.IncompatibleRequirement, { shortName }).map(c => c.shortName);
  }, [getConditionsByActionType]);

};
