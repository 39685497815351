import React, { memo } from 'react';

export enum HelgaImageType {
  Report = '/images/helga-befund.png',
  Basket = '/images/helga-basket.png',
  MagnifierCovid = '/images/helga-covid.png',
  MagnifierBacterium = '/images/helga-welcheuntersuchung.png',
  SelectAbc = '/images/helga-auswahl-abc.png',
  Question = '/images/helga-frage.png',
  Coffee = '/images/helga-kaffee.png',
  Letter = '/images/helga-kontakt.png',
  QR = '/images/helga-qr.png',
  Number10 = '/images/helga-ziffer-zehn.png',
  Directions = '/images/helga-zweirichtungen.png',
  Orders = '/images/helga-onlineauftrag.png',
}

export const HelgaImage: React.FC<React.ImgHTMLAttributes<HTMLImageElement> & { type: HelgaImageType }> = memo(({ type, ...props }) => (
  <img src={type} alt={'helga image'} {...props}/>
));
