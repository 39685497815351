import React from 'react';
import { Guard, ListLayoutArgs } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { faFileSignature } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { ChooseWorkstationControl } from 'modules/orders/components/ChooseWorkstationControl';
import { useAuthUser } from 'modules/auth/providers';
import { useApi } from 'providers';
import { UserType } from 'interfaces/api';

const labels = messages.orders.sign;

export const Sign = (props: ListLayoutArgs<OrdersListContext>) => {

  const { context: { selected }, bindings: { reloadList } } = props;
  const { entityId: aid } = useAuthUser();
  const { orders: { signOrders } } = useApi();

  return (
    <Guard permitted={[UserType.ARZ]}>
      <ChooseWorkstationControl
        icon={faFileSignature}
        label={labels.label}
        button={{
          type: 'primary',
          disabled: selected.length === 0,
        }}
        notifications={labels.notifications}
        aid={aid}
        onDone={async (apid) => {
          await signOrders({ orderIds: selected.map(s => s.meta.id), workstationId: apid });
          reloadList();
        }}
      />
    </Guard>
  );

};
