import { useEffect, useRef } from 'react';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { useReloadUserDetails } from 'modules/auth/hooks';
import { PushNotifications as CorePushNotifications } from '@capacitor/push-notifications';
import { useEnv } from 'providers/EnvProvider';

export const useAppListeners = () => {

  const isNative = useEnv.isNative();

  const reloadUserDetails = useReloadUserDetails();

  const lastActiveState = useRef<boolean>(false);

  useEffect(() => {

    App.addListener('appStateChange', async ({ isActive }) => {

      if (isActive !== lastActiveState.current) {

        if (isActive) {
          await reloadUserDetails();
          await SplashScreen.hide();
        } else {
          await SplashScreen.show();
        }

        if (isActive && isNative) {
          await CorePushNotifications.removeAllDeliveredNotifications();
        }

        lastActiveState.current = isActive;
      }

    });

    return () => {
      App.removeAllListeners();
    };

  }, []);

};

