import React, { useMemo } from 'react';
import { Translate } from 'providers';
import './IconNav.less';
import cx from 'classnames';
import { Icon, IconProps } from 'components';
import { Color, CssColor, Message } from 'interfaces';
import { Guard, GuardConfig } from 'containers';
import { getCssColor } from 'utils/dom';
import { map } from 'lodash';

export type SideBarIconNavItem = {
  onClick?: () => void;
  color: Color | CssColor | string;
  icon: IconProps;
  title: Message;
  active?: boolean;
  disabled?: boolean;
  guard?: GuardConfig;
  margin?: number;
  className?: any;
};

export type SideBarIconNavProps = {
  items: SideBarIconNavItem[];
  className?: any;
};

const SideBarIconNavItem: React.FC<SideBarIconNavItem> = React.memo((props) => {

  const { active, disabled, onClick, color, title, margin } = props;

  const className = useMemo(() => cx({ 'is-active': active, 'is-disabled': disabled }, props.className), [active, disabled, props.className]);
  const style = useMemo(() => ({ background: getCssColor(color) }), [color]);
  const icon = useMemo(() => <Icon {...props.icon} style={margin ? { marginLeft: `${margin}px` } : undefined}/>, [props.icon, margin]);

  return (
    <li className={className} onClick={onClick}>
      <span style={style}>{icon}</span>
      <span><Translate message={title}/></span>
    </li>
  );

});

export const SideBarIconNav: React.FC<SideBarIconNavProps> = (props) => {

  const className = useMemo(() => cx('icon-nav', props.className), [props.className]);

  return (
    <ul className={className}>
      {map(props.items, (item, idx) => (
        <Guard {...item.guard} key={idx}>
          <SideBarIconNavItem {...item}/>
        </Guard>
      ))}
    </ul>
  );
};
