import React from 'react';
import { Compose } from 'containers/Compose';
import { ReportsConfigProvider } from 'modules/reports/providers/ReportsConfigProvider';
import { useGuard } from 'containers';
import { Product } from 'interfaces/api';

export type ReportsRootProps = {
  children: React.ReactNode;
};

export const ReportsRoot: React.FC<ReportsRootProps> = (props) => {
  const guard = useGuard();
  return (
    <Compose
      components={[guard({ product: Product.OB }, () => ReportsConfigProvider)]}
    >
      {props.children}
    </Compose>
  );
};
