import React from 'react';
import styles from './MibiTable.module.less';
import { Tooltip } from 'components';
import { ReportValue } from 'interfaces/api';
import { groupBy, map, upperFirst } from 'lodash';

type Props = {
  name: string;
  values: ReportValue[];
};

export const MibiTable = (props: Props) => {

  const { name, values } = props;

  const cols = groupBy(values, 'catalog');

  const colHeaders: string[] = [];
  const rowHeaders: string[] = [];

  const mibiTableValues: any = [];

  map(cols, (col, colIdx) => {
    mibiTableValues.push([]);
    map(col, (row, rowIdx) => {
      if (rowIdx === 0) {
        const header = row.meta && row.meta[0] ? row.meta[0] : colIdx + 1;
        colHeaders.push(header);
      } else {
        const idx = rowHeaders.indexOf(row.description);
        if (idx === -1) {
          rowHeaders.push(row.description);
        }
        mibiTableValues[mibiTableValues.length - 1][idx === -1 ? rowHeaders.length - 1 : idx] = row.result;
      }
    });
  });

  return (
    <div className={styles.mibiTable} key={name}>
      <table>
        <thead>
          <tr>
            <th>{upperFirst(name)}</th>
            {colHeaders.map((h, i) => (
              <th key={i}>
                <Tooltip title={h}><span>{h}</span></Tooltip>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowHeaders.map((title, rowIdx) => (
            <tr key={rowIdx}>
              <td>{title}</td>
              {colHeaders.map((r, colIdx) => (<td key={colIdx + '-' + rowIdx}>{mibiTableValues[colIdx][rowIdx]}</td>))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

};
