import Case from './Case';
import Doctor from './Doctor';
import Labor from './Labor';
import LaboratoryGroup from './LaboratoryGroup';
import Order from './Order';
import Patient from './Patient';
import Report from './Report';
import Workstation from './Workstation';
import './styles.less';

export const PopOvers = {
  Case,
  Doctor,
  Labor,
  LaboratoryGroup,
  Order,
  Patient,
  Report,
  Workstation,
};
