import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { Control } from 'components';
import React from 'react';
import messages from 'messages';
import { useOrdersSelectors } from 'modules/orders/providers';
import { useApi } from 'providers';
import { useOnWizardDone } from 'modules/orders/utils';

export const CancelRealRerequestControl = () => {

  const orders = useOrdersSelectors.orders();
  const onWizardDone = useOnWizardDone();

  const { realReRequest: { cancelRealReRequest } } = useApi();

  const labels = messages.orders.controls.cancelReRequest;

  const cancelReRequest = () => new Promise((resolve, reject) => {
    cancelRealReRequest({ orderIds: orders.map(o => o.id) })
      .then(response => onWizardDone(response, resolve))
      .catch(reject);
  });

  return (
    <Control
      icon={faTimesCircle}
      label={labels.label}
      onClick={cancelReRequest}
      notifications={labels.notifications}
      button={{
        danger: true,
        block: true,
      }}
    />
  );

};
