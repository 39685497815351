import React, { useCallback } from 'react';
import { ListLayoutArgs } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { useApi } from 'providers';
import { Control } from 'components';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';

const labels = messages.orders.completion.complete;

export const Complete = (props: ListLayoutArgs<OrdersListContext>) => {

  const { context: { selected } } = props;
  const { completion: { complete } } = useApi();

  const onClick = useCallback(async () => {
    await complete({ order_ids: selected.map(s => s.id as number) });
    props.bindings.reloadList();
  }, [selected]);

  return (
    <Control
      icon={faPaperPlane}
      label={labels.label}
      button={{
        type: 'primary',
        disabled: selected.length === 0,
      }}
      onClick={onClick}
      confirm={labels.confirm}
      notifications={labels.notifications}
    />
  );

};
