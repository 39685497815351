import categories from './categories/messages';
import manufacturers from './manufacturers/messages';
import products from './products/messages';
import general from './general/messages';

export default {
  categories,
  manufacturers,
  products,
  general,
};
