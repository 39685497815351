import { create } from 'zustand';
import { createSelectors } from 'utils/helpers';

export interface ApiState {
  updateAvailable: boolean;
  setUpdateAvailable: (updateAvailable: boolean) => void;
}

export const ApiStore = create<ApiState>()(set => ({
  updateAvailable: false,
  setUpdateAvailable: updateAvailable => set({ updateAvailable }),
}));

export const useApiStoreSelectors = createSelectors(ApiStore).use;
