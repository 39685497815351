import { useAuthStoreSelectors } from 'modules/auth/store';
import { StoreProduct } from 'interfaces/api';
import { useEnv } from 'providers/EnvProvider';
import messages from 'messages';
import { useTranslate } from 'providers';
import { useCallback } from 'react';

export const useProductImageSrc = () => {

  const lid = useAuthStoreSelectors.lid();
  const endpoint = useEnv.endpoint();

  return (product: StoreProduct, thumbnail?: boolean) => product.images?.length > 0
    ? `${endpoint}/api/store/products/${thumbnail ? 'thumbnail' : 'image'}/${product.images[0].id}?lid=${lid}`
    : undefined;

};

export const useTranslateProductUnits = () => {
  const translate = useTranslate();
  return useCallback((product: StoreProduct) => {
    return `${product.unitAmount} ${translate(messages.admin.store.products.fields.unitTypes[product.unitType])}`;
  }, [translate]);
};
