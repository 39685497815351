import { isWeb } from 'utils/device';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

const setter = async (key: string, value: string, secureOnly?: boolean) => {

  if (await isWeb() && secureOnly) {
    return undefined;
  }

  try {
    return SecureStoragePlugin.set({ key, value });
  } catch (e) {
    await SecureStoragePlugin.remove({ key });
    return SecureStoragePlugin.set({ key, value });
  }
};

const getter = async (key: string) => {
  try {
    return (await SecureStoragePlugin.get({ key })).value;
  } catch (e) {
    return undefined;
  }
};

const remover = async (key: string): Promise<void> => {
  try {
    await SecureStoragePlugin.remove({ key });
  } catch (e) { /* empty */
  }
};

export const setIdentifier = (identifier: string) => setter('lab7-identifier', identifier, true);
export const getIdentifier = () => getter('lab7-identifier');

export const setToken = (token: string) => setter('lab7-token', token, true);
export const getToken = () => getter('lab7-token');
export const removeToken = () => remover('lab7-token');

export const setFaceIdLoginRequested = () => setter('lab7-face-id-requested', 'true');
export const getFaceIdLoginRequested = () => getter('lab7-face-id-requested');
export const removeFaceIdLoginRequested = () => remover('lab7-face-id-requested');

export const setLastUsedWorkstation = (id: number) => setter('lab7-last-used-workstation-id', id + '');
export const getLastUsedWorkstation = () => getter('lab7-last-used-workstation-id');
export const removeLastUsedWorkstation = () => remover('lab7-last-used-workstation-id');
