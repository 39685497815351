import React, { useCallback, useMemo } from 'react';
import { AnyFunction } from 'utils/helpers';
import { useAuthUser } from 'modules/auth/providers';
import { Feature, FeatureProperties, Platform, PreferencesProperties, Product, ProductProperties, UserPermission, UserPermissionsProperties, UserType } from 'interfaces/api';
import { find, isFunction } from 'lodash';
import { useEnv } from 'providers/EnvProvider';

type UserTypeCheck = UserType | UserType[];
type UserTypePermission = {
  [key in UserType]?: UserPermission;
};

type PreferenceCheckFunction = (preferences: PreferencesProperties) => boolean;
type PreferenceCheck = keyof PreferencesProperties | PreferenceCheckFunction;

export type GuardConfig = {
  permitted?: UserTypeCheck;
  forbidden?: UserTypeCheck;
  product?: Product;
  feature?: Feature;
  userPermission?: UserTypePermission;
  preference?: PreferenceCheck;
};

const checkUserType = (needle: UserType, hay: UserTypeCheck) => {
  if (typeof hay === 'number' && hay !== needle) {
    return false;
  } else if (hay instanceof Array && hay.indexOf(needle) === -1) {
    return false;
  }
  return true;
};

const guardCheck = (
  config: GuardConfig,
  userType: UserType,
  products: ProductProperties[],
  features: FeatureProperties[],
  userPermissions: UserPermissionsProperties,
  platform: Platform,
  preferences: PreferencesProperties,
) => {

  const { permitted, forbidden, product, feature, userPermission, preference } = config;

  if (permitted && !checkUserType(userType, permitted)) {
    return false;
  }

  if (forbidden && checkUserType(userType, forbidden)) {
    return false;
  }

  // check product enabled
  if (product) {
    const checkProduct = (find(products, { key: product }) || {});
    if (!checkProduct.enabled || (!checkProduct.appEnabled && platform !== Platform.WEB)) {
      if (!(checkProduct.key === Product.ADMIN && [UserType.ADM, UserType.SAD].includes(userType))) {
        return false;
      }
    }
  }

  // check feature enabled
  if (feature && !(find(features, { key: feature }) || {}).enabled) {
    return false;
  }

  // check user permission does match
  if (userPermission?.[userType]) {
    return userPermissions?.[userPermission[userType]];
  }

  // check user preferences
  if (preference) {
    return isFunction(preference) ? preference(preferences) : !!preferences[preference];
  }

  return true;
};

export const Guard: React.FC<GuardConfig & { children?: React.ReactNode }> = ({ children, ...config }) => {
  const guard = useGuard();
  const isPermitted = useMemo(() => guard(config, () => true), [config, guard]);
  return isPermitted ? children : null;
};

export type GuardFunction = <T extends AnyFunction>(config: GuardConfig, callback: T) => ReturnType<T>;

export const useGuard = (): GuardFunction => {

  const platform = useEnv.platform();
  const user = useAuthUser();

  return useCallback((config, callback) => {

    if (!user) {
      return null;
    }

    const { products, features, permissions, preferences } = user;
    const { permitted, forbidden, product, feature, userPermission, preference } = config;
    const hasPermissions = guardCheck({ permitted, forbidden, product, feature, userPermission, preference }, user.type, products, features, permissions, platform, preferences);

    if (hasPermissions) {
      return callback();
    } else {
      return null;
    }

  }, [user?.id, user?.products, user?.features]);

};
