import React, { useEffect } from 'react';
import { DevicesCreateDeviceTokenResponse, Platform } from 'interfaces/api';
import { getToken, removeFaceIdLoginRequested, removeToken, setFaceIdLoginRequested, setToken } from 'providers/StorageProvider';
import { FaceIDRequest } from './FaceIDRequest';
import { useEnv } from 'providers/EnvProvider';
import { BIOMETRIC_TYPE, FingerprintAIO, FingerprintOptions } from '@awesome-cordova-plugins/fingerprint-aio';
import { useLogger } from 'providers/LoggerProvider';
import { create } from 'zustand';
import { createSelectors } from 'utils/helpers';

export interface BiometricsState {
  available: BIOMETRIC_TYPE;
  setAvailable: (available: BIOMETRIC_TYPE) => void;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  faceIdRequestVisible: boolean;
  setFaceIdRequestVisible: (faceIdRequestVisible: boolean) => void;
  enable: (createDeviceToken: () => Promise<DevicesCreateDeviceTokenResponse>) => Promise<void>;
  disable: () => Promise<void>;
  show: (options: FingerprintOptions) => Promise<void>;
  showFaceIdRequest: () => Promise<void>;
  hideFaceIdRequest: () => Promise<void>;
}

export const BiometricsStore = create<BiometricsState>()((set, get) => ({
  available: undefined,
  setAvailable: available => set({ available }),
  enabled: undefined,
  setEnabled: enabled => set({ enabled }),
  faceIdRequestVisible: undefined,
  setFaceIdRequestVisible: faceIdRequestVisible => set({ faceIdRequestVisible }),
  enable: async (createDeviceToken: () => Promise<DevicesCreateDeviceTokenResponse>) => {
    try {
      console.debug('enabling biometrics');
      await get().show({});
      console.debug('biometrics received, receive token');
      const token = await createDeviceToken();
      console.debug('token received', token);
      await setToken(token.token);
      get().setEnabled(true);
    } catch (e) {
      get().setEnabled(false);
      console.error('biometrics not activated', e);
    }
  },
  disable: async () => {
    await removeToken();
    await removeFaceIdLoginRequested();
    get().setEnabled(false);
  },
  show: options => new Promise((resolve, reject) => {
    if (get().available !== undefined) {
      console.debug('showing biometrics');
      FingerprintAIO
        .show(options)
        .then((result) => {
          console.debug('show biometrics success', result);
          resolve(result);
        })
        .catch((error) => {
          console.error('show biometrics error', error);
          reject(error);
        });
    } else {
      console.warn('biometrics not available');
    }
  }),
  showFaceIdRequest: async () => {
    if (get().available !== undefined && !await getToken()) {
      get().setFaceIdRequestVisible(true);
      await setFaceIdLoginRequested();
    }
  },
  hideFaceIdRequest: async () => {
    get().setFaceIdRequestVisible(false);
    await setFaceIdLoginRequested();
  },
}));

export const useBiometricsStoreSelectors = createSelectors(BiometricsStore).use;

export const BiometricsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const platform = useEnv.platform();

  const available = useBiometricsStoreSelectors.available();
  const setAvailable = useBiometricsStoreSelectors.setAvailable();
  const setEnabled = useBiometricsStoreSelectors.setEnabled();

  const logger = useLogger('BiometricsProvider');

  useEffect(() => {
    if (platform !== Platform.WEB) {
      FingerprintAIO.isAvailable().then(setAvailable).catch((reason) => {
        logger.warn('biometrics not available', reason);
        setAvailable(undefined);
      });
    }
  }, []);

  useEffect(() => {
    if (available !== undefined) {
      getToken().then(credentials => setEnabled(!!credentials)).catch(logger.error);
    }
  }, [available]);

  return (
    <FaceIDRequest>
      {children}
    </FaceIDRequest>
  );
};
