import React, { ImgHTMLAttributes } from 'react';
import styles from './Images.module.less';
import cx from 'classnames';

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> | string;

export type ImagesProps = {
  items: ImageProps[];
};

export const Images = React.memo((props: ImagesProps) => {

  const { items } = props;

  return (
    <div className={cx(styles.container, { [styles.multiImages]: items.length > 1 })}>
      {items.map((item, idx) => (
        <img key={idx} {...(typeof item === 'string' ? { src: item } : item)}/>
      ))}
    </div>
  );

});
