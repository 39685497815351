import React, { useRef } from 'react';
import { AnamnesisProvider, OfficeDoctorProvider, OrdersListLayoutBindingsContext, OrdersProvider } from 'modules/orders/providers';
import { OrderRulesProvider } from 'modules/orders/providers/OrderRulesProvider';
import { OrderRuleModal } from 'modules/orders/components/OrderRuleModal';
import { withSuspense } from 'hocs';
import { createListLayoutBindingsStore } from 'containers';
import { Compose } from 'containers/Compose';

const OrderWizard = withSuspense(React.lazy(() => import('../OrderWizard')));

export type OrderRootProps = {
  children: React.ReactNode;
};

export const OrdersRoot: React.FC<OrderRootProps> = (props) => {

  const listRefStore = useRef(createListLayoutBindingsStore());

  return (
    <Compose
      components={[
        [OrdersListLayoutBindingsContext.Provider, { value: listRefStore.current }],
        OfficeDoctorProvider,
        OrdersProvider,
        OrderRulesProvider,
        AnamnesisProvider,
      ]}
    >

      {props.children}

      <OrderWizard/>

      <OrderRuleModal/>

    </Compose>
  );

};
