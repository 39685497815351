import React from 'react';
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons';
import { useApi } from 'providers';
import { ChooseWorkstationControl } from 'modules/orders/components/ChooseWorkstationControl';
import messages from 'messages';

type Props = {
  orderId: number;
  aid: number;
  apid?: number;
  barcodeNumber: string;
  probeType: string;
  reload?: () => void;
};

const labels = messages.orders.controls.expendExtraDynamicBarcode;

export const ExpendExtraDynamicBarcode = (props: Props) => {

  const { print: { expendExtraBarcode } } = useApi();
  const { aid, orderId, barcodeNumber, probeType, reload } = props;

  return (
    <ChooseWorkstationControl
      icon={faBarcodeScan}
      label={labels.label}
      notifications={labels.notifications}
      aid={aid}
      defaultSelected={props.apid}
      onDone={async (apid) => {
        await expendExtraBarcode({ aid, barcodeNumber, sampleType: probeType, id: orderId, workstation: apid });
        reload?.();
      }}
    />
  );
};
