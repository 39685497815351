import { MessageThreadType } from 'interfaces/api';

export default {
  title: 'Messages',
  listHeading: 'Message Center',
  controls: {
    deleteThread: {
      label: 'Delete Thread',
      notifications: {
        success: 'Deleted thread successfully',
      },
      confirm: {
        title: 'Do you really want to delete this thread?',
        content: 'You cannot revert this action',
      },
    },
    starThread: {
      label: 'Star Thread',
      labelUnstar: 'Unstar Thread',
      notifications: {
        success: 'Starred thread successfully',
      },
      notificationsUnstar: {
        success: 'Unstarred thread successfully',
      },
    },
  },
  header: {
    generalMessage: 'General message',
    orderMessage: 'Message for order',
    reportMessage: 'Message for report',
  },
  read: 'read',
  subject: 'Subject',
  messageFieldTypeHere: 'Type message',
  newMessage: {
    title: 'New Message',
    send: 'Send',
    chooseRecipient: 'Choose recipient',
    chooseType: 'Choose message type',
  },
  type: {
    title: 'Message type',
  },
  appointment: {
    description: 'Sent a courier service request for {DATE}.',
    confirm: 'Confirm',
    deny: 'Deny',
    confirmed: 'Request was confirmed',
    denied: 'Request was denied',
  },
  specialMessageTitle: {
    [MessageThreadType.News]: 'News by your laboratory',
  },
  filter: {
    label: 'Message type',
    types: {
      [MessageThreadType.General]: 'General',
      [MessageThreadType.Report]: 'Report',
      [MessageThreadType.Order]: 'Order',
      [MessageThreadType.Store]: 'Store',
      [MessageThreadType.CourierService]: 'Courier service',
      [MessageThreadType.News]: 'News',
    },
  },
};
