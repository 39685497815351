import React from 'react';
import { Container, ModalFormControl } from 'components';
import { ReportControlContext } from 'modules/reports/index';
import { useApi, useTranslate } from 'providers';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { ReportsContactLaboratoryRequest } from 'interfaces/api';
import messages from 'messages';
import { useAuthUser } from 'modules/auth/providers';

const labels = messages.reports.controls.contactLabor;

export const ContactLaboratory = (props: ReportControlContext) => {

  const user = useAuthUser();
  const translate = useTranslate();
  const { reports: { contactLaboratory } } = useApi();

  const { data: { bid } } = props;

  return (
    <ModalFormControl
      icon={faEnvelope}
      label={labels.label}
      notifications={labels.notifications}
      modal={{
        okText: messages.general.send,
      }}
      form={{
        initialValue: {} as ReportsContactLaboratoryRequest,
        request: value => contactLaboratory({ bid, ...value }),
        children: ({ Input, TextArea, Switch }) => (
          <Container padding>
            <Input property={'subject'} label={labels.subject}/>
            <TextArea property={'message'} label={labels.message}/>
            <Switch property={'copyToUser'} label={translate(labels.copyToUser, { email: user?.email })}/>
          </Container>
        ),
      }}
    />
  );

};
