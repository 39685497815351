export enum Breakpoint {
  MobileOnly = 'mobileOnly',
  TabletOnly = 'tabletOnly',
  TabletDown = 'tabletDown',
  TabletUp = 'tabletUp',
  DesktopOnly = 'desktopOnly',
  DesktopDown = 'desktopDown',
  DesktopUp = 'desktopUp',
  DesktopXl = 'desktopXl',
}
