import React from 'react';
import messages from 'messages';
import { FormItemRenderProps } from 'components/Form/Item';
import { OrdersPreferencesProperties } from 'interfaces/api';

const labels = messages.admin.order.general;

export const UrgentNotficationPreferencesForm: React.ComponentType<FormItemRenderProps<Required<OrdersPreferencesProperties>>> = (props) => {

  const { Switch } = props;

  return (
    <>

      <Switch
        property={'orderWizardUrgentNotificationModeCall'}
        label={labels.urgentNotificationSettings.mode.call}
      />

      <Switch
        property={'orderWizardUrgentNotificationModeFax'}
        label={labels.urgentNotificationSettings.mode.fax}
      />

      <Switch
        property={'orderWizardUrgentNotificationModeEmail'}
        label={labels.urgentNotificationSettings.mode.email}
      />

    </>
  );

};
