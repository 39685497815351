import React, { useMemo, useState } from 'react';
import styles from './MaintenanceNotice.module.less';
import { Control, Icon, Tooltip } from 'components';
import { faClose, faWrench, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Color } from 'interfaces';
import { useApi, useConfig, useSetConfig } from 'providers';

const MaintenanceNotice = () => {

  const { system } = useConfig();
  const setConfig = useSetConfig();

  const {
    globals: { updateConfig },
  } = useApi();

  const { maintenance, acceptedMaintenanceAt } = system;

  const [hidden, setHidden] = useState(!maintenance?.enabled || acceptedMaintenanceAt);

  const onClose = async (): Promise<void> => {
    await updateConfig({ system: { acceptedMaintenanceAt: true } });
    setConfig({ system: { acceptedMaintenanceAt: true } });
    setHidden(true);
  };

  const messageToolTip = useMemo(() => {
    return (
      <Tooltip title={maintenance.message} placement={'bottom'} className={styles.tooltip}>
        <Icon icon={faInfoCircle} color={Color.White}/>
      </Tooltip>
    );
  }, [maintenance]);

  return (
    !hidden
      ? (
        <div id='maintenanceNotice' hidden={hidden} className={styles.container}>
          <Icon className={styles.icon} icon={faWrench} color={Color.Smoke}/>
          <div className={styles.title}>{maintenance.title}</div>
          <div className={styles.text}>{maintenance.message}</div>
          {messageToolTip}
          <Control
            className={styles.close}
            icon={{ icon: faClose, color: Color.White }}
            tooltip
            onClick={onClose}
          />
        </div>
      )
      : null
  );
};

export default MaintenanceNotice;
