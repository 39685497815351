import React, { RefObject } from 'react';
import { Container, List, ListItem, ListProps } from 'components';
import { ApiRequest, ApiRequestUpdateBinding, ErrorBoundary, InfiniteScroll, ListLayoutArgs, ListLayoutContext, ListLayoutUpdater, ResponseType } from 'containers';
import { ApiRequestConfig, PaginationResponse } from 'providers';
import './ListLayoutList.less';
import { isPaginatedResponse } from 'utils/helpers';
import cx from 'classnames';
import { findIndex } from 'lodash';

export type ListLayoutCustomListProps = Omit<ListProps, 'items'> & {
  withSelection?: boolean | { multi?: boolean; defaultSelectAll?: boolean };
};

export type ListLayoutTransformRequest<Context, Request> = (args: ListLayoutArgs<Context>) => Partial<Request>;
export type ListLayoutTransformResponse<Context, Response> = (entity: Response, context?: Context) => ListItem;
export type ListLayoutTransformListProps<Context> = (args: ListLayoutArgs<Context>, listProps: ListLayoutCustomListProps) => ListLayoutCustomListProps;

export type ListLayoutListProps<Context extends ListLayoutContext, Response, Request> = {
  request: (data?: Request, config?: ApiRequestConfig) => Promise<PaginationResponse<Response> | Response[]>;
  onLoaded?: (data: PaginationResponse<Response> | Response[], context?: Context, listProps?: ListLayoutCustomListProps) => void;
  transformRequest?: ListLayoutTransformRequest<Context, Request>;
  transformResponse: ListLayoutTransformResponse<Context, Response>;
  bindUpdateItem?: (bind: ListLayoutUpdater) => void;
  bindReload?: (binding: () => void) => void;
  bindGetData?: (binding: () => PaginationResponse<ListItem[]>) => void;
  transformListProps?: ListLayoutTransformListProps<Context>;
  virtualized?: boolean;
  renderListContainer?: (listElement: React.ReactNode) => React.ReactNode;
} & ListLayoutCustomListProps;

type Props<Context, Response, Request> = ListLayoutListProps<Context, Response, Request> & ListLayoutArgs<Context>;

export class ListLayoutList<Context extends ListLayoutContext, Response, Request> extends React.PureComponent<Props<Context, Response, Request>> {

  // static whyDidYouRender = true;

  _reload: () => void;

  _getData: () => PaginationResponse<any>;

  _updateData: ApiRequestUpdateBinding<ResponseType<any>>;

  listRef: RefObject<any>;

  _mergedListProps: ListLayoutCustomListProps;

  constructor(props: Props<Context, Response, Request>) {
    super(props);

    this.listRef = React.createRef();
  }

  componentDidMount() {
    const { bindReload, bindUpdateItem, bindGetData } = this.props;
    bindReload?.(() => this._reload());
    bindGetData?.(() => this.getData());
    bindUpdateItem?.(this.updateItem);
  }

  getData = () => {
    return this._getData();
  };

  updateItem = (id: number | string, item: any) => {
    this._updateData((data) => {

      if (data) {

        const index = findIndex(data.results, { id });
        let results = undefined;

        if (item) {
          item = this.props.transformResponse(item, this.props.context);
          results = (index > -1) ? { ...data.results[index], ...item } : item;
        }

        data.results.splice(index, 1, results);
        data.results = data.results.filter((d: any) => !!d);

      }

      return data;
    });
  };

  getResults = (data: PaginationResponse<Response> | Response[]) => {
    return isPaginatedResponse(data) ? data.results : data;
  };

  request = (offset: number) => {
    const { context, bindings, request, transformRequest } = this.props;
    const requestArgs = transformRequest ? transformRequest({ context, bindings }) : context.filters;
    return request({ offset, ...requestArgs });
  };

  onLoaded = (data: PaginationResponse<Response> | Response[]) => {

    const { onLoaded } = this.props;
    const { withSelection } = this._mergedListProps;

    const context = {} as Context;

    const results = this.getResults(data);

    if (withSelection && (withSelection as any).defaultSelectAll) {
      context.selected = results as any;
    }
    context.data = results as any;
    this.props.bindings.setContext(context);

    onLoaded?.(data, context, this._mergedListProps);
  };

  bindUpdateData = (updateData: ApiRequestUpdateBinding<ResponseType<any>>) => {
    this._updateData = updateData;
  };

  bindReload = (reload: () => void) => {
    this._reload = reload;
  };

  onItemSelect = (item: ListItem) => {
    const { bindings } = this.props;
    return bindings.onSelect(item, (this._mergedListProps.withSelection as any).multi);
  };

  transformResponse = (response: PaginationResponse<Response> | Response[]) => {
    const { context, transformResponse } = this.props;
    return {
      ...response,
      results: (this.getResults(response) || []).map(item => transformResponse(item, context)) as any,
    };
  };

  renderListContainer = (el: React.ReactNode) => {
    return this.props.renderListContainer?.(el) || el;
  };

  render() {

    const { context, bindings, transformRequest, onLoaded, transformResponse, transformListProps, bindUpdateItem, virtualized, ...listProps } = this.props;

    this._mergedListProps = transformListProps ? transformListProps({ context, bindings }, listProps) : listProps;

    if (this._mergedListProps.withSelection) {
      this._mergedListProps.checkboxes = listProps.checkboxes !== undefined ? listProps.checkboxes : true;
      this._mergedListProps.selected = this._mergedListProps.selected || context.selected;
      this._mergedListProps.onSelect = this._mergedListProps.onSelect || this.onItemSelect;
    }

    return (
      <Container ref={this.listRef} grow shrink scrollY translateZero className={'list-layout-list-scroller'}>
        <ErrorBoundary>
          <ApiRequest
            key={JSON.stringify(transformRequest ? transformRequest({ context, bindings }) : context.filters)}
            request={this.request}
            onLoaded={this.onLoaded}
            bindReload={this.bindReload}
            bindUpdateData={this.bindUpdateData}
            transformResponse={this.transformResponse}
            children={({ data, next }) => (
              <InfiniteScroll onNext={next}>
                {this.renderListContainer((
                  <List
                    items={data?.results}
                    {...this._mergedListProps}
                    className={cx('list-layout-list', listProps.className)}
                  />
                ))}
              </InfiniteScroll>
            )}
          />
        </ErrorBoundary>
      </Container>
    );
  }

}
