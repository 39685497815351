import React, { useCallback } from 'react';
import { Control } from 'components';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { ReportControlContext } from '../../../index';

const labels = messages.reports.controls.deleteReport;

export const Delete: React.FC<ReportControlContext> = (props) => {

  const { data: { bid }, bindings: { updateItem, setListView } } = props;

  const { reports: { deleteReport } } = useApi();

  const onClick = useCallback(async () => {
    await deleteReport({ bid });
    updateItem(bid);
    setListView();
  }, [bid, updateItem, setListView, deleteReport]);

  return (
    <Control
      icon={faTrash}
      label={labels.label}
      confirm={labels.confirm}
      notifications={labels.notifications}
      onClick={onClick}
    />
  );

};
