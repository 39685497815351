import { useCallback } from 'react';
import { Report, ReportMibiType, ReportStatus } from 'interfaces/api';
import { Color } from 'interfaces';

export const useGetListItemFlag = () => {
  return useCallback((report: Report): Color[] => {
    if ([
      ReportStatus.Preliminary,
      ReportStatus.SpecimenReceived,
      ReportStatus.OrderReceived,
      ReportStatus.IDEXXXCertificateFinal,
      ReportStatus.IDEXXCertificateRequest,
    ].indexOf(report.status) >= 0) {
      return [Color.Silver];
    }

    if (report.status === ReportStatus.Partial) {
      if (report.mibi === ReportMibiType.None) {
        return [Color.Yellow];
      } else {
        return [Color.Yellow, Color.DarkGray];
      }
    }

    if (report.mibi === ReportMibiType.Only) {
      return [Color.DarkGray];
    } else if (report.mibi === ReportMibiType.Mixed) {
      return [report.pathological ? Color.Red : Color.Green, Color.DarkGray];
    }

    return [report.pathological ? Color.Red : Color.Green];
  }, []);
};
