import React from 'react';
import { Workstation, WorkstationStatus } from 'interfaces/api';
import { useApi, useApiCall, useConfig } from 'providers';
import { groupBy, keys, map } from 'lodash';
import { Icon, Select, SelectProps } from 'components';
import { Color, Message } from 'interfaces';
import messages from './messages.ts';
import { WorkstationGroup } from './interfaces.ts';
import { faDesktopSlash, faDesktop } from '@fortawesome/pro-thin-svg-icons';

type Props = {
  aid?: number;
  workstations?: Workstation[];
  placeholder?: Message;
  labelWithId?: boolean;
} & SelectProps<any>;

export const WorkstationSelect = (props: Props) => {

  const { workstations: { listWorkstations } } = useApi();

  const { aid, workstations: initialWorkstations, labelWithId, ...rest } = props;

  const loadWorkstations = useApiCall(() => listWorkstations({ aid }));

  const workstations = initialWorkstations || loadWorkstations.data;

  const { preferences } = useConfig();

  const groupedWorkstations = workstations
    ? groupBy(workstations
      .filter((({ status }) => !preferences.orderWizardHideOfflineWorkstations || status !== WorkstationStatus.Disconnected))
    , (workstation) => {
      if (workstation.clientVersion === 'manuell') {
        return WorkstationGroup.Manual;
      } else if (workstation.status === WorkstationStatus.Disconnected) {
        return WorkstationGroup.Disconnected;
      } else {
        return WorkstationGroup.Connected;
      }
    })
    : undefined;

  return (
    <Select
      {...rest}
      popupMatchSelectWidth={false}
      loading={loadWorkstations.loading}
      optionGroup={
        groupedWorkstations
          ? map(keys(groupedWorkstations).sort().reverse(), (workstationStatus: WorkstationGroup) => ({
            label: messages.status[workstationStatus],
            options: groupedWorkstations[workstationStatus].map(workstation => ({
              value: workstation.id,
              children: (
                <>
                  <Icon icon={workstation.status === WorkstationStatus.Disconnected ? faDesktopSlash : faDesktop} color={Color.Gray}/>
                  {` ${workstation.name}` + (labelWithId ? ` (${workstation.id})` : '')}
                </>
              ),
            })),
          }))
          : undefined
      }
    />
  );
};
