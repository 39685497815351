import React, { useEffect } from 'react';
import { faExclamationCircle, faTimes, faUserEdit, faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import styles from './styles.module.less';
import messages from 'messages';
import { Container, Control, Icon, List, ListItem, Popover } from 'components';
import { getGenderIcon } from 'modules/reports/utils';
import { FormatDate, Translate, useTranslate } from 'providers';
import { OrderPatientMetaProperties, WriteableOrderProperties } from 'interfaces/api';
import { PatientFormModalControl } from 'modules/orders/containers/OrderWizard/components/PatientForm';
import { useToggle } from 'hooks';
import { useCurrentOrder, useMandatoryFields, useOfficeDoctorSelectors, useOrdersSelectors, useValidPatientsCount } from 'modules/orders/providers';
import { find } from 'lodash';
import cx from 'classnames';
import { Color } from 'interfaces';
import { isOrderReadonly, sortOrders } from 'modules/orders/utils';

const PatientMeta = (props: { order: WriteableOrderProperties }) => {

  const { patient: { gender, birthday, exttnr, medication }, costUnit } = props.order;
  const { orderWizardShowExttnr, orderWizardShowExttnrLastUnderline, orderWizardPatientTopBarFindingsMedications } = useOfficeDoctorSelectors.wizardSettings()?.preferences || {};

  return (
    <span className={cx('patient-meta', styles.meta)}>
      <Icon icon={getGenderIcon(gender)}/>
      <FormatDate date={birthday} options={{ dateOnly: true }}/>
      {orderWizardShowExttnr && exttnr?.length && `, ${orderWizardShowExttnrLastUnderline ? exttnr.split('_').pop() : exttnr}`}
      {orderWizardPatientTopBarFindingsMedications && medication?.length && `, ${medication}`}
      {costUnit && `, ${costUnit}`}
    </span>
  );

};

export const PatientDisplayName = (props: OrderPatientMetaProperties) => {

  const translate = useTranslate();

  if (props?.displayName?.length > 0) {
    return props.displayName;
  } else if (props?.lastName?.length > 0 && props?.firstName?.length > 0) {
    return props.lastName + ' ' + props.firstName;
  } else {
    return translate(messages.orders.selectPatient);
  }
};

const PatientLabel: React.FC = () => {

  const translate = useTranslate();

  const validPatientsCount = useValidPatientsCount();

  const orders = useOrdersSelectors.orders();
  const removeOrderAtIndex = useOrdersSelectors.removeOrderAtIndex();
  const currentOrder = useCurrentOrder();

  const selectedOrderIndex = useOrdersSelectors.selectedOrderIndex();
  const selectOrderAtIndex = useOrdersSelectors.selectOrderAtIndex();

  const { showPatientWarningBadgeForPoolOrder } = useMandatoryFields();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const [showPopover, toggleShowPopover] = useToggle(false);

  if (validPatientsCount === 0) {
    return <Translate message={messages.orders.editPatient}/>;
  } else if (validPatientsCount === 1 && orders.length === 1) {
    return (
      <>
        <PatientMeta order={currentOrder}/>
        <PatientDisplayName {...currentOrder.patient}/>
      </>
    );
  } else {

    const poolLabels = messages.orders.patients.pool;

    const items: ListItem[] = sortOrders(orders, wizardSettings?.preferences?.orderSortPatientsDropdownByName).map(({ order, idx }) => ({
      id: idx + '',
      title: (
        <>
          {showPatientWarningBadgeForPoolOrder(order) ? <Icon icon={faExclamationCircle} color={Color.Alert}/> : undefined}
          <PatientDisplayName {...order.patient}/>
        </>
      ),
      subtitle: <PatientMeta order={order}/>,
    }));

    const selected = selectedOrderIndex !== undefined ? [find(items, { id: selectedOrderIndex + '' })] : [];

    const getControlLabel = () => {

      if (selectedOrderIndex === undefined) {
        return translate(poolLabels.label, { COUNT: orders.length });
      }

      return (
        <>
          <span className={styles.meta}>
            {translate(poolLabels.label, { COUNT: orders.length })}
          </span>
          <PatientDisplayName {...orders[selectedOrderIndex].patient}/>
        </>
      );
    };

    return (
      <Container grow center>
        <Popover
          placement={'bottomLeft'}
          open={showPopover}
          onOpenChange={toggleShowPopover}
          content={(
            <Container grow shrink scrollY className={styles.popoverPatientSelectContainer} onClick={e => e.stopPropagation()}>
              <List
                checkboxes
                onSelect={(item) => {
                  selectOrderAtIndex(parseInt(item.id + '') === selectedOrderIndex ? undefined : parseInt(item.id + ''));
                }}
                selected={selected}
                items={items}
                className={{ item: styles.popoverPatientSelectListItem }}
                getControls={item => [(
                  <PatientFormModalControl
                    icon={faUserEdit}
                    key={'edit'}
                    index={parseInt(item.id + '')}
                    onClick={() => toggleShowPopover(false)}
                  />
                ), (
                  <Control key={'remove'} icon={faTimes} onClick={() => removeOrderAtIndex(parseInt(item.id + ''))}/>
                )]}
              />
            </Container>
          )}
        >
          <Container grow style={{ justifyContent: 'center' }}>
            {getControlLabel()}
          </Container>
        </Popover>
      </Container>
    );

  }

};

export const SelectPatientsControl = () => {

  const showPatientSelectModal = useOrdersSelectors.showPatientSelectModal();
  const setShowPatientSelectModal = useOrdersSelectors.setShowPatientSelectModal();

  const currentOrder = useCurrentOrder();
  const readonly = isOrderReadonly(currentOrder);

  const orders = useOrdersSelectors.orders();
  const selectedOrderIndex = useOrdersSelectors.selectedOrderIndex();

  const { showPatientWarningBadge } = useMandatoryFields();

  const hasSelected = orders.length === 1 || selectedOrderIndex !== undefined;

  useEffect(() => {
    if (showPatientSelectModal) {
      setShowPatientSelectModal(false);
    }
  });

  return (
    <PatientFormModalControl
      icon={hasSelected ? faUserEdit : faUserGroup}
      alertBadge={showPatientWarningBadge}
      label={<PatientLabel/>}
      className={styles.patient}
      disabled={!hasSelected}
      readonly={readonly}
      modal={{
        initialVisible: showPatientSelectModal,
      }}
    />
  );

};
