import React, { useCallback, useEffect, useRef } from 'react';
import { Container, ContainerProps } from 'components';

export const DraggableContainer: React.FC<ContainerProps> = (props) => {

  const containerRef = useRef<HTMLDivElement>();
  const dragging = useRef<boolean>(false);
  const lastClientX = useRef<number>(0);
  const lastClientY = useRef<number>(0);

  const mouseUpHandle = useCallback((event: any) => {
    if (dragging.current) {
      dragging.current = false;
    }
  }, []);

  const mouseDownHandle = useCallback((event: any) => {
    if (!dragging.current) {
      dragging.current = true;
      lastClientX.current = event.clientX;
      lastClientY.current = event.clientY;
      event.preventDefault();
    }
  }, []);

  const mouseMoveHandle = useCallback((event: any) => {
    if (dragging.current) {
      containerRef.current.scrollLeft -= (-lastClientX.current + (lastClientX.current = event.clientX));
      containerRef.current.scrollTop -= (-lastClientY.current + (lastClientY.current = event.clientY));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', mouseUpHandle);
    window.addEventListener('mousemove', mouseMoveHandle);
    return () => {
      window.removeEventListener('mouseup', mouseUpHandle);
      window.removeEventListener('mousemove', mouseMoveHandle);
    };
  }, []);

  return (
    <Container
      ref={containerRef}
      onMouseUp={mouseUpHandle}
      onMouseMove={mouseMoveHandle}
      onMouseDown={mouseDownHandle}
      {...props}
    />
  );

};
