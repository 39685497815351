import React from 'react';
import { Workstation } from 'interfaces/api';
import { Card } from 'components/Card';

interface Props {
  workstation: Workstation;
}

export default React.memo((props: Props) => {
  const { name, location } = props.workstation;

  return (
    <Card className={'info-popover'} title={name}>
      {location}
    </Card>
  );
});
