import React, { useCallback, useMemo } from 'react';
import { ListItem } from 'components/List';
import {
  AggregatedProfileProperties,
  AggregatedRequirementFlags,
  OrderRequirementsFilter,
  OrderRequirementsSpecialFilterId,
  OrderWizardRequirement,
  OrderWizardSettings,
  RequirementAggregationType,
} from 'interfaces/api';
import { TooltipPlacement } from 'antd/es/tooltip';
import { RequirementPopOver } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver';
import { PopoverProps } from 'components/Popover';
import { useCurrentOrder, useOfficeDoctorSelectors } from 'modules/orders/providers';
import dayjs from 'dayjs';
import { filter, find, keys, uniq } from 'lodash';
import { OrderAggregationConfig } from 'modules/orders/interfaces';
import { OrderAggregations } from 'modules/orders/constants';
import { faBug, faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { Message } from 'interfaces';
import messages from 'messages';
import { faCrown, faQuestionCircle, faStar } from '@fortawesome/pro-solid-svg-icons';
import { Translate, useTranslate } from 'providers';
import { getLongDateFormat, secondsToInterval } from 'utils/helpers';
import { RankIcon } from 'modules/orders/containers/OrderWizard/components';
import { getRequirementPrice, getRequirementShortName } from 'modules/orders/utils';
import { Tag } from 'components';
import cx from 'classnames';
import { useBasketContext, useRequirementFilteredForms } from 'modules/orders/containers/OrderWizard/providers';
import { useBasketRequirements } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketRequirements';

export const useGetPopover = () => {

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const { orderWizardDisablePopovers, orderWizardDisableAllPopovers, orderWizardEnableBasketPopovers } = wizardSettings?.preferences || {};

  return (item: ListItem<OrderWizardRequirement>, detail?: boolean, basket?: boolean, options?: Omit<PopoverProps, 'children'>) => {

    const { meta: requirement } = item;

    const isSpecialPopover = requirement.flags.includes(AggregatedRequirementFlags.IsProfile) || requirement.flags.includes(AggregatedRequirementFlags.IsSuperRequirement);

    if (orderWizardDisablePopovers && (orderWizardDisableAllPopovers || !isSpecialPopover) && !(orderWizardEnableBasketPopovers && basket)) {
      return undefined;
    }

    return {
      arrow: true,
      placement: 'right' as TooltipPlacement,
      content: <RequirementPopOver requirement={requirement} detail={detail} basket={basket}/>,
      destroyTooltipOnHide: true,
      mouseEnterDelay: 0.3,
      ...options,
      // visible: requirement.id === 46,
    };
  };
};

export const getBadgeForRequirement = (requirement: OrderWizardRequirement) => {

  const { analyses } = requirement;

  if (!analyses) {
    return undefined;
  }

  const noExpansion = analyses.length === 1 && (
    requirement.shortName === analyses[0].shortName
    || requirement.longName === analyses[0].longName
  );

  return noExpansion || analyses.length <= 1 || requirement.flags.includes(AggregatedRequirementFlags.MultiAnalysisSelect) ? undefined : analyses.length;

};

/**
 * Returns either the freeText or the freeTextDate of the given requirement
 * @param requirement
 * @param format
 */
export const freeTextByType = (requirement: OrderWizardRequirement, format?: boolean) => {
  if (requirement?.freeTextAsDate) {
    if (format) {
      const freeTextMoment = dayjs(requirement?.freeTextDate).isValid() ? dayjs(requirement?.freeTextDate) : dayjs();
      return freeTextMoment.format(getLongDateFormat());
    }

    return requirement?.freeTextDate?.trim();
  }

  return requirement?.freeText?.trim();
};

export const isRequirement = (entity: OrderWizardRequirement | AggregatedProfileProperties): entity is OrderWizardRequirement => {
  return !entity.flags?.includes(AggregatedRequirementFlags.IsProfile);
};

export const isProfile = (entity: OrderWizardRequirement | AggregatedProfileProperties): entity is AggregatedProfileProperties => {
  return entity.flags?.includes(AggregatedRequirementFlags.IsProfile);
};

const specialFilterMessages = messages.orders.requirementAggregations;

const getSpecialFilterMessage = (id: OrderRequirementsSpecialFilterId) => ({
  [OrderRequirementsSpecialFilterId.Unassigned]: specialFilterMessages.unassigned,
  [OrderRequirementsSpecialFilterId.Profiles]: specialFilterMessages.profiles,
  [OrderRequirementsSpecialFilterId.Favorites]: specialFilterMessages.favorites,
  [OrderRequirementsSpecialFilterId.TopRequests]: specialFilterMessages.topRequests,
  [OrderRequirementsSpecialFilterId.Microbiological]: specialFilterMessages.microbiological,
}[id]);

const getSpecialFilterIcon = (id: OrderRequirementsSpecialFilterId) => ({
  [OrderRequirementsSpecialFilterId.Unassigned]: faQuestionCircle,
  [OrderRequirementsSpecialFilterId.Profiles]: faLayerGroup,
  [OrderRequirementsSpecialFilterId.Favorites]: faStar,
  [OrderRequirementsSpecialFilterId.TopRequests]: faCrown,
  [OrderRequirementsSpecialFilterId.Microbiological]: faBug,
}[id]);

const getSpecialFilterClassname = (id: OrderRequirementsSpecialFilterId) => ({
  [OrderRequirementsSpecialFilterId.Unassigned]: 'oa-filter-unassigned',
  [OrderRequirementsSpecialFilterId.Profiles]: 'oa-filter-profiles',
  [OrderRequirementsSpecialFilterId.Favorites]: 'oa-filter-favorites',
  [OrderRequirementsSpecialFilterId.TopRequests]: 'oa-filter-top-requests',
  [OrderRequirementsSpecialFilterId.Microbiological]: 'oa-filter-bak',
}[id]);

const getSpecialFilterColor = (id: OrderRequirementsSpecialFilterId) => ({
  [OrderRequirementsSpecialFilterId.Unassigned]: '--oa-filter-unassigned-color',
  [OrderRequirementsSpecialFilterId.Profiles]: '--oa-filter-profiles-color',
  [OrderRequirementsSpecialFilterId.Favorites]: '--oa-filter-favorites-color',
  [OrderRequirementsSpecialFilterId.TopRequests]: '--oa-filter-top-requests-color',
  [OrderRequirementsSpecialFilterId.Microbiological]: '--oa-filter-bak-color',
}[id]);

export const useGetFilterConfig = () => {

  const translate = useTranslate();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  return useCallback((filter: OrderRequirementsFilter, settings: OrderWizardSettings = wizardSettings) => {

    filter = find(settings.filters, filter) || filter;

    if (filter.type === RequirementAggregationType.OrderForm) {
      const filterForm = find(settings.forms, { id: filter.id });
      if (filterForm) {
        const { costUnit, name } = filterForm;
        filter.name = translate(messages.orders.wizard.formCostUnitFormat, { costUnit, name }) ?? '';
      }
    }

    const name: Message = filter.name || translate(getSpecialFilterMessage(filter.id));

    const config: OrderAggregationConfig = filter.type !== undefined
      ? find(OrderAggregations, { type: filter.type })
      : {
        icon: getSpecialFilterIcon(filter.id),
        color: getSpecialFilterColor(filter.id),
        type: filter.type,
        className: getSpecialFilterClassname(filter.id),
      };

    return { name, ...config };
  }, [translate, wizardSettings]);
};

export const useInvoiceTo = () => {

  const translate = useTranslate();

  const basketRequirements = useBasketRequirements();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const optionLabels = messages.orders.additionalFields;

  const options = useMemo(() => ([
    { value: 'patient', label: translate(optionLabels.invoiceToOptions.patient) },
    { value: 'arzt', label: translate(optionLabels.invoiceToOptions.doctor) },
  ]), [translate]);

  const getSelectedLabel = useCallback((invoiceTo: string) => {
    return find(options, { value: invoiceTo })?.label;
  }, [options]);

  const invoiceToActive = useMemo(
    () => basketRequirements.filter(r => find(wizardSettings?.forms, { id: r.formId })?.invoiceToChangeable).length > 0,
    [basketRequirements, wizardSettings?.forms],
  );

  const invoiceToActiveInPopover = ({ formId }: OrderWizardRequirement) => {
    return wizardSettings?.preferences?.orderWizardPopoverInvoiceTo && find(wizardSettings?.forms, { id: formId })?.invoiceToChangeable;
  };

  return {
    options,
    getSelectedLabel,
    invoiceToActive,
    invoiceToActiveInPopover,
  };

};

export const useGetRequirementPrivateForms = () => {

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const filterForms = useRequirementFilteredForms();

  return useCallback((requirement: OrderWizardRequirement) => {
    return filter(filterForms(requirement).map(id => find(wizardSettings?.forms, { id })), f => f?.isPrivate);
  }, [wizardSettings?.forms, filterForms]);
};

export const useIsRequirementPrivateOnly = () => {

  const filterForms = useRequirementFilteredForms();
  const getRequirementPrivateForms = useGetRequirementPrivateForms();

  return useCallback((requirement: OrderWizardRequirement) => {
    const filteredForms = filterForms(requirement);
    return filteredForms.length > 0 && filteredForms.length === getRequirementPrivateForms(requirement).length;
  }, [getRequirementPrivateForms, filterForms]);
};

export const useGetRequirementBasketForm = () => {

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const { basketRequirements } = useBasketContext();

  return useCallback((requirement: OrderWizardRequirement) => {
    const inBasket = find(basketRequirements, { id: requirement.id });
    return inBasket ? find(wizardSettings?.forms, { id: inBasket.formId }) : undefined;
  }, [basketRequirements, wizardSettings?.forms]);
};

export const useGetRequirementPriceBadge = () => {

  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const filterForms = useRequirementFilteredForms();

  const getRequirementPrivateForms = useGetRequirementPrivateForms();
  const isRequirementPrivateOnly = useIsRequirementPrivateOnly();
  const getRequirementBasketForm = useGetRequirementBasketForm();

  return useCallback((requirement: OrderWizardRequirement) => {

    const isPrivateOnly = isRequirementPrivateOnly(requirement) && filterForms(requirement).length === 1;

    const requirementForm = getRequirementBasketForm(requirement) || (isPrivateOnly ? getRequirementPrivateForms(requirement)[0] : undefined);
    const price = getRequirementPrice(requirement, requirementForm, officeDoctor?.localisation);

    return price && requirementForm?.isPrivate ? (<span className={cx('wizard-price-badge')}>{price}</span>) : null;
  }, [isRequirementPrivateOnly, getRequirementBasketForm, getRequirementPrivateForms, officeDoctor?.localisation, filterForms]);
};

export const useRequirementSubtitle = () => {

  const currentOrder = useCurrentOrder();
  const getRequirementPriceBadge = useGetRequirementPriceBadge();

  return useCallback((requirement: OrderWizardRequirement, inBasket?: boolean) => {

    const { diagnoses, selectedAnalyses, examinationDuration, examinationDurationInterval } = requirement;

    const selectedDiagnoses = filter(keys(currentOrder?.selectedDiagnoses)?.map(d => find(diagnoses, { id: parseInt(d) })?.key)).join(', ');

    return (
      <>
        {requirement.rank !== undefined && <RankIcon requirement={requirement}/>}
        {getRequirementShortName(requirement)}
        {selectedDiagnoses && (
          <span>&nbsp;-&nbsp;{selectedDiagnoses}</span>
        )}
        {selectedAnalyses?.length > 0 && !inBasket && (
          <span>&nbsp;-&nbsp;{filter(selectedAnalyses.map(shortName => find(requirement.analyses, { shortName })?.longName)).join(', ')}</span>
        )}
        {
          examinationDuration && examinationDurationInterval && (
            <span>&nbsp;-&nbsp;<Translate message={messages.general.intervalPlurals[examinationDurationInterval]}
              values={{ time: secondsToInterval(examinationDuration, examinationDurationInterval) }}/></span>
          )
        }
        {requirement.flags?.includes(AggregatedRequirementFlags.IsNew) && (
          <Tag className={'wizard-new-badge'}>
            <Translate message={messages.general.new}/>
          </Tag>
        )}
        {getRequirementPriceBadge(requirement)}
      </>
    );

  }, [useGetRequirementPriceBadge, currentOrder]);

};

export const useUniqueFormTypes = () => {

  const basketRequirements = useBasketRequirements();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  // unique forms in basket
  const uniqForms = useMemo(
    () => uniq(filter(basketRequirements.map(r => r.formId).map(id => find(wizardSettings?.forms, { id })))) || [],
    [basketRequirements, wizardSettings?.forms],
  );

  return useMemo(() => uniq(uniqForms.map(f => f.formType)), [uniqForms]);

};
