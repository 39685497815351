import React from 'react';
import './Textarea.less';
import { default as AntInput, TextAreaProps as AntTextareaProps } from 'antd/es/input';

type OverwriteProps = object;

export type TextareaProps = Omit<AntTextareaProps, keyof OverwriteProps> & OverwriteProps;

export const Textarea: React.FC<TextareaProps> = (props) => {

  return (
    <AntInput.TextArea
      {...props}
    />
  );
};
