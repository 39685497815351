import React, { useRef } from 'react';
import styles from './Profile.module.less';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { Translate, useApi, useBiometricsStoreSelectors, useConfig, useIntlStoreSelectors, useSetConfig } from 'providers';
import { Avatar, Container, Control, FormItem, Icon, ModalControl, ModalFormControl, useSuccessModal } from 'components';
import { Product, UserAccessibilityContrast, UserAccessibilityFontSize, UserType } from 'interfaces/api';
import { ApiRequest, FormLayout, Guard, useGuard } from 'containers';
import { faCircleHalfStroke, faEdit, faFingerprint, faKey, faPowerOff, faShieldKeyhole, faShieldSlash, faSmile, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { useReloadUserDetails } from 'modules/auth/hooks';
import { splitEnumOptions, UserTypeIcon } from 'utils/helpers';
import { filter, map } from 'lodash';
import messages from 'messages';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Radio } from 'antd';

export const Profile: React.FC = () => {

  const translate = useIntlStoreSelectors.translate();
  const labels = messages.general.topBar.editProfile;

  const avatar = useAuthStoreSelectors.avatar();
  const setAvatar = useAuthStoreSelectors.setAvatar();

  const reloadUserDetails = useReloadUserDetails();
  const { entityId, type, enabled2fa } = useAuthStoreSelectors.user();

  const {
    authentication: { changePassword, disableTwoFactorAuthentication, enableTwoFactorAuthentication, initializeTwoFactorAuthentication },
    doctors: { getDoctorgroupsForDoctor },
    globals: { updateConfig },
    devices: { createDeviceToken },
    profile: { getUserProfile, updateUserProfile, updateAvatarUserProfile },
  } = useApi();

  const config = useConfig();
  const { preferences, twoFactorAuthEnabled, hideEmailFields, doctorSmsVerification } = config;
  const setConfig = useSetConfig();

  const guard = useGuard();
  const successModal = useSuccessModal();

  const enableBiometrics = useBiometricsStoreSelectors.enable();
  const disableBiometrics = useBiometricsStoreSelectors.disable();

  const biometricsEnabled = useBiometricsStoreSelectors.enabled();
  const biometricsAvailable = useBiometricsStoreSelectors.available();

  const fingerPrintLabels = messages.general.fingerprint;
  const fingerPrintTypeLabel = translate(fingerPrintLabels.types[biometricsAvailable]);

  const products = filter(Product, product => ![Product.LC, Product.NC].includes(product) && !!guard({ product }, () => product));

  const onAvatarChange = async (avatar: File): Promise<void> => {
    await updateAvatarUserProfile({ avatar });
    setAvatar(avatar);
  };

  const reloadSettings = useRef<() => void>();

  return (
    <ModalControl
      className={'profile-control'}
      label={labels.title}
      modal={{
        scrollY: true,
        footer: null,
        children: (
          <ApiRequest
            bindReload={reload => reloadSettings.current = reload}
            request={async () => await getUserProfile()}
            children={({ data }) => (
              <Container>

                <FormLayout label={labels.avatar} embedded>
                  <Container className={styles.avatarContainer}>
                    <Avatar file={avatar} size={'12rem'} className={styles.avatar} icon={UserTypeIcon[type]}>
                      <Icon icon={faUpload} className={styles.upload}/>
                      <input type={'file'} onChange={event => onAvatarChange(event.target.files[0])}/>
                    </Avatar>
                  </Container>
                  {avatar && (
                    <Container center>
                      <Control
                        icon={faTrashAlt}
                        className={'margin-top-1'}
                        label={labels.deleteAvatar}
                        confirm={{
                          title: labels.deleteAvatarConfirm,
                        }}
                        onClick={() => onAvatarChange(null)}
                      />
                    </Container>
                  )}
                </FormLayout>

                <FormLayout label={labels.personalData} embedded>
                  {!hideEmailFields && (
                    <FormItem label={labels.email}>
                      <span className={'form-plain-text'}>{data.email}</span>
                    </FormItem>
                  )}

                  {doctorSmsVerification && (
                    <Guard permitted={[UserType.ARZ]}>
                      <FormItem label={labels.smsNumber}>
                        <span className={'form-plain-text'}>{data.smsNumber || <i><Translate message={messages.general.notSpecified}/></i>}</span>
                      </FormItem>
                    </Guard>
                  )}

                  <Guard permitted={[UserType.LAB, UserType.ARZ]}>
                    <FormItem label={labels.website}>
                      <span className={'form-plain-text'}>{data.www}</span>
                    </FormItem>
                  </Guard>

                  <Guard forbidden={[UserType.LAU]}>
                    {(type !== UserType.PAT || !hideEmailFields) && (
                      <FormItem className={styles.editPersonalDataFormItem}>
                        <ModalFormControl
                          modal={{ narrow: true }}
                          button={{ block: true }}
                          icon={faEdit}
                          label={labels.editPersonalData}
                          form={{
                            onSuccess: () => reloadSettings.current(),
                            request: async (values) => {
                              await updateUserProfile(values);
                              await reloadUserDetails();
                            },
                            initialValue: data,
                            children: ({ Input }) => (
                              <Container grow shrink padding>
                                {!hideEmailFields && <Input property={'email'} label={labels.email}/>}
                                <Guard permitted={[UserType.LAB, UserType.LAU, UserType.ARZ]}>
                                  <Input property={'www'} label={labels.website}/>
                                </Guard>
                                {doctorSmsVerification && (
                                  <Guard permitted={[UserType.ARZ]}>
                                    <Input property={'smsNumber'} label={labels.smsNumber} inputMode={'tel'}/>
                                  </Guard>
                                )}
                              </Container>
                            ),
                          }}
                        />
                      </FormItem>
                    )}
                  </Guard>

                </FormLayout>

                <FormLayout label={messages.general.settings} embedded>

                  <Guard permitted={[UserType.ARZ]}>
                    <ApiRequest
                      errorAsAlert
                      request={() => getDoctorgroupsForDoctor({ aid: entityId })}
                      children={({ data }) => {
                        const groups = map(data, d => d.name).join(', ');
                        return groups
                          ? (
                            <FormItem label={labels.groups}>
                              <>{groups}</>
                            </FormItem>
                          )
                          : undefined;
                      }}
                    />
                  </Guard>

                  <FormItem label={translate(messages.products.product[preferences.startProduct]?.title ?? labels.noStartProductSelected)}>
                    <ModalFormControl
                      modal={{ narrow: true }}
                      button={{ block: true }}
                      icon={faEdit}
                      label={labels.changeStartProduct}
                      form={{
                        request: async (values) => {
                          const { startProduct } = values;
                          await updateConfig({ preferences: { startProduct } });
                          setConfig({ preferences: Object.assign({}, preferences, { startProduct }) });
                        },
                        initialValue: { startProduct: preferences.startProduct },
                        children: ({ Select }) => (
                          <Container grow shrink padding>
                            <Select
                              property={'startProduct'}
                              label={labels.startProduct}
                              options={products.map(value => ({ value, label: translate(messages.products.product[value].title) }))}
                            />
                          </Container>
                        ),
                      }}
                    />
                  </FormItem>

                  <FormItem label={labels.accessibility}>

                    <Container horizontal>

                      <Radio.Group
                        defaultValue={preferences.fontSize}
                        onChange={async (event) => {
                          await updateConfig({ preferences: { fontSize: event.target.value } });
                          setConfig({ preferences: Object.assign({}, preferences, { fontSize: event.target.value }) });
                        }}
                        className={'margin-right-1'}
                      >
                        {splitEnumOptions(UserAccessibilityFontSize).map(({ value }) => (
                          <Radio.Button value={value}>
                            <span className={`profile-font-size-${value}`}>aA</span>
                          </Radio.Button>
                        ))}
                      </Radio.Group>

                      <Radio.Group
                        defaultValue={preferences.contrast}
                        onChange={async (event) => {
                          await updateConfig({ preferences: { contrast: event.target.value } });
                          setConfig({ preferences: Object.assign({}, preferences, { contrast: event.target.value }) });
                        }}
                      >
                        {splitEnumOptions(UserAccessibilityContrast).map(({ value }) => (
                          <Radio.Button value={value}>
                            <span className={`profile-contrast-${value}`}><Icon icon={faCircleHalfStroke}/></span>
                          </Radio.Button>
                        ))}
                      </Radio.Group>

                    </Container>

                  </FormItem>

                </FormLayout>

                <FormLayout label={labels.security} embedded>

                  <FormItem label={labels.password}>
                    <ModalFormControl
                      label={labels.changePassword.title}
                      icon={faKey}
                      button={{ block: true }}
                      modal={{ narrow: true }}
                      form={{
                        onSuccess: () => reloadSettings.current(),
                        request: values => changePassword(values),
                        initialValue: { newPassword: '', oldPassword: '' },
                        notifications: labels.changePassword.notifications,
                        children: ({ Password }) => (
                          <Container grow shrink padding>
                            <Password property={'oldPassword'} label={labels.changePassword.oldPassword} autoComplete={'new-password'}/>
                            <Password property={'newPassword'} label={labels.changePassword.newPassword} withPolicy autoComplete={'new-password'}/>
                          </Container>
                        ),
                      }}
                    />
                  </FormItem>

                  {biometricsAvailable !== undefined && (
                    <FormItem label={fingerPrintTypeLabel}>
                      {biometricsEnabled && (
                        <Control
                          label={translate(fingerPrintLabels.disable, { TYPE: fingerPrintTypeLabel })}
                          icon={faPowerOff}
                          button={{ block: true }}
                          onClick={() => disableBiometrics()}
                        />
                      )}
                      {!biometricsEnabled && (
                        <Control
                          label={translate(fingerPrintLabels.enable, { TYPE: fingerPrintTypeLabel })}
                          icon={biometricsAvailable === 'face' ? faSmile : faFingerprint}
                          button={{ block: true }}
                          onClick={() => enableBiometrics(createDeviceToken)}
                        />
                      )}
                    </FormItem>
                  )}

                  {twoFactorAuthEnabled && (
                    <FormItem label={labels.twoFactorAuth.label}>
                      {enabled2fa && (
                        <Control
                          label={labels.twoFactorAuth.disable.label}
                          icon={faShieldSlash}
                          button={{ block: true }}
                          onClick={async () => {
                            await disableTwoFactorAuthentication();
                            await reloadUserDetails();
                            successModal({
                              title: translate(labels.twoFactorAuth.disable.success.title),
                              content: translate(labels.twoFactorAuth.disable.success.content),
                            });
                          }}
                          confirm={labels.twoFactorAuth.disable.confirm}
                        />
                      )}
                      {!enabled2fa && (
                        <ModalFormControl
                          label={labels.twoFactorAuth.enable.label}
                          icon={faShieldKeyhole}
                          button={{ block: true }}
                          modal={{
                            narrow: true,
                            okText: labels.twoFactorAuth.activateNow,
                          }}
                          form={{
                            initialValue: { request: async () => ({ ...await initializeTwoFactorAuthentication(), token2fa: '' }) },
                            request: async ({ secret, token2fa }) => {
                              await enableTwoFactorAuthentication({ secret, token2fa });
                              await reloadUserDetails();
                              successModal({
                                title: translate(labels.twoFactorAuth.enable.success.title),
                                content: translate(labels.twoFactorAuth.enable.success.content),
                              });
                            },
                            children: ({ PinField, value, submit }) => (
                              <Container grow shrink padding center>
                                <h2>
                                  <Translate message={labels.twoFactorAuth.qrcode}/>
                                </h2>
                                <Container padding center>
                                  <img src={value.qrcode} height={200} width={'200px'} style={{ margin: '0 auto' }}/>
                                  <Container padding center>
                                    <Translate message={labels.twoFactorAuth.manual}/><br/>
                                    <span style={{ fontSize: '15px' }}>{value.secret.match(/.{1,4}/g).join(' ')}</span>
                                  </Container>
                                </Container>
                                <h2>
                                  <Translate message={labels.twoFactorAuth.enterToken}/>
                                </h2>
                                <Container padding center>
                                  <Container style={{ maxWidth: '260px' }}>
                                    <PinField
                                      autoFocus
                                      property={'token2fa'}
                                      length={6}
                                      placeholder={'•'}
                                      inputMode={'numeric'}
                                      pattern={'[0-9]*'}
                                      validate={/^[0-9]$/}
                                      onComplete={() => window.setTimeout(submit, 100)}
                                    />
                                  </Container>
                                </Container>
                              </Container>
                            ),
                          }}
                        />
                      )}
                    </FormItem>
                  )}

                </FormLayout>

              </Container>
            )}
          />
        ),
      }}
    />
  );

};
