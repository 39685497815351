import React, { useMemo } from 'react';
import { isI18nMessage, Message } from 'interfaces';
import { Card, Container, ControlProps } from 'components';
import { withContent, WithContentInnerProps, WithContentOuterProps } from 'hocs';
import './FormLayout.less';
import { Translate } from 'providers';
import cx from 'classnames';
import { map } from 'lodash';

export type FormLayoutProps = {
  label?: Message;
  description?: Message;
  className?: string;
  style?: React.CSSProperties;
  columns?: Message[];
  embedded?: boolean;
  narrowLabels?: boolean;
  narrowControls?: boolean;
} & WithContentOuterProps<{ Row: React.ComponentType<FormLayoutRowProps> }>;

type Props = FormLayoutProps & WithContentInnerProps;

export const FormLayout = withContent((props: Props) => {

  const { label, description, columns, className, embedded, narrowLabels, narrowControls } = props;

  const header = useMemo(() => (label || columns) && (
    <Container className={cx('form-layout-header')}>
      <h3><Translate message={label}/></h3>
      {columns && (
        <Container horizontal grow className={'form-layout-columns'}>
          {columns.map((column, idx) => (<h4 key={isI18nMessage(column) ? column.id : idx}><Translate message={column}/></h4>))}
        </Container>
      )}
    </Container>
  ), [label, columns]);

  const containerClasses = useMemo(() => cx('form-layout', {
    'form-layout-embedded': embedded,
    'form-layout-columns-no-header': !label,
    'form-layout-has-columns': !!columns,
    'form-layout-narrow-labels': narrowLabels,
    'form-layout-narrow-controls': narrowControls,
  }, className), [className, embedded, label, columns, narrowLabels]);

  return (
    <div className={containerClasses} style={props.style}>
      <Card title={header} className={'form-layout-card'}>
        {description && (
          <Container className={'form-layout-description'}>
            <Translate message={description}/>
          </Container>
        )}
        {props.renderContent({ Row: FormLayoutRow })}
      </Card>
    </div>
  );

});

export type FormLayoutRowProps = {
  title?: Message;
  subtitle?: Message;
  className?: string;
  controls?: React.ReactElement<ControlProps>[];
} & WithContentOuterProps;

export const FormLayoutRow = withContent((props: FormLayoutRowProps & WithContentInnerProps) => {

  const { title, subtitle, className, controls } = props;

  return (
    <Container className={cx('form-layout-row', className)}>

      <h4>
        <span className={'form-layout-title'}>{title && <Translate message={title}/>}</span>
        <span className={'form-layout-subtitle'}>{subtitle && <Translate message={subtitle}/>}</span>
      </h4>

      <Container grow className={'form-layout-row-content'}>
        {props.renderContent()}

        {controls && (
          <Container className={'form-layout-controls'} horizontal>
            <ul>
              {map(controls, (control, index) => (
                <li key={index}>{control}</li>
              ))}
            </ul>
          </Container>
        )}

      </Container>
    </Container>
  );

});
