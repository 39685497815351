import React from 'react';
import messages from 'messages';
import { FormItemRenderProps } from 'components/Form/Item';
import { Feature, OrdersPreferencesProperties } from 'interfaces/api';
import { Guard } from 'containers';

const labels = messages.admin.order.general;

export const OrdersBakPreferencesForm: React.ComponentType<FormItemRenderProps<Required<OrdersPreferencesProperties>>> = (props) => {

  const { Switch, ColorPicker } = props;

  return (
    <>
      <Guard feature={Feature.BakSettingSplitBak}>
        <Switch
          property={'ordersSplitBak'}
          label={labels.bakSettings.splitBak.label}
          description={labels.bakSettings.splitBak.description}
        />
      </Guard>

      <Guard feature={Feature.BakSettingMultipleBakBarcodes}>
        <Switch
          property={'ordersMultipleBakBarcodes'}
          label={labels.bakSettings.multipleBakBarcodes}
        />
      </Guard>

      <Switch
        property={'orderWizardBakModalBlockButtons'}
        label={labels.bakModalBlockButtons.label}
        description={labels.bakModalBlockButtons.description}
      />

      <ColorPicker
        property={'orderWizardBakColor'}
        label={labels.bakSettings.bakColor}
      />

      <ColorPicker
        property={'orderWizardBakFontColor'}
        label={labels.bakSettings.bakFontColor}
      />

    </>
  );

};
