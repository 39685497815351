import React from 'react';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';
import { OrderControlContext } from 'modules/orders/index';
import { useApi } from 'providers';
import { ChooseOrdersControl } from '../../ChooseOrdersControl';
import { Order } from 'interfaces/api';
import messages from 'messages';

const labels = messages.orders.controls.exportWithoutPDF;

export const ExportWithoutPDF = (props: OrderControlContext) => {

  const { orders: { exportWithoutPdf } } = useApi();
  const { data: { hasMissingAggregation }, bindings: { reload } } = props;

  return (
    <ChooseOrdersControl
      orders={props.data.orders}
      disabledCheck={o => !o.isCancelable}
      icon={faFileExport}
      button={{
        danger: false,
        block: true,
      }}
      disabled={hasMissingAggregation}
      label={labels.label}
      notifications={labels.notifications}
      onDone={async (orders: Order[]) => {
        await exportWithoutPdf({ orderIds: orders.map(o => o.id) });
        reload();
      }}
    />
  );
};
