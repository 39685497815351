import { faCalendar, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { ButtonGroup, Control } from 'components';
import React, { useMemo } from 'react';
import messages from 'messages';
import { useOfficeDoctorSelectors, useOrdersSelectors } from 'modules/orders/providers';
import { useSaveOrExecuteOrders } from 'modules/orders/containers/OrderWizard/providers';
import { useSampleDateModal } from 'modules/orders/components';
import { Feature, OrderStatus } from 'interfaces/api';
import { filter, reverse } from 'lodash';
import { SampleInLaboratoryIcon } from 'modules/orders/utils';
import { useGuard } from 'containers';
import dayjs from 'dayjs';

const labels = messages.orders.controls.execute;

export const ExecuteOrderControl = () => {

  const guard = useGuard();

  const orders = useOrdersSelectors.orders();
  const globalSettings = useOrdersSelectors.globalSettings();
  const saveOrExecute = useSaveOrExecuteOrders();

  const showModal = useSampleDateModal();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const ordersPreferScheduleDateMode = wizardSettings?.preferences?.ordersPreferScheduleDateMode;
  const ordersShowPrePrint = wizardSettings?.preferences?.ordersShowPrePrint;
  const ordersPrePrintIsC2A = wizardSettings?.preferences?.ordersPrePrintIsC2A;
  const ordersSampleInLaboratoryButtonFirst = wizardSettings?.preferences?.ordersSampleInLaboratoryButtonFirst;
  const ordersSampleDateTimeButton = wizardSettings?.preferences?.ordersSampleDateTimeButton;

  const preferPrePrint = useMemo(
    () => ![OrderStatus.Queued].includes(orders[0].status) && ordersShowPrePrint && ordersPrePrintIsC2A,
    [orders, ordersShowPrePrint, ordersPrePrintIsC2A],
  );

  const buttonType = useMemo(
    () => !preferPrePrint && (!ordersPreferScheduleDateMode || globalSettings.scheduled_at) ? 'primary' : 'default',
    [preferPrePrint, ordersPreferScheduleDateMode, globalSettings.scheduled_at],
  );

  const executeWithSampleDateTime = () => {
    let defaultValue = orders[0]?.patient?.sampleDate ? dayjs(orders[0].patient.sampleDate) : undefined;
    const sampleTime = orders[0]?.patient?.sampleTime;

    if (sampleTime) {
      defaultValue = defaultValue
        .set('hour', parseInt(sampleTime.substring(0, 2)))
        .set('minute', parseInt(sampleTime.substring(2, 4)));
    }

    return showModal({
      defaultValue,
      onDone: async (value) => {
        await saveOrExecute({ execute: true, sampleDate: value.format(), sampleTime: value.format('HHmm') });
      },
    });
  };

  // @todo better memoization

  const buttons = filter([(
    <Control
      key={'execute'}
      icon={faPaperPlane}
      label={labels.label}
      onClick={() => saveOrExecute({ execute: true })}
      notifications={{
        success: labels.notifications.success,
        error: false,
      }}
      button={{
        type: buttonType,
      }}
    />
  ), ordersSampleDateTimeButton
    ? (
      <Control
        key={'sample-date'}
        icon={faCalendar}
        label={labels.sampleDate}
        onClick={executeWithSampleDateTime}
        tooltip={{
          placement: 'left',
        }}
        notifications={{
          success: labels.notifications.success,
          error: false,
        }}
        button={{
          type: buttonType,
        }}
      />
    )
    : guard({ feature: Feature.SampleInLaboratory }, () => (
      <Control
        key={'sample-in-lab'}
        icon={SampleInLaboratoryIcon}
        label={labels.labelInLab}
        onClick={async () => {
          await (saveOrExecute({ execute: true, sample_in_lab: true }));
        }}
        notifications={{
          success: labels.notifications.success,
          error: false,
        }}
        button={{
          type: buttonType,
        }}
      />
    ))]);

  return (
    <ButtonGroup>
      {ordersSampleInLaboratoryButtonFirst ? reverse(buttons) : buttons}
    </ButtonGroup>
  );

};
