import { Message } from 'interfaces/Messages';

export type SortOption = {
  message?: Message;
  key: string;
  grouping?: SortGrouping;
  dir?: SortDirection;
};

export enum SortDirection {
  ASC = 0,
  DESC = 1,
}

export namespace SortDirection {
  export function toggle(dir: SortDirection) {
    return dir === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
  }
}

export const enum SortGrouping {
  DATE,
  LETTER,
  NUMBER,
  STRING,
}
