import React, { useEffect } from 'react';
import { Message } from 'interfaces';
import { ControlMenuProps } from 'components';
import { create } from 'zustand';
import { createSelectors } from 'utils/helpers';

type BackLink = {
  title: Message;
  path: string;
};

interface TopBarState {
  backLink: BackLink;
  setBackLink: (backLink: BackLink) => void;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  menu: ControlMenuProps;
  setMenu: (menu: ControlMenuProps) => void;
}

export const useTopBarStore = create<TopBarState>()((set, get) => ({
  backLink: undefined,
  setBackLink: backLink => set({ backLink }),
  isActive: false,
  setIsActive: isActive => set({ isActive }),
  menu: false,
  setMenu: menu => set({ menu }),
}));

export const useTopBarSelectors = createSelectors(useTopBarStore).use;

export const TopBarBackLink: React.FC<{ backLink?: BackLink; children?: React.ReactNode }> = (props) => {

  const { backLink } = props;

  const setBackLink = useTopBarSelectors.setBackLink();
  const setIsActive = useTopBarSelectors.setIsActive();

  useEffect(() => {

    setBackLink(backLink);
    setIsActive(true);

    return () => {
      setIsActive(false);
    };

  }, [backLink?.path, backLink?.title]);

  return props.children;

};

export const TopBarControlMenu: React.FC<ControlMenuProps> = (props) => {

  const setMenu = useTopBarSelectors.setMenu();

  useEffect(() => {

    setMenu(props);

    return () => {
      window.setTimeout(() => {
        setMenu(undefined);
      }, 200);
    };

  }, []);

  useEffect(() => {
    setMenu(props);
  }, [props.controls]);

  return props.children;

};
