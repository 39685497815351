import React, { useCallback, useMemo, useState } from 'react';
import { Message } from 'interfaces';
import { AlertIcon, Badge, BadgeProps, Button, Icon, IconProps, Tooltip, TooltipProps, useConfirmModal, WarningIcon } from 'components';
import './Control.less';
import { ButtonProps } from 'antd/es/button';
import { PromiseCancelError } from 'utils/helpers';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useTranslate } from 'providers';
import messages from 'messages';
import { App } from 'antd';

export type ControlProps = {
  label?: React.ReactNode | Message;
  icon?: IconProps;
  hoverIcon?: IconProps;
  badge?: BadgeProps;
  warningBadge?: boolean;
  alertBadge?: boolean;
  iconBadge?: IconProps;
  onClick?: (event?: React.MouseEvent) => any;
  onError?: (error?: Error) => any;
  linkTo?: string;
  className?: string;
  style?: React.CSSProperties;
  isActive?: boolean;
  button?: ButtonProps;
  tooltip?: boolean | Partial<TooltipProps>;
  disabled?: boolean;
  readonly?: boolean;
  exact?: boolean;
  confirm?: {
    title: Message;
    content?: Message;
  };
  notifications?: {
    success?: Message | boolean;
    error?: Message | boolean;
    canceled?: Message;
  };
  children?: React.ReactNode;
};

export class ControlCancelError extends Error {

  __CANCEL__ = true;

  constructor(message?: string) {
    super(message);
    this.name = 'ControlCancelError';
  }

}

export const Control = (props: ControlProps) => {

  const translate = useTranslate();
  const { message } = App.useApp();
  const { label, icon, onClick, tooltip, children, style, button, isActive, linkTo, notifications, disabled } = props;

  const [internalLoading, setLoading] = useState<boolean>(false);
  const confirmModal = useConfirmModal();

  const { loading: buttonLoading, ...buttonProps } = button || {};
  const loading = internalLoading || !!buttonLoading;

  const showSuccess = useCallback(() => {
    setLoading(false);
    if (notifications?.success) {
      message.success(translate(notifications.success));
    }
  }, [notifications]);

  const showError = useCallback((error: Error) => {

    setLoading(false);

    if (error instanceof PromiseCancelError) {
      return;
    }

    if ((error as ControlCancelError).__CANCEL__) {
      message.warning(translate(notifications?.canceled || messages.errors.canceled));
    } else {

      console.error(error);

      props.onError?.(error);

      if (notifications?.error !== false) {
        message.error(translate(notifications?.error || messages.errors.occurred, { name: error.name }));
      }
    }
  }, [notifications, props.onError]);

  const handleClick = useCallback((event: React.MouseEvent) => {

    if (event) {
      event.preventDefault();
    }

    if (onClick && !disabled && !loading) {

      const { confirm } = props;

      const handle = () => {
        setLoading(true);
        Promise.resolve(onClick(event)).then(showSuccess).catch(showError);
      };

      if (confirm) {
        confirmModal({
          title: translate(confirm.title),
          content: translate(confirm.content),
          onOk: handle,
          okButtonProps: {
            id: 'controlConfirmationPrimaryButton',
          },
        });
      } else {
        handle();
      }

    }
  }, [onClick, disabled, loading]);

  const containerClassname = useMemo(() => cx('control', props.className), [props.className]);

  const classNames = useMemo(() => cx(containerClassname, {
    'is-active': isActive,
    disabled: disabled || loading,
  }), [containerClassname, isActive, disabled, loading]);

  const WrapperTag = button ? Button : 'a';

  const renderIcon = useCallback(() => icon && (
    <span className={cx('control-icon', { 'control-has-hover-icon': props.hoverIcon })}>
      <Icon {...(loading ? { icon: faSpinner, spin: true } : icon)}/>
      {props.hoverIcon && <Icon {...props.hoverIcon}/>}
    </span>
  ), [icon, loading]);
  const tooltipProps = tooltip === true ? {} : tooltip;

  const renderedTooltip = useMemo(() => (
    <Tooltip arrow title={label} overlayClassName={'control-tooltip'} trigger={'hover'} placement={'bottom'} {...tooltipProps}>
      {renderIcon()}
    </Tooltip>
  ), [label, tooltip, renderIcon]);

  const { alertBadge, warningBadge, iconBadge, badge } = props;

  const renderedBadge = useMemo(() => {
    if (alertBadge) {
      return <AlertIcon className={'control-badge-icon'}/>;
    } else if (warningBadge) {
      return <WarningIcon className={'control-badge-icon'}/>;
    } else if (iconBadge) {
      return <Icon {...iconBadge} className={'control-badge-icon'}/>;
    }
    return badge ? <Badge {...badge} className={'control-badge'}/> : null;
  }, [alertBadge, warningBadge, iconBadge, badge]);

  const controlContent = useMemo(() => (
    <>
      {tooltip !== false && !button && matchMedia('(hover: hover)').matches && !!label ? renderedTooltip : renderIcon()}
      {label && <span className={'control-label'}>{translate(label)}</span>}
      {renderedBadge}
    </>
  ), [label, renderedTooltip, renderIcon, renderedBadge]);

  return useMemo(() => {

    if (!icon && !label && children) {
      return (
        <WrapperTag className={containerClassname} style={style} onClick={handleClick}>
          {children}
        </WrapperTag>
      );
    }

    if (linkTo) {
      return (
        <NavLink to={linkTo} className={({ isActive }) => cx(classNames, { 'is-active': isActive })}>
          {button ? <Button {...buttonProps}>{controlContent}</Button> : controlContent}
        </NavLink>
      );
    }

    return (
      <WrapperTag className={classNames} style={style} {...buttonProps} onClick={handleClick}>
        {controlContent}
      </WrapperTag>
    );

  }, [linkTo, classNames, controlContent, handleClick, icon, children, label, containerClassname, WrapperTag, buttonProps]);

};
