import { useCurrentOrder, useOfficeDoctorSelectors, useOrdersSelectors } from 'modules/orders/providers';
import { useCallback } from 'react';
import { OrderWizardRequirement } from 'interfaces/api';
import { find } from 'lodash';

export const useRequirementFilteredForms = () => {

  const currentOrder = useCurrentOrder();

  const orders = useOrdersSelectors.orders();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  return useCallback((requirement: OrderWizardRequirement, costUnit?: string, includePool?: boolean) => {
    costUnit = costUnit || currentOrder?.costUnit;
    return (requirement.forms || []).filter((id) => {
      const form = find(wizardSettings?.forms, { id });
      return (includePool || orders.length === 1) && (costUnit === undefined || form?.costUnit === costUnit);
    });
  }, [currentOrder, wizardSettings?.forms, orders.length]);
};
