import React, { useMemo } from 'react';
import { useFormatDate } from './IntlProvider';
import { FormatDateOptions } from './useFormatDateFunction';
import dayjs from 'dayjs';

interface ComponentProps {
  date: Date | dayjs.Dayjs | string | number;
  options?: FormatDateOptions;
}

export const FormatDate: React.FC<ComponentProps> = ({ date, options }) => {
  const formatDate = useFormatDate();
  const formattedDate = useMemo(() => formatDate(date, options), [date, options, formatDate]);
  return (<>{formattedDate}</>);
};
