import { faFile, faFileAlt, faFileArchive, faFileAudio, faFileCode, faFileExcel, faFileImage, faFilePdf, faFileVideo, faFileWord } from '@fortawesome/pro-light-svg-icons';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { isWeb } from 'utils/device';
import { saveAs } from 'file-saver';

export const enum FileType {
  Other,
  Image,
  Video,
  Pdf,
  Word,
  Excel,
  Audio,
  Text,
  Archive,
  Code,
  LDT,
  HL7,
}

export const FileTypeExtensions = {
  png: FileType.Image,
  jpg: FileType.Image,
  jpeg: FileType.Image,
  gif: FileType.Image,
  bmp: FileType.Image,

  pdf: FileType.Pdf,

  mp3: FileType.Audio,
  wav: FileType.Audio,

  mov: FileType.Video,
  mp4: FileType.Video,
  avi: FileType.Video,

  doc: FileType.Word,
  docx: FileType.Word,

  txt: FileType.Text,
  rtf: FileType.Text,

  rar: FileType.Archive,
  zip: FileType.Archive,

  html: FileType.Code,

  csv: FileType.Excel,
  xls: FileType.Excel,
  xlsx: FileType.Excel,

  ldt: FileType.LDT,
  hl7: FileType.HL7,
};

export const FileTypeIcons = {
  [FileType.Image]: faFileImage,
  [FileType.Video]: faFileVideo,
  [FileType.Pdf]: faFilePdf,
  [FileType.Word]: faFileWord,
  [FileType.Excel]: faFileExcel,
  [FileType.Audio]: faFileAudio,
  [FileType.Text]: faFileAlt,
  [FileType.Archive]: faFileArchive,
  [FileType.Code]: faFileCode,
  [FileType.Other]: faFile,
  [FileType.LDT]: faFile,
  [FileType.HL7]: faFile,
};

export const getFileExtension = (file: File | string): keyof typeof FileTypeExtensions => {
  const filename = file instanceof File ? file.name : file;
  return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase() as keyof typeof FileTypeExtensions;
};

export const getFileType = (file: File | string) => {
  return FileTypeExtensions[getFileExtension(file)] || FileType.Other;
};

export const getFileIcon = (file: File | string) => {
  return FileTypeIcons[getFileType(file)];
};

export const downloadFile = async (file: File) => {

  try {

    if (await isWeb()) {
      return saveAs(file, file.name);
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = async function () {
      const base64data = reader.result;
      try {
        const result = await Filesystem.writeFile({
          path: file.name,
          data: <string>base64data,
          directory: Directory.Data,
          recursive: true,
        });

        FileOpener.open(result.uri, file.type).catch(console.error);

      } catch (e) {
        console.error(e);
      }
    };

  } catch (e) {
    console.error(e);
  }

};
