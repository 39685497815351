import React from 'react';
import { OrderWizardRequirement } from 'interfaces/api';
import styles from './styles.module.less';
import cx from 'classnames';

export const RankIcon = React.memo((props: { requirement: OrderWizardRequirement }) => (
  <span className={cx(styles.container, 'oa-requirement-rank')}>
    <img
      style={{
        backgroundColor: 'var(--color-silver)',
        mask: `url(/icons/occurrence-${props.requirement.rank + 1}.svg) no-repeat center`,
        WebkitMask: `url(/icons/occurrence-${props.requirement.rank + 1}.svg) no-repeat center`,
        maskSize: 'contain',
        WebkitMaskSize: 'contain',
      }}
    />
  </span>
), (prevProps, nextProps) => prevProps.requirement.rank === nextProps.requirement.rank);
