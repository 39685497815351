import React, { useCallback } from 'react';
import { AggregatedProfileProperties, OrderWizardRequirement } from 'interfaces/api';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { Control } from 'components/Control';
import messages from 'messages';
import { useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers';

export const InstantExecution = (props: { requirement: OrderWizardRequirement | AggregatedProfileProperties }) => {

  const { requirement } = props;
  const upsertBasket = useUpsertBasket();

  const onClick = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();
    upsertBasket({ ...requirement }, true);
  }, [upsertBasket, requirement]);

  return (
    <Control
      icon={faPaperPlane}
      label={messages.orders.instantExecution}
      tooltip={{ placement: 'top' }}
      onClick={onClick}
    />
  );

};
