import React, { useMemo } from 'react';
import './Password.less';
import { default as AntInput, PasswordProps as AntPasswordProps } from 'antd/es/input';
import { Translate, useConfig } from 'providers';
import messages from 'messages';
import { Icon } from 'components/Icon';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { Color } from 'interfaces';

const labels = messages.general.passwordPolicy;

type OverwriteProps = {
  value?: string;
  withPolicy?: boolean;
};

export type PasswordProps = Omit<AntPasswordProps, keyof OverwriteProps> & OverwriteProps;

export const Password: React.FC<PasswordProps> = (props) => {

  const { withPolicy, ...rest } = props;

  const { passwordPolicyPattern } = useConfig();

  const ok = useMemo(() => !passwordPolicyPattern || !!props.value?.match(new RegExp(passwordPolicyPattern)), [passwordPolicyPattern, props.value]);

  if (withPolicy) {
    return (
      <>
        <AntInput.Password {...rest}/>
        {passwordPolicyPattern && (
          <p className={'form-password-policy'}>
            <span className={'form-password-policy-status'}>
              <Icon {...(ok ? { icon: faCheckCircle, color: Color.Green } : { icon: faTimesCircle, color: Color.Red })}/>&nbsp;
              <Translate message={labels.label}/>
            </span>
            <span className={'form-password-policy-description'}>
              <Translate message={labels.description}/>
            </span>
          </p>
        )}
      </>
    );
  }

  return (
    <AntInput.Password
      {...rest}
    />
  );
};
