import React from 'react';
import { ScanPreview, Translate, useBarcodeProvider } from 'providers';
import { ModalControl } from 'components/Control/ModalControl';
import { faBarcode } from '@fortawesome/pro-regular-svg-icons';
import { Container } from 'components/Container';
import { Message } from 'interfaces';
import './ScanModalControl.less';

export type ScanModalControlProps = {
  title: Message;
  close: Message;
  className?: any;
  onScanned: (data: string) => void;
};

export const ScanModalControl: React.FC<ScanModalControlProps> = (props) => {

  const { className, onScanned } = props;

  const { available } = useBarcodeProvider();

  if (!available) {
    return null;
  }

  return (
    <ModalControl
      icon={faBarcode}
      className={className}
      modal={{
        footer: null,
        narrow: true,
        children: (
          <Container grow shrink className={'scan-modal'}>
            <h1><Translate message={props.title}/></h1>
            <Container style={{ margin: '0 1rem 1rem 1rem' }}>
              <ScanPreview className={'scan-preview'} onScanned={onScanned} height={300}/>
            </Container>
          </Container>
        ),
      }}
    />
  );
};
